import React, { useEffect, useState, useRef } from 'react';
import { IoAddCircleOutline } from "react-icons/io5";
import { MdOutlineCancel } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, FieldArray } from 'formik'; // <-- Ensure Formik is imported
import * as Yup from 'yup';
import { BASE_URL,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2 } from '../../Constant/Constant';
import '../../App.css';
import CryptoJS from 'crypto-js';

const AddServices = () => {
    const [loading, setLoading] = useState(false);
    const imageInputRef = useRef(null);
    const previewImageInputRef = useRef(null);
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);


    // Define the initial form values outside of Formik
    const initialValues = {
        title: '',
        preview_image: null,
        short_description: '',
        Image: null,
        service_category: '',
        points: [{ id: 1, text: '' }], // Your points array
        description: '',
        language_id: languageId,
    };

    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required'),
        short_description: Yup.string().required('Short description is required'),
        service_category: Yup.string().required('Service category is required'),
        Image: Yup.mixed().required('Image is required').test(
            'fileFormat',
            'Unsupported format',
            (value) => !value || ['image/jpeg', 'image/png', 'image/gif'].includes(value?.type)
        ).test(
            'fileSize',
            'File size should be less than 1MB',
            (value) => !value || value.size < 1024 * 1024
        ),
        preview_image: Yup.mixed().required('Preview Image is required').test(
            'fileFormat',
            'Unsupported format',
            (value) => !value || ['image/jpeg', 'image/png', 'image/gif'].includes(value?.type)
        ).test(
            'fileSize',
            'File size should be less than 1MB',
            (value) => !value || value.size < 1024 * 1024
        ),
    });
    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
    
        // Define validation rules for images
        const imageValidations = [
            {
                file: values.preview_image,
                dimensions: { width: 1110, height: 1200 },
                errorMessage: 'Preview image must be 1110 x 1200 pixels.',
            },
            {
                file: values.Image,
                dimensions: { width: 1920, height: 500 },
                errorMessage: 'Image must be 1920 x 500 pixels.',
            },
        ];
    
        // Validate each image based on the defined rules
        for (const { file, dimensions, errorMessage } of imageValidations) {
            // Log the file before proceeding to check its validity
            console.log('Checking file:', file); // Debugging log
    
            // Check if file is valid before proceeding
            if (!file || !(file instanceof File)) {
                console.error('Invalid file:', file); // Log the invalid file for debugging
                toast.dismiss()
                toast.error(`Invalid file for validation: ${errorMessage}`, { autoClose: 1500 });
                setLoading(false);
                return;
            }
    
            const isValid = await checkImageDimensions(file, dimensions);
            console.log(`Validation result for ${errorMessage}:`, isValid); // Debugging log
    
            if (!isValid) {
                toast.dismiss()
                toast.error(errorMessage, { autoClose: 1500 });
                setLoading(false);
                return;
            }
        }
    
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('short_description', values.short_description);
        formData.append('service_category', values.service_category);
        formData.append('points', JSON.stringify(values.points.map((point) => point.text)));
        formData.append('Image', values.Image);
        formData.append('preview_image', values.preview_image);
        formData.append('description', values.description);
        formData.append('language_id',languageId);
    
        try {
            const response = await axios.post(`${BASE_URL}service/add`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success(response.data.message || 'Service added successfully', { autoClose: 1500 });
    
            resetForm(); // Reset form after successful submission
            imageInputRef.current.value = '';
            previewImageInputRef.current.value = '';
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error adding service';
            toast.dismiss()
            toast.error(errorMessage, { autoClose: 1500 });
            console.error('Error details:', error);
        } finally {
            setLoading(false);
        }
    };
    
    // Updated checkImageDimensions function to accept dimensions
    const checkImageDimensions = (file, { width, height }) => {
        return new Promise((resolve) => {
            const img = new Image();
            const objectUrl = URL.createObjectURL(file);
    
            img.onload = () => {
                const isValid = img.width === width && img.height === height;
                URL.revokeObjectURL(objectUrl);
                resolve(isValid);
            };
    
            img.onerror = () => {
                URL.revokeObjectURL(objectUrl);
                resolve(false);
            };
            img.src = objectUrl;
        });
    };
    
    return (
        <div className="container-xl my-4">
            <ToastContainer />
            <div className="row mb-3 mt-1">
                <div className="col-md-6">
                    <h4>Services</h4>
                </div>
                <div className="col-md-6">
                    <div className="breadcrumb-text">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-end">
                                <li className="breadcrumb-item">
                                    <Link to="../serviceslist" className="footer-link fs-6">Back</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="#" className="footer-link fs-6">Add</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="row row-cards">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header" style={{ borderBottom: '1px solid #ccc', marginBottom: '2rem', paddingBottom: '1rem' }}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h5 className="card-title me-3">Add</h5>
                            </div>
                        </div>
                        <div className="card-body">

                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, handleChange, handleSubmit, setFieldValue, touched, errors }) => (
                                    <form onSubmit={handleSubmit} className="row g-3">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Title<span className='text-danger px-1 fs-5'>*</span>
                                                </label>
                                                <input
                                                    name="title"
                                                    type="text"
                                                    className={`form-control ${touched.title && errors.title ? 'is-invalid' : ''}`}
                                                    value={values.title}
                                                    onChange={handleChange}
                                                />
                                                {touched.title && errors.title && <div className="invalid-feedback">{errors.title}</div>}
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label">Image (1920 x 500)<span className='text-danger px-1 fs-5'>*</span>
                                                </label>
                                                <input
                                                    name="Image"
                                                    type="file"
                                                    accept="image/jpeg, image/png, image/gif"
                                                    ref={imageInputRef}
                                                    className={`form-control ${touched.Image && errors.Image ? 'is-invalid' : ''}`}
                                                    onChange={(e) => setFieldValue('Image', e.target.files[0])}
                                                />
                                                {touched.Image && errors.Image && <div className="invalid-feedback">{errors.Image}</div>}
                                            </div>


                                            <div className="mb-3">
                                                <label className="form-label">Short Description<span className='text-danger px-1 fs-5'>*</span>
                                                </label>
                                                <textarea
                                                    name="short_description"
                                                    className={`form-control ${touched.short_description && errors.short_description ? 'is-invalid' : ''}`}
                                                    value={values.short_description}
                                                    onChange={handleChange}
                                                />
                                                {touched.short_description && errors.short_description && (
                                                    <div className="invalid-feedback">{errors.short_description}</div>
                                                )}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Points</label>
                                                <FieldArray name="points">
                                                    {({ remove, push }) => (
                                                        <div>
                                                            {values.points.map((point, index) => (
                                                                <div key={index} className="input-group mb-3">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={point.text}
                                                                        onChange={(e) => {
                                                                            const pointsCopy = [...values.points];
                                                                            pointsCopy[index].text = e.target.value;
                                                                            setFieldValue('points', pointsCopy);
                                                                        }}
                                                                    />
                                                                    {index !== 0 && (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-label-secondary"
                                                                            onClick={() => remove(index)}
                                                                        >
                                                                            <MdOutlineCancel />
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            ))}
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() => push({ id: values.points.length + 1, text: '' })}
                                                            >
                                                                <IoAddCircleOutline style={{ fontSize: '16px' }} />
                                                            </button>
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            </div>


                                        </div>

                                        <div className="col-md-6">

                                            <div className="mb-3">
                                                <label className="form-label">Service Category<span className='text-danger px-1 fs-5'>*</span>
                                                </label>
                                                <select
                                                    name="service_category"
                                                    className={`form-select ${touched.service_category && errors.service_category ? 'is-invalid' : ''}`}
                                                    value={values.service_category}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Business Class">Business Class</option>
                                                    <option value="Chauffeur Hailing">Chauffeur Hailing</option>
                                                    <option value="Airport Transfers">Airport Transfers</option>
                                                    <option value="Sprinter Class">Sprinter Class</option>
                                                    <option value="Wedding Class">Wedding Class</option>
                                                    <option value="Travel Transfer">Travel Transfer</option>
                                                </select>
                                                {touched.service_category && errors.service_category && (
                                                    <div className="invalid-feedback">{errors.service_category}</div>
                                                )}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Preview Image  (1110 x 1200)<span className='text-danger px-1 fs-5'>*</span>
                                                </label>
                                                <input
                                                    name="preview_image"
                                                    type="file"
                                                    accept="image/jpeg, image/png, image/gif"
                                                    ref={previewImageInputRef}
                                                    className={`form-control ${touched.preview_image && errors.preview_image ? 'is-invalid' : ''}`}
                                                    onChange={(e) => setFieldValue('preview_image', e.target.files[0])}
                                                />
                                                {touched.preview_image && errors.preview_image && (
                                                    <div className="invalid-feedback">{errors.preview_image}</div>
                                                )}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Description</label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={values.description}
                                                    onChange={(value) => setFieldValue('description', value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12 text-end">
                                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                                {loading ? 'Adding...' : 'Add'}
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddServices;

