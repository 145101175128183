import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2  } from '../../../../Constant/Constant';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

const HeaderMenu = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        HomeTitle: '',
        AboutTitle: '',
        OurFleetTitle: '',
        ServicesTitle: '',
        ContactTitle: '',
        PhoneNumber: '',
        ProfileTitle: '',
        ChangePasswordTitle: '',
        BookingListTitle: '',
        Logout: '',
        RegisterTitle: '',
        LoginTitle: ''
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [id, setId] = useState(null);
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [isBookingBoxData, setIsBookingBoxData] = useState(false);
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);
  
    useEffect(() => {
      if (permissions) {
        try {
          const modules = JSON.parse(permissions);
          const legalcontent = modules.find(module => module.rolename === 'staticcontent');
          if (legalcontent) {
            setRolePermissions(legalcontent.permissions);
            setIsBookingBoxData(legalcontent.permissions.write === 'no');
           
          }
        } catch (error) {
          console.error('Failed to parse permissions:', error);
        }
      }
    }, [permissions]);

   
        const fetchData = async () => {
            setLoading(true); // Show loading indicator while fetching data
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.data && response.data.headermenu) {
                    const parsedData = JSON.parse(response.data.headermenu);
                    setFormValues(parsedData);
                    setId(response.data.id);
                    setIsDataExists(true);
                    setIsEditMode(false);
                } else {
                    // No data exists
                    setFormValues({
                        HomeTitle: '',
                        AboutTitle: '',
                        OurFleetTitle: '',
                        ServicesTitle: '',
                        ContactTitle: '',
                        PhoneNumber: '',
                        RegisterTitle: '',
                        LoginTitle: ''

                    });
                    setIsDataExists(false);
                    setIsEditMode(true); // Allow user to add new data
                }
            } catch (error) {
                setIsEditMode(true); // Allow editing in case of failure
            } finally {
                setLoading(false);
            }
        };


    useEffect(() => {
        if (token && languageId) {
            fetchData();
        }
    }, [token, languageId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                headermenu: JSON.stringify(formValues), // Stringify form data for sending
                language_id: languageId
            };

            const url = `${BASE_URL}staticcontent/headermenu`;
            const response = await axios.post(url, payload, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data && response.data.message === 'headermenu Process successfully!') {
                toast.dismiss()
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);
                setIsEditMode(false); // Exit edit mode after saving
            } else {
                toast.dismiss()
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }
        } catch (error) {
            toast.dismiss()
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <form className="row g-3" onSubmit={handleSubmit}>
            {/* First Row */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Home Title</label>
                    <input
                        name="HomeTitle"
                        type="text"
                        className="form-control"
                        value={formValues.HomeTitle}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">About Title</label>
                    <input
                        name="AboutTitle"
                        type="text"
                        className="form-control"
                        value={formValues.AboutTitle}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">OurFleet Title</label>
                    <input
                        name="OurFleetTitle"
                        type="text"
                        className="form-control"
                        value={formValues.OurFleetTitle}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
            </div>

            {/* Second Row */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Services Title</label>
                    <input
                        name="ServicesTitle"
                        type="text"
                        className="form-control"
                        value={formValues.ServicesTitle}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Contact Title</label>
                    <input
                        name="ContactTitle"
                        type="text"
                        className="form-control"
                        value={formValues.ContactTitle}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Phone Number</label>
                    <input
                        name="PhoneNumber"
                        type="text"
                        className="form-control"
                        value={formValues.PhoneNumber}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
            </div>

            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Profile Title</label>
                    <input
                        name="ProfileTitle"
                        type="text"
                        className="form-control"
                        value={formValues.ProfileTitle}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">ChangePassword Title</label>
                    <input
                        name="ChangePasswordTitle"
                        type="text"
                        className="form-control"
                        value={formValues.ChangePasswordTitle}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">BookingList Title</label>
                    <input
                        name="BookingListTitle"
                        type="text"
                        className="form-control"
                        value={formValues.BookingListTitle}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Logout</label>
                    <input
                        name="Logout"
                        type="text"
                        className="form-control"
                        value={formValues.Logout}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Login Title</label>
                    <input
                        name="LoginTitle"
                        type="text"
                        className="form-control"
                        value={formValues.LoginTitle}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Register Title</label>
                    <input
                        name="RegisterTitle"
                        type="text"
                        className="form-control"
                        value={formValues.RegisterTitle}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
            </div>

            <div className="card-footer text-end">
            {rolePermissions.write === 'yes' && 

                <button
                    type="submit"
                    className="btn btn-primary"
                  disabled={loading ||isBookingBoxData}
                >
                    {loading ? 'Saving...' : (isDataExists ? 'Update' : 'Add')}
                </button>
}
            </div>
        </form>
    );
};

export default HeaderMenu;
