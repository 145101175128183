import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Constant/Constant'


const TableBody = ({ data, columns, selectedRows, handleRowSelect, handleRowAction, isBookingListPage, canEdit, checkbox }) => {
    const [currency, setCurrency] = useState('');
    useEffect(() => {
        const fetchCurrencyCode = async () => {
            try {
                const response = await axios.get(`${BASE_URL}api/central_setting_time`);
                const currencyCode = response.data[0]?.code; // Adjust based on the response structure
                setCurrency(currencyCode || ''); // Ensure currencyCode is not undefined or null
            } catch (error) {
                console.error('Failed to fetch currency code:', error);
            }
        };

        fetchCurrencyCode();
    }, []);

    return (
        <tbody >
            {data.length > 0 ? (
                data.map((item, rowIndex) => (
                    <tr key={item.id || rowIndex}>
                        {canEdit && !isBookingListPage && !checkbox && (
                            <td style={{ width: '50px' }}>
                                <input
                                    type="checkbox"
                                    checked={selectedRows[rowIndex] || false}
                                    onChange={() => handleRowSelect(rowIndex)}
                                />
                            </td>
                        )}
                        {columns.map((column) => (
                            <td key={column.key}>
                                {column.key === 'image' || column.key === 'preview_image' ? ( // Check for image key
                                    <img
                                        src={`${BASE_URL}uploads/${item[column.key]}`}
                                        alt="Item"
                                        style={{ width: '60px', height: '40px', borderRadius: "5px" }}
                                    />
                                ) : column.key === 'status' || column.key === 'payment_status' ? (
                                    <span
                                        className={`badge ${item[column.key] === 'active' ||
                                                item[column.key] === 'paid' ||
                                                item[column.key] === 'complete'
                                                ? 'bg-label-success' // For success statuses
                                                : item[column.key] === 'process'
                                                    ? 'bg-label-warning' // For process status (yellow)
                                                    : item[column.key] === 'cancel' || item[column.key] === 'unpaid' || item[column.key] === 'inactive' 
                                                        ? 'bg-label-danger' // For cancel status (red)
                                                        : 'bg-label-default' // Fallback color for unknown statuses
                                            }`}
                                    >
                                        {item[column.key]}
                                    </span>

                                ) : column.key === 'discount' ? (
                                    <span className="fw-medium">
                                        {item[column.key] ? ` ${parseFloat(item[column.key]).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} %` : 'N/A'}
                                    </span>
                                ) : column.key === 'total_price' || column.key === 'price' || column.key === 'start_price' || column.key === 'day_price' || column.key === 'night_price' ? (
                                    <span className="fw-medium">
                                        {item[column.key] ? `${currency} ${parseFloat(item[column.key]).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}` : 'N/A'}
                                    </span>
                                ) : (
                                    <span className="fw-medium">{item[column.key]}</span>
                                )}
                            </td>
                        ))}
                        <td style={{ width: "50px" }}>
                            <i
                                className="text-primary ti ti-eye cursor-pointer"
                                title="View"
                                onClick={() => handleRowAction('view', item.id)}
                            ></i>

                            {canEdit && !isBookingListPage && (
                                <>
                                    <i
                                        className="text-primary ti ti-pencil cursor-pointer"
                                        title="Edit"
                                        onClick={() => handleRowAction('edit', item.id)}
                                    ></i>
                                    <i
                                        className="text-primary ti ti-trash cursor-pointer"
                                        title="Delete"
                                        onClick={() => handleRowAction('delete', item.id)}
                                    ></i>
                                </>
                            )}
                            {isBookingListPage && canEdit && (
                                <>
                                    <i
                                        className="text-primary fa-regular fa-pen-to-square cursor-pointer"
                                        style={{ marginLeft: '3px' }}
                                        title="Update"
                                        onClick={() => handleRowAction('update', item.id)}
                                    ></i>
                                    <i
                                        className="text-primary fa-regular fa-circle-xmark cursor-pointer"
                                        style={{ marginLeft: '3px' }}
                                        title="Cancel"
                                        onClick={() => handleRowAction('cancel', item.id)}
                                    ></i>
                                </>
                            )}
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan={columns.length + (isBookingListPage ? 1 : 2)} className="text-center">No records found</td>
                </tr>
            )}
        </tbody>
    );
};

export default TableBody;
