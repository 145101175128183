import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, CRYPTOJS_ENCRYPTION_KEY} from '../../Constant/Constant';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CryptoJS from 'crypto-js';
const ActionMetaTags = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { id } = useParams();
    const action = queryParams.get('action');
    const navigate = useNavigate();

    const [isEditable, setIsEditable] = useState(false);
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [isLoading, setIsLoading] = useState(true); // New loading state
    const [token, setToken] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
   
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
    }, []);


    useEffect(() => {
        if (permissions) {
            try {
                const modules = JSON.parse(permissions);
                const MetaTags = modules.find(module => module.rolename === 'MetaTags');
                if (MetaTags) {
                    setRolePermissions(MetaTags.permissions);
                }
            } catch (error) {
                console.error('Failed to parse permissions:', error);
            }
        }
    }, [permissions]);

        const fetchLegalContents = async () => {
        axios.get(`${BASE_URL}metatags/get/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            const data = response.data;
            formik.setValues({
                meta_title: data.meta_title,
                meta_description: data.meta_description,
                meta_keywords: data.meta_keywords,
                category: data.category,
            });
            setIsLoading(false); // Data fetched, stop loading
        })
        .catch(error => {
            toast.error('Error fetching MetaTags', {
                autoClose: 1500,
            });
            setIsLoading(false); // Stop loading even on error
        });
    }
    useEffect(() => {
        if (token ) {
            fetchLegalContents();
        }
    }, [token]);
    useEffect(() => {
        setIsEditable(action === 'edit');
    }, [action]);

    const formik = useFormik({
        initialValues: {
            meta_title: '',
            meta_description: '',
            meta_keywords: '',
            category: ''
        },
        validationSchema: Yup.object({
            meta_title: Yup.string().required('Meta Title is required'),
            meta_description: Yup.string().required('Meta Description is required'),
            meta_keywords: Yup.string().required('Meta Keywords are required'),
            category: Yup.string().required('Category is required'),
        }),
        onSubmit: (values) => {
            if (rolePermissions.write === 'no') {
                toast.dismiss();
                toast.error('You do not have permission to update data.', {
                    autoClose: 1500,
                });
                return;
            }
            axios.put(`${BASE_URL}metatags/update/${id}`, values, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                toast.success('MetaTags updated successfully', {
                    autoClose: 1500,
                });
              
                    navigate(`/action_metatags/${id}?action=view`);
                
            })
            .catch(error => {
                toast.error('Failed to update MetaTags', {
                    autoClose: 1500,
                });
            });
        },
    });

    const handleCancel = () => {
        navigate(`/action_metatags/${id}?action=view`);
    };

    const categories = [
        { value: '', label: 'Select Category' },
        { value: 'home', label: 'Home' },
        { value: 'about', label: 'About' },
        { value: 'ourfleet', label: 'Our Fleet' },
        { value: 'services', label: 'Services' },
        { value: 'contact', label: 'Contact' },
        { value: 'booking-vehicle', label: 'Booking Vehicle' },
        { value: 'booking-extra', label: 'Booking Extra' },
        { value: 'booking-passenger', label: 'Booking Passenger' },
        { value: 'booking-payment', label: 'Booking Payment' },
        { value: 'booking-received', label: 'Booking Received' },
        { value: 'legel-content', label: 'Legal Content' },
    ];

    if (isLoading) {
        return <div>Loading...</div>; // Display loading state
    }

    return (
        <div className="container-xl my-4">
            <ToastContainer/>
            <div className="row">
                <div className="col-md-6">
                    <h4>MetaTags</h4>
                </div>
                <div className="col-md-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end fs-3">
                            <li className="breadcrumb-item"><Link to="../metatags" className='fs-6'>Back</Link></li>
                            <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="col-12 mt-3">
                <div className="card mt-3">
                    <div className="card-header header-underline pb-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="card-legal_meta_title">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
                        </div>
                    </div>
                    <div className="card-body mt-3">
                        <form onSubmit={formik.handleSubmit} className="row g-3">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="col-sm col-form-label">Category<span className='text-danger px-1 fs-5'>*</span></label>
                                    <select
                                        name="category"
                                        value={formik.values.category}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={!isEditable}
                                        style={{ color: !isEditable ? "#808080" : "#808080",}}
                                        className="form-select mb-2"
                                    >
                                        {categories.map(cat => (
                                            <option key={cat.value} value={cat.value}>{cat.label}</option>
                                        ))}
                                    </select>
                                    {formik.touched.category && formik.errors.category ? (
                                        <div className="text-danger">{formik.errors.category}</div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="meta_description" className="col-sm col-form-label">Meta Description<span className='text-danger px-1 fs-5'>*</span></label>
                                    <textarea
                                        id="meta_description"
                                        name="meta_description"
                                        rows="3"
                                        value={formik.values.meta_description}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={!isEditable}
                                        className="form-control mb-2"
                                    />
                                    {formik.touched.meta_description && formik.errors.meta_description ? (
                                        <div className="text-danger">{formik.errors.meta_description}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="meta_title" className="col-sm col-form-label">Meta Title<span className='text-danger px-1 fs-5'>*</span></label>
                                    <input
                                        type="text"
                                        id="meta_title"
                                        name="meta_title"
                                        value={formik.values.meta_title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={!isEditable}
                                        className="form-control mb-2"
                                    />
                                    {formik.touched.meta_title && formik.errors.meta_title ? (
                                        <div className="text-danger">{formik.errors.meta_title}</div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="meta_keywords" className="col-sm col-form-label">Meta Keywords<span className='text-danger px-1 fs-5'>*</span></label>
                                    <textarea
                                        id="meta_keywords"
                                        name="meta_keywords"
                                        rows="3"
                                        value={formik.values.meta_keywords}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={!isEditable}
                                        className="form-control mb-2"
                                    />
                                    {formik.touched.meta_keywords && formik.errors.meta_keywords ? (
                                        <div className="text-danger">{formik.errors.meta_keywords}</div>
                                    ) : null}
                                </div>
                            </div>
                            {isEditable ? (
                                <div className="col-12 d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={handleCancel} className="btn btn-label-secondary me-3">
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Update
                                    </button>
                                </div>
                            ) : (rolePermissions.write === 'yes' && (
                                <div className="col-12 d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={() => navigate(`/action_metatags/${id}?action=edit`)} className="btn btn-primary">
                                        Edit
                                    </button>
                                </div>
                            ))}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActionMetaTags;
