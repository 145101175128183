import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import {BASE_URL,BACKENDTOKEN,SELECTEDLANGUAGEID} from '../../../../Constant/Constant';
import { toast } from 'react-toastify';

const AppIndex = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        AppTitle: '',
        AppDescription: '',
        OfferTitle: '',
        OfferDescription: '' 
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [languageId, setLanguageId] = useState(`${SELECTEDLANGUAGEID}`);
    const [id, setId] = useState(null); // Initialize with null

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url,{
                    headers:{
                        'Authorization': `Bearer ${BACKENDTOKEN}`
                    }
                });

                // Set the ID from the fetched data, even if appindex is null
                if (response.data && response.data.id) {
                    setId(response.data.id);
                }

                if (response.data && response.data.appindex) {
                    const parsedData = JSON.parse(response.data.appindex);
                    setFormValues(parsedData);
                    setIsDataExists(true);
                    setIsEditMode(false); // Data exists, so not in edit mode
                } else {
                    setFormValues({
                        AppTitle: '',
                        AppDescription: '',
                        OfferTitle: '',
                        OfferDescription: ''
                    });
                    setIsDataExists(false);
                    setIsEditMode(true);
                }
            } catch (error) {
                setIsEditMode(true); 
            }
        };

        fetchData();
    }, [languageId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleDescriptionChange = (name, value) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                appindex: formValues,
                language_id: languageId
            };

            const url = `${BASE_URL}staticcontent/appindex`;

            const response = await axios.post(url, payload,{
                headers:{
                    'Authorization': `Bearer ${BACKENDTOKEN}`
                }
            });

            if (response.data.message === 'appindex Process successfully!') {
                setIsDataExists(true);
                toast.dismiss()
                toast.success('Data Process Successfully', {
                    autoClose: 1500,
                });
            } else {
                toast.dismiss()
                toast.error('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.dismiss()
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">App Title</label>
                        <input
                            name="AppTitle"
                            type="text"
                            className="form-control"
                            value={formValues.AppTitle}
                            onChange={handleChange}
                           
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">App Description</label>
                        <ReactQuill
                            name="AppDescription"
                            theme="snow"
                            value={formValues.AppDescription}
                            onChange={(value) => handleDescriptionChange('AppDescription', value)}
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    [{ 'size': [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['link', 'image'],
                                    ['clean']
                                ]
                            }}
                            style={{ height: '130px', borderRadius: '10px' }}
                            className="react-quill-editor mb-3"
                         
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Offer Title</label>
                        <input
                            name="OfferTitle"
                            type="text"
                            className="form-control"
                            value={formValues.OfferTitle}
                            onChange={handleChange}
                           
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Offer Description</label>
                        <ReactQuill
                            name="OfferDescription"
                            theme="snow"
                            value={formValues.OfferDescription}
                            onChange={(value) => handleDescriptionChange('OfferDescription', value)}
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    [{ 'size': [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['link', 'image'],
                                    ['clean']
                                ]
                            }}
                            style={{ height: '130px', borderRadius: '10px' }}
                            className="react-quill-editor mb-3"
                            
                        />
                    </div>
                </div>
                <div className="card-footer text-end"    style={{"marginTop":"110px"}} >
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                     
                    >
                        {loading ? 'Saving...' : (isDataExists ? 'Update' : 'Add')}
                    </button>
                </div>

            </form>
        </>
    );
};

export default AppIndex;
