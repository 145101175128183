import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BASE_URL, CRYPTOJS_ENCRYPTION_KEY, CRYPTOJS_ENCRYPTION_KEY_2 } from '../Constant/Constant';
import CryptoJS from 'crypto-js';

const Notification = ({ setNewNotificationCount }) => {
    const [data, setData] = useState([]);
    const [newBookingsCount, setNewBookingsCount] = useState(0); // Track only new bookings
    const [lastBookingId, setLastBookingId] = useState(null); // To track the last booking ID
    const [token, setToken] = useState(null); // Store token in state
    const [languageId, setLanguageId] = useState(null);

    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };

    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); // Ensure it's set to state
               
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
    useEffect(() => {
        fetchBackendToken();
        fetchLanguage();
    }, []); // Only run once to decrypt and set the token and language
  
    // Fetch booking data from the API
    const fetchData = async () => {
        if (!token || !languageId) return; // Ensure token and languageId are available before fetching data

        try {
            const response = await fetch(`${BASE_URL}booking/get?language_id=${languageId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Server error: ${response.status}`);
            }

            const result = await response.json();

            if (Array.isArray(result) && result.length > 0) {
                setData(result);

                // Detect new bookings by comparing the first booking's ID with the previously stored one
                const newBookings = result.filter(booking => lastBookingId && booking.id > lastBookingId);

                if (newBookings.length > 0) {
                    toast.success(`${newBookings.length} new booking(s) added!`, {
                        autoClose: 2000,
                    });
                    setNewBookingsCount(newBookings.length); // Set the count of new bookings only
                    setNewNotificationCount(newBookings.length); // Update the count passed to the header
                }

                // Update the last booking ID to the latest one
                setLastBookingId(result[0].id);
            } else {
                console.warn('No data found for the given language ID.');
            }
        } catch (error) {
            toast.error('Error fetching data: ' + error.message, {
                autoClose: 2000,
            });
        }
    };

    // Fetch data initially and set up a 1-minute interval to refresh the data
    useEffect(() => {
        if (token && languageId) {
            fetchData(); // Fetch data when token and languageId are set

            // Set an interval to refetch the data every 1 minute (60000ms)
            const intervalId = setInterval(() => {
                fetchData();
            }, 60000);

            // Clean up the interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [token, languageId, lastBookingId]); // Depend on token, languageId, and lastBookingId to refresh data

    // Get only the first 5 messages from the data
    const firstFiveMessages = data.slice(0, 5);

    return (
        <ul className="list-group list-group-flush">
            {firstFiveMessages.map((item, index) => (
                <li className="list-group-item list-group-item-action dropdown-notifications-item" key={index}>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h6 className="mb-1">{item.booking_id}</h6>
                            <p className="mb-0">{item.from_location}</p>
                            <small className="text-muted">{item.time}</small>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default Notification;
