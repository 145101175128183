import React from 'react';

const ConfirmationModal = ({ isOpen, message, onConfirm, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content-delete">
            <p className='delete-customize'><svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="yellow"><path d="M479.98-301.54q9.79 0 15.64-5.83 5.84-5.83 5.84-15.61 0-9.79-5.83-15.64-5.83-5.84-15.61-5.84-9.79 0-15.64 5.83-5.84 5.83-5.84 15.61 0 9.79 5.83 15.64 5.83 5.84 15.61 5.84Zm-13.9-129.15h30.77v-246.08h-30.77v246.08ZM480.4-120q-75.18 0-140.29-28.34-65.12-28.34-114.25-77.42-49.13-49.08-77.49-114.21Q120-405.11 120-480.37q0-74.49 28.34-140.07 28.34-65.57 77.42-114.2 49.08-48.63 114.21-76.99Q405.11-840 480.37-840q74.49 0 140.07 28.34 65.57 28.34 114.2 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.4q0 75.18-28.34 140.29-28.34 65.12-76.92 114.07-48.58 48.94-114.26 77.49Q554.81-120 480.4-120Zm.1-30.77q136.88 0 232.81-96.04 95.92-96.04 95.92-233.69 0-136.88-95.73-232.81-95.74-95.92-233.5-95.92-137.15 0-233.19 95.73-96.04 95.74-96.04 233.5 0 137.15 96.04 233.19 96.04 96.04 233.69 96.04ZM480-480Z"/></svg></p>
                <p>{message}</p>
                <div className="modal-actions">
                    <button className="btn btn-danger" onClick={onConfirm}>Yes, delete it!</button>
                    <button className="btn btn-label-secondary" onClick={onClose} style={{ marginLeft: '10px' }}>No</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
