import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL,BACKENDTOKEN,SELECTEDLANGUAGEID} from '../../../Constant/Constant';

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
});

const ModalOfferInfo = ({ refreshData }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const initialValues = { title: '', description: '' };

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
        try {
            const language_id = `${SELECTEDLANGUAGEID}`
            const dataToSend = {
                ...values,
                description,
                language_id,
            };
    
            const response = await fetch(`${BASE_URL}offerinfo/add`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${BACKENDTOKEN}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            });
    
            // Check if the response is not successful
            if (!response.ok) {
                const errorData = await response.json(); // Parse the error message from the response
                throw new Error(errorData.message || 'Error adding offer info');
            }
    
            // If the request is successful
            await response.json();
            toast.dismiss();
            toast.success('Successfully added', {
                autoClose: 1500,
            });
            resetForm();
            setDescription(''); // Reset description after submission
            setModalOpen(false); // Close modal after submission
    
            if (refreshData) {
                refreshData(); // Call the function to reload the table data
            }
        } catch (error) {
            toast.dismiss();
            
            // Display the backend message or a fallback message
            const errorMessage = error.message || 'Error submitting form';
            toast.error(errorMessage, {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
            <Link type="button" className="btn btn-primary waves-effect waves-light" onClick={() => setModalOpen(true)}>
                <i className="fa fa-plus me-2"></i>Add
            </Link>

            {modalOpen && (
                <>
                    <div className={`modal fade show`} style={{ display: 'block' }} tabIndex="-1" aria-hidden={!modalOpen}>
                        <ToastContainer />
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content" style={{ backgroundColor: 'white' }}>
                                <div className="modal-header">
                                    <h5 className="modal-title">Add</h5>
                                    <button type="button" className="btn-close" onClick={() => setModalOpen(false)} aria-label="Close"></button>
                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {formik => (
                                        <Form>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <label htmlFor="title" className="form-label">Title<span className='text-danger px-1 fs-5'>*</span></label>
                                                        <Field
                                                            id="title"
                                                            name="title"
                                                            type="text"
                                                            className={`form-control ${formik.errors.title && formik.touched.title ? 'is-invalid' : ''}`}
                                                           
                                                        />
                                                        {formik.errors.title && formik.touched.title && (
                                                            <div className="invalid-feedback">{formik.errors.title}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col">
                                                        <label htmlFor="description" className="form-label">Description</label>
                                                        <ReactQuill
                                                            theme="snow"
                                                            value={description}
                                                            onChange={setDescription}
                                                            modules={{
                                                                toolbar: [
                                                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                                    [{ 'size': [] }],
                                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                    ['link', 'image'],
                                                                    ['clean'],
                                                                ]
                                                            }}
                                                            style={{ height: 'auto', borderRadius: '10px',  }}
                                                            className="react-quill-editor mb-3"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-label-secondary" onClick={() => setModalOpen(false)}>
                                                    Close
                                                </button>
                                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                                    {loading ? 'Adding...' : 'Add'}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
                </>
            )}
        </>
    );
};

export default ModalOfferInfo;
