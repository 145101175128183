import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { BASE_URL,CRYPTOJS_ENCRYPTION_KEY } from '../../../Constant/Constant';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CryptoJS from 'crypto-js';

const ActionContact = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(null);
  const [file, setFile] = useState(null);
  const [originalImage, setOriginalImage] = useState('');

  const [branch_type, setbranch_type] = useState('');
  const [branch_name, setbranch_name] = useState('');
  const [street, setstreet] = useState('');
  const [number, setnumber] = useState('');
  const [postal_code, setpostal_code] = useState('');
  const [city, setcity] = useState('');
  const [state, setstate] = useState('');
  const [working_hours, setworking_hours] = useState('');
  const [email, setemail] = useState('');
  const [mobile, setmobile] = useState('');

  const [isEditable, setIsEditable] = useState(false);
  const [message, setMessage] = useState(null);

  const permissions = useSelector(state => state.roles.permissions.user_modules);
  const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
  const [token, setToken] = useState(null);
  
  // Function to decrypt tokens
  const decryptToken = (encryptedToken, key) => {
      try {
          const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
          const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
          return decryptedToken ? decryptedToken : null;
      } catch (error) {
          console.error('Error decrypting token:', error);
          return null;
      }
  };
  
  // Fetch token from localStorage and decrypt it
  const fetchBackendToken = async () => {
      const encryptedBackendToken = localStorage.getItem('BackendToken');
      if (encryptedBackendToken) {
          const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
          setToken(decryptedToken); // Set token to state
      } else {
          console.warn('No BackendToken found in localStorage.');
      }
  };
  
      useEffect(() => {
      fetchBackendToken(); // Fetch and set token
  }, []);


  // Formik validation schema
  const validationSchema = Yup.object().shape({
    branch_name: Yup.string().required('Branch Name is required'),
    branch_type: Yup.string().required('Branch Type is required'),
    street: Yup.string().required('Street is required'),
    number: Yup.number().typeError('Number must be a valid number').required('Number is required'),
    postal_code: Yup.string().required('Postal Code is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
   // working_hours: Yup.string().required('Working Hours are required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mobile: Yup.string().required('Mobile is required'),
    image: Yup.mixed().nullable().test('fileType', 'Unsupported file type. Only JPEG, PNG, and GIF are allowed.', value => {
      return !value || (value && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type));
    })
  });

  const initialValues = {
    branch_type: branch_type,
    branch_name: branch_name,
    street: street,
    number: number,
    postal_code: postal_code,
    city: city,
    state: state,
    working_hours: working_hours,
    email: email,
    mobile: mobile,
    image: file
  };

  useEffect(() => {
    if (permissions) {
      try {
        const modules = JSON.parse(permissions);
        const contactus = modules.find(module => module.rolename === 'contactus');
        if (contactus) {
          setRolePermissions(contactus.permissions);
        }
      } catch (error) {
        console.error('Failed to parse permissions:', error);
      }
    }
  }, [permissions]);

 const  fetchLegalContents = async () => {
    axios.get(`${BASE_URL}contact/get/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        setbranch_type(response.data.branch_type);
        setbranch_name(response.data.branch_name);
        setstreet(response.data.street);
        setnumber(response.data.number);
        setpostal_code(response.data.postal_code);
        setcity(response.data.city);
        setstate(response.data.state);
        setworking_hours(response.data.working_hours);
        setemail(response.data.email);
        setmobile(response.data.mobile);
        setOriginalImage(response.data.image);
        setPreviewImage(`${BASE_URL}uploads/${response.data.image}`);
      })
      .catch(error => {
        console.error('Error fetching company:', error);
      });
  };
  useEffect(() => {
    if (token ) {
        fetchLegalContents();
    }
}, [token]);

  useEffect(() => {
    setIsEditable(action === 'edit');
  }, [action]);

  const handleUpdate = (values) => {
    if (rolePermissions.write === 'no') {
      toast.dismiss();
      toast.error('You do not have permission to update data.', {
        autoClose: 1500,
      });
      return;
    }
    const formData = new FormData();
    formData.append('branch_type', values.branch_type);
    formData.append('branch_name', values.branch_name);
    formData.append('street', values.street);
    formData.append('number', values.number);
    formData.append('postal_code', values.postal_code);
    formData.append('city', values.city);
    formData.append('state', values.state);
    formData.append('working_hours', values.working_hours);
    formData.append('email', values.email);
    formData.append('mobile', values.mobile);

    if (file) {
      formData.append('new_image', file);
    } else {
      formData.append('image', originalImage);
    }
    axios.put(`${BASE_URL}contact/update/${id}`,
      formData, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        toast.dismiss();
        toast.success('Company updated successfully', {
          autoClose: 1500,
        });
        setTimeout(() => {
          setMessage(null);
          navigate(`/ActionContact/${id}?action=view`);
        });
      })
      .catch(error => {
        toast.dismiss();
        toast.error('Failed to update company', {
          autoClose: 1500,
        });
        setTimeout(() => {
          setMessage(null);
        });
      });
  };

  const handleEdit = () => {
    navigate(`/ActionContact/${id}?action=edit`);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreviewImage(URL.createObjectURL(selectedFile));
    }
  };

  const handleCancel = () => {
    navigate(`/ActionContact/${id}?action=view`);
  };

  return (
    <div className="container-xl my-4">
      <ToastContainer />
      <div className="row">
        <div className="col-md-6">
          <h4>Contact Us</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="/contactus" className="fs-6">Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className="fs-6">ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-name">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleUpdate}
            >
              {({ isSubmitting }) => (
                <Form className="row g-3">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="col-sm col-form-label" htmlFor="branch_type">Branch Type<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field as="select" name="branch_type"   disabled={!isEditable} className="form-select" style={{ color: !isEditable ? "#808080" : "#808080",}}>
                        <option value="">Branch Type</option>
                        <option value="MainBranch">Main Branch</option>
                        <option value="SubBranch">Sub Branch</option>
                      </Field>
                      <ErrorMessage name="branch_type" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <label className="col-sm col-form-label" htmlFor="branch_name">Branch Name<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field name="branch_name" type="text" className="form-control"   disabled={!isEditable} />
                      <ErrorMessage name="branch_name" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-sm-3">
                      <label className="col-form-label" htmlFor="street">Street Name<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field name="street" type="text" className="form-control"   disabled={!isEditable}/>
                      <ErrorMessage name="street" component="div" className="invalid-feedback" />
                    </div>
                    <div className="col-sm-3">
                      <label className="col-form-label" htmlFor="number">House Number<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field name="number" type="text" className="form-control"   disabled={!isEditable} />
                      <ErrorMessage name="number" component="div" className="invalid-feedback" />
                    </div>
                    <div className="col-sm-3">
                      <label className="col-form-label" htmlFor="postal_code">Postal Code<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field name="postal_code" type="text" className="form-control"   disabled={!isEditable}/>
                      <ErrorMessage name="postal_code" component="div" className="invalid-feedback" />
                    </div>
                    <div className="col-sm-3">
                      <label className="col-form-label" htmlFor="city">City<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field name="city" type="text" className="form-control"   disabled={!isEditable} />
                      <ErrorMessage name="city" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                  {/* Add more form fields similarly */}
                  <div className="col-md-6 ">
                    <div className="">
                      <label className="col-sm col-form-label" htmlFor="state">State<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field name="state" type="text" className="form-control"   disabled={!isEditable} />
                      <ErrorMessage name="state" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="">
                      <label className="col-sm col-form-label" htmlFor="working_hours">Working Hours</label>
                      <Field name="working_hours" type="text" className="form-control"   disabled={!isEditable} />
                      {/* <ErrorMessage name="working_hours" component="div" className="invalid-feedback" /> */}
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="">
                      <label className="col-sm col-form-label" htmlFor="email">Email</label>
                      <Field name="email" type="email" className="form-control"   disabled={!isEditable}/>
                      <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="">
                      <label className="col-form-label" htmlFor="mobile">Mobile</label>
                      <PhoneInput
                        inputClass="w-100"
                        value={mobile}
                        country={'us'}
                        onChange={phone => setmobile(phone)}
                        inputStyle={{
                          width: "100%",
                          height: "40px",
                          paddingLeft: "44px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          fontSize: "16px",
                          backgroundColor: !isEditable ? "#f4f4f4" : "white",
                      }}
                        disabled={!isEditable}
                      />
                      <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="">
                    <div className="mb-3">
                      <label className="col-sm col-form-label" htmlFor="image">Image</label>
                      <div className="mb-3">
                        {previewImage && (
                          <img
                            src={previewImage}
                            alt="Image Preview"
                            className="img-fluid mb-2 rounded"
                          />
                        )}
                      </div>
                      {isEditable && (
                        <input
                          type="file"
                          id="image"
                          accept="image/jpeg, image/png, image/gif"
                          onChange={handleFileChange}
                          className="form-control mb-2"
                        />
                      )}
                    </div>
                    </div>
                  </div>
                  {isEditable ? (
                    <div className="col-12 mt-5 d-flex justify-content-end align-items-center">
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                      <button type="button" onClick={handleCancel} className="btn btn-label-secondary ms-2">
                        Cancel
                      </button>
                    </div>
                  ) : (
                    rolePermissions.write === 'yes' && (
                      <div className="col-12 d-flex justify-content-end align-items-center">
                        <button type="button" onClick={handleEdit} className="btn btn-primary">
                          Edit
                        </button>
                      </div>
                    )
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionContact;
