import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../Redux/Slice/authSlice'; 
import { clearUserPermissions } from '../Redux/Slice/roleSlice'; 
const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        // Remove the JWT token from localStorage
        localStorage.removeItem('BackendToken');

        // Dispatch the clearAuth action to clear authentication state
        dispatch(logout());
        dispatch(clearUserPermissions());
        // Redirect to the login page or another page
        navigate('/');
    }, [navigate, dispatch]);

    return null; // No UI needed; just a redirect
};

export default Logout;
