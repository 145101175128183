import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import ReactQuill styles
import { BASE_URL,CRYPTOJS_ENCRYPTION_KEY  } from '../../../Constant/Constant';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CryptoJS from 'crypto-js';

const ActionLegal = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();

  const [legal_title, setLegalTitle] = useState('');
  const [legal_type, setLegalType] = useState('');
  const [description, setDescription] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });

  const permissions = useSelector(state => state.roles.permissions.user_modules);

  // Define validation schema
  const validationSchema = Yup.object().shape({
    legal_title: Yup.string().required('Legal Title is required'),
    legal_type: Yup.string().required('Legal Type is required'),
    
  });
  const [token, setToken] = useState(null);
  
  // Function to decrypt tokens
  const decryptToken = (encryptedToken, key) => {
      try {
          const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
          const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
          return decryptedToken ? decryptedToken : null;
      } catch (error) {
          console.error('Error decrypting token:', error);
          return null;
      }
  };
  
  // Fetch token from localStorage and decrypt it
  const fetchBackendToken = async () => {
      const encryptedBackendToken = localStorage.getItem('BackendToken');
      if (encryptedBackendToken) {
          const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
          setToken(decryptedToken); // Set token to state
      } else {
          console.warn('No BackendToken found in localStorage.');
      }
  };
  
  
  // Single useEffect for both fetching token and language on component mount
  useEffect(() => {
      fetchBackendToken(); // Fetch and set token
  }, []);

  useEffect(() => {
    if (permissions) {
      try {
        const modules = JSON.parse(permissions);
        const legalcontent = modules.find(module => module.rolename === 'legalcontent');
        if (legalcontent) {
          setRolePermissions(legalcontent.permissions);
        }
      } catch (error) {
        console.error('Failed to parse permissions:', error);
      }
    }
  }, [permissions]);
  const fetchData = async () => {
    axios.get(`${BASE_URL}legalcontent/get/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        setLegalTitle(response.data.legal_title);
        setLegalType(response.data.legal_type);
        setDescription(response.data.description);
      })
      .catch(error => {
        console.error('Error fetching legal-contents:', error);
      });
    }

    useEffect(() => {
      if (token) {
        fetchData();
      }
  }, [token]);

  useEffect(() => {
    setIsEditable(action === 'edit');
  }, [action]);

  const handleUpdate = (values) => {
    if (rolePermissions.write === 'no') {
      toast.dismiss();
      toast.error('You do not have permission to update data.', {
        autoClose: 1500,
      });
      return;
    }
    axios.put(`${BASE_URL}legalcontent/update/${id}`, { legal_title: values.legal_title, legal_type: values.legal_type, description: values.description }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        toast.success('Legal contents updated successfully', {
          autoClose: 1500,
        });
        setTimeout(() => {
          navigate(`/actionlegal/${id}?action=view`);
        });
      })
      .catch(error => {
        toast.error('Failed to update legal-contents', {
          autoClose: 1500,
        });
      });
  };

  const handleEdit = () => {
    navigate(`/actionlegal/${id}?action=edit`);
  };

  const handleCancel = () => {
    navigate(`/actionlegal/${id}?action=view`);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  return (
    <div className="container-xl my-4">
      <ToastContainer/>
      <div className="row">
        <div className="col-md-6">
          <h4>Legal Contents</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="/legalcontents" className='fs-6'>Back</Link></li>
              <li className="breadcrumb-item"><Link to="" className='fs-6'>ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-legal_title">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
            <Formik
              initialValues={{ legal_title, legal_type, description }}
              validationSchema={validationSchema}
              onSubmit={handleUpdate}
              enableReinitialize={true}  // Enable reinitialization of values
            >
              {({ handleChange, handleBlur, setFieldValue, values }) => (
                <Form className="row g-3">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className='form-label'>Legal Title<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field
                        type="text"
                        name="legal_title"
                        value={values.legal_title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!isEditable}
                        className="form-control mb-2"
                      />
                      <ErrorMessage name="legal_title" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className='form-label'>Legal Type<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field as="select"
                        name="legal_type"
                        value={values.legal_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!isEditable}
                        className="form-select mb-2"
                        style={{ color: !isEditable ? "#808080" : "#808080",}}
                      >
                        <option value="">Select</option>
                        <option value="Privacy_policy">Privacy Policy</option>
                        <option value="Terms_conditions">Terms & Conditions</option>
                        <option value="Licenses">Licenses</option>
                        <option value="Refund">Refund</option>
                      </Field>
                      <ErrorMessage name="legal_type" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    {isEditable ? (
                      <ReactQuill
                        theme="snow"
                        value={description}
                        onChange={(value) => {
                          handleDescriptionChange(value);
                          setFieldValue('description', value); // Update Formik's description field
                        }}
                        modules={{
                          toolbar: [
                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                            [{ 'size': [] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            ['link', 'image'],
                            ['clean']
                          ]
                        }}
                        style={{ height: '300px', borderRadius: '10px' }}
                        className="react-quill-editor mb-3"
                      />
                    ) : (
                      <div
                        className="form-control mb-2"
                        dangerouslySetInnerHTML={{ __html: description }}
                        style={{ minHeight: '130px', backgroundColor: "#f4f4f4" }}
                      />
                    )}
                    <ErrorMessage name="description" component="div" className="text-danger" />
                  </div>
                  <div className='card-footer'>
                    <div className="d-flex justify-content-end align-items-center">
                      {action === 'view' && rolePermissions.write === 'yes' && (
                        <button type="button" onClick={handleEdit} className="btn btn-primary mb-2">Edit</button>
                      )}
                      {isEditable && (
                        <button type="submit" className="btn btn-primary mx-3">Update</button>
                      )}
                      {action === 'edit' && (
                        <button type="button" onClick={handleCancel} className=' btn btn-label-secondary'>Cancel</button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionLegal;
