import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import {BASE_URL,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2 } from '../../../../Constant/Constant';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

const CarlistIndex = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        CarTitle: '',
        Description: ''
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [id, setId] = useState(null); // Initialize with null
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [isBookingBoxData, setIsBookingBoxData] = useState(false);

    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);
  
    useEffect(() => {
      if (permissions) {
        try {
          const modules = JSON.parse(permissions);
          const legalcontent = modules.find(module => module.rolename === 'staticcontent');
          if (legalcontent) {
            setRolePermissions(legalcontent.permissions);
            setIsBookingBoxData(legalcontent.permissions.write === 'no');
           
          }
        } catch (error) {
          console.error('Failed to parse permissions:', error);
        }
      }
    }, [permissions]);
    const fetchData = async () => {
        try {
            const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
            const response = await axios.get(url,{
                headers:{
                    'Authorization': `Bearer ${token}`
                }
            });
            setId(response.data.id);
            if (response.data && response.data.carlistindex) {
                const parsedData = JSON.parse(response.data.carlistindex);
                setFormValues(parsedData);
                setIsDataExists(true);
                setIsEditMode(false); // Data exists, so not in edit mode

              
            } else {
               
                setFormValues({
                    CarTitle: '',
                    Description: ''
                });
                setIsDataExists(false);
                setIsEditMode(true); // No data, so allow adding
            }
        } catch (error) {
            setIsEditMode(true); // Error fetching data, possibly allow editing
        }
    };

    useEffect(() => {
        if (token && languageId) {
            fetchData();
        }
    }, [token, languageId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleDescriptionChange = (value) => {
        setFormValues(prevValues => ({
            ...prevValues,
            Description: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            const payload = {
                carlistindex: formValues,
                language_id: languageId
            };
    
            const url = `${BASE_URL}staticcontent/carlistindex`;
    
            const response = await axios.post(url, payload,{
                headers:{
                    'Authorization': `Bearer ${token}`
                }
            });
    
            // Check if response data is in the expected format
            if (response.data && response.data.message === 'carlistindex Process successfully!') {
                toast.dismiss()
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);
               
            } else {
                toast.dismiss()
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }
    
            setIsEditMode(false);
        } catch (error) {
            toast.dismiss()
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Car Title</label>
                        <input
                            name="CarTitle"
                            type="text"
                            className="form-control"
                            value={formValues.CarTitle}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                {/* <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Car Description</label>
                        <ReactQuill
                            theme="snow"
                            value={formValues.Description}
                            onChange={handleDescriptionChange}
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    [{ 'size': [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['link', 'image'],
                                    ['clean']
                                ]
                            }}
                            style={{ height: '130px', borderRadius: '10px' }}
                            className="react-quill-editor mb-3"
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div> */}

                <div className="card-footer text-end" style={{"marginTop":"80px"}}>
                {rolePermissions.write === 'yes' && 
                    <button
                        type="submit"
                        className="btn btn-primary"
                      disabled={loading ||isBookingBoxData}
                    >
                        {loading ? 'Saving...' : (isDataExists ? 'Update' : 'Add')}
                    </button>
}
                </div>

            </form>
        </>
    );
};

export default CarlistIndex;
