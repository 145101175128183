import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { BASE_URL } from '../Constant/Constant';

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [isNewPasswordVisible, setIsNewPasswordVisible] = React.useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = React.useState(false);

    const toggleNewPasswordVisibility = () => {
        setIsNewPasswordVisible(!isNewPasswordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    };

    const validationSchema = Yup.object({
        newPassword: Yup.string()
            .required('New password is required')
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[\W_]/, 'Password must contain at least one special character'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Confirm password is required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await fetch(`${BASE_URL}user/reset-password/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newPassword: values.newPassword }),
            });

            if (response.ok) {
                toast.dismiss();
                toast.success('Password has been reset successfully.', {
                    autoClose: 2000,
                });
                navigate('/login');
            } else {
                const data = await response.json();
                toast.dismiss();
                toast.error(data.message || 'Error resetting password.', {
                    autoClose: 2000,
                });
            }
        } catch (error) {
            toast.dismiss();
            toast.error('Network error. Please try again.', {
                autoClose: 2000,
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="authentication-wrapper authentication-cover authentication-bg" style={{ backgroundColor: 'white' }}>
                <div className="authentication-inner row">
                    <div className="d-none d-lg-flex col-lg-7 p-0">
                        <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                            <img
                                src="/assets/img/illustrations/auth-reset-password-illustration-light.png"
                                alt="auth-reset-password-cover"
                                className="my-5 auth-illustration"
                                data-app-light-img="illustrations/auth-reset-password-illustration-light.png"
                                data-app-dark-img="illustrations/auth-reset-password-illustration-dark.png"
                            />
                            <img
                                src="/assets/img/illustrations/bg-shape-image-light.png"
                                alt="auth-reset-password-cover"
                                className="platform-bg"
                                data-app-light-img="illustrations/bg-shape-image-light.png"
                                data-app-dark-img="illustrations/bg-shape-image-dark.png"
                            />
                        </div>
                    </div>
                    <div className="d-flex col-12 col-lg-5 align-items-center p-4 p-sm-5">
                        <div className="w-px-400 mx-auto">
                            <div className="app-brand mb-4">
                                <a href="#" className="app-brand-link gap-2">
                                    <img alt="Aargauertaxi" src="/assets/img/template/logo-old.png" style={{ height: "45px", width: "230px", marginLeft: "-20px" }} />
                                </a>
                            </div>
                            <h4 className="mb-4">Reset Password 🔒</h4>
                            <Formik
                                initialValues={{ newPassword: '', confirmPassword: '' }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting }) => (
                                    <Form id="formAuthentication" className="mb-3 needs-validation" noValidate>
                                        <div className="mb-3 form-password-toggle">
                                            <label className="form-label" htmlFor="newPassword">New Password</label>
                                            <div className="input-group input-group-merge">
                                                <Field
                                                    id="newPassword"
                                                    name="newPassword"
                                                    type={isNewPasswordVisible ? 'text' : 'password'}
                                                    className="form-control"
                                                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                                />
                                                <span className="input-group-text cursor-pointer" onClick={toggleNewPasswordVisibility}>
                                                    <i className={isNewPasswordVisible ? "ti ti-eye" : "ti ti-eye-off"}></i>
                                                </span>
                                            </div>
                                            <ErrorMessage name="newPassword" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="mb-3 form-password-toggle">
                                            <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                                            <div className="input-group input-group-merge">
                                                <Field
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    type={isConfirmPasswordVisible ? 'text' : 'password'}
                                                    className="form-control"
                                                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                                />
                                                <span className="input-group-text cursor-pointer" onClick={toggleConfirmPasswordVisibility}>
                                                    <i className={isConfirmPasswordVisible ? "ti ti-eye" : "ti ti-eye-off"}></i>
                                                </span>
                                            </div>
                                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                        </div>
                                        <button className="btn btn-primary d-grid w-100 mb-3" type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? 'Submitting...' : 'Set New Password'}
                                        </button>
                                        <div className="text-center">
                                            <a href="/login">
                                                <i className="ti ti-chevron-left scaleX-n1-rtl"></i>
                                                Back to Login
                                            </a>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
