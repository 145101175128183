import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {BASE_URL,BACKENDTOKEN,SELECTEDLANGUAGEID} from '../../../../Constant/Constant';
import { toast } from 'react-toastify';


const ContactUs = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        Title: "",
        TitleSlug: "",
        SupportMainTitle: "",
        Support1Title: "",
        Support1Description: "",
        Support2Title: "",
        Support2Description: "",
        Support3Title: "",
        Support3Description: "",
        Support4Title: "",
        Support4Description: "",
        AddressTitle: "",
        AddressTitleSlug: ""
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [languageId, setLanguageId] = useState(`${SELECTEDLANGUAGEID}`);
    const [id, setId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url,{
                    headers:{
                        'Authorization': `Bearer ${BACKENDTOKEN}`
                    }
                });
                if (response.data && response.data.contactus) {
                    const parsedData = JSON.parse(response.data.contactus);
                    setFormValues(parsedData);
                    setId(response.data.id);
                    setIsDataExists(true);
                    setIsEditMode(false);
                } else {
                    setFormValues({
                        Title: '',
                        TitleSlug: '',
                        SupportMainTitle: '',
                        Support1Title: '',
                        Support1Description: '',
                        Support2Title: '',
                        Support2Description: '',
                        Support3Title: '',
                        Support3Description: '',
                        Support4Title: '',
                        Support4Description: '',
                        AddressTitle: '',
                        AddressTitleSlug: ''
                    });
                    setIsDataExists(false);
                    setIsEditMode(true);
                }
            } catch (error) {
                setIsEditMode(true);
            }
        };

        fetchData();
    }, [languageId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleDescriptionChange = (value, key) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [key]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                contactus: formValues,
                language_id: languageId
            };

            const url = `${BASE_URL}staticcontent/contactus`;

            const response = await axios.post(url, payload,{
                headers:{
                    'Authorization': `Bearer ${BACKENDTOKEN}`
                }
            });

            if (response.data && response.data.message === 'contactus Process successfully!') {
                toast.dismiss()
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);
            } else {
                toast.dismiss()
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.dismiss()
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                {/* First Row */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Title</label>
                        <input
                            name="Title"
                            type="text"
                            className="form-control"
                            value={formValues.Title}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Title Slug</label>
                        <input
                            name="TitleSlug"
                            type="text"
                            className="form-control"
                            value={formValues.TitleSlug}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Support Main Title</label>
                        <input
                            name="SupportMainTitle"
                            type="text"
                            className="form-control"
                            value={formValues.SupportMainTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>

                {/* Second Row */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Support1 Title</label>
                        <input
                            name="Support1Title"
                            type="text"
                            className="form-control"
                            value={formValues.Support1Title}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-8 mb-5">
                    <div className="mb-3">
                        <label className="form-label">Support1 Description</label>
                        <ReactQuill
                            theme="snow"
                            value={formValues.Support1Description}
                            onChange={(value) => handleDescriptionChange(value, 'Support1Description')}
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    [{ 'size': [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['link', 'image'],
                                    ['clean']
                                ]
                            }}
                            style={{ height: '130px', borderRadius: '10px' }}
                            className="react-quill-editor mb-3"
                            disabled={loading}
                        />
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Support2 Title</label>
                        <input
                            name="Support2Title"
                            type="text"
                            className="form-control"
                            value={formValues.Support2Title}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-8 mb-5">
                    <div className="mb-3">
                        <label className="form-label">Support2 Description</label>
                        <ReactQuill
                            theme="snow"
                            value={formValues.Support2Description}
                            onChange={(value) => handleDescriptionChange(value, 'Support2Description')}
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    [{ 'size': [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['link', 'image'],
                                    ['clean']
                                ]
                            }}
                            style={{ height: '130px', borderRadius: '10px' }}
                            className="react-quill-editor mb-3"
                            disabled={loading}
                        />
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Support3 Title</label>
                        <input
                            name="Support3Title"
                            type="text"
                            className="form-control"
                            value={formValues.Support3Title}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-8 mb-5">
                    <div className="mb-3">
                        <label className="form-label">Support3 Description</label>
                        <ReactQuill
                            theme="snow"
                            value={formValues.Support3Description}
                            onChange={(value) => handleDescriptionChange(value, 'Support3Description')}
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    [{ 'size': [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['link', 'image'],
                                    ['clean']
                                ]
                            }}
                            style={{ height: '130px', borderRadius: '10px' }}
                            className="react-quill-editor mb-3"
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Support4 Title</label>
                        <input
                            name="Support4Title"
                            type="text"
                            className="form-control"
                            value={formValues.Support4Title}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-8 mb-5">
                    <div className="mb-3">
                        <label className="form-label">Support4 Description</label>
                        <ReactQuill
                            theme="snow"
                            value={formValues.Support4Description}
                            onChange={(value) => handleDescriptionChange(value, 'Support4Description')}
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    [{ 'size': [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['link', 'image'],
                                    ['clean']
                                ]
                            }}
                            style={{ height: '130px', borderRadius: '10px' }}
                            className="react-quill-editor mb-3"
                            disabled={loading}
                        />
                    </div>
                </div>

                {/* Third Row */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Address Title</label>
                        <input
                            name="AddressTitle"
                            type="text"
                            className="form-control"
                            value={formValues.AddressTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Address Title Slug</label>
                        <input
                            name="AddressTitleSlug"
                            type="text"
                            className="form-control"
                            value={formValues.AddressTitleSlug}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>

                <div className="card-footer text-end">
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Saving...' : (isDataExists ? 'Update' : 'Add')}
                    </button>
                </div>
            </form>
        </>
    );
};

export default ContactUs;
