import React, { useState, useEffect } from 'react';
import TableMain from '../../TableComponents/Table/TableMain';
import Header from '../../TableComponents/Header';
import Breadcrumb from '../../TableComponents/Breadcrumb';
import SearchMain from '../../TableComponents/SearchComponents/SearchMain';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpWideShort, faArrowDownShortWide, faArrowsUpDown } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL, BACKENDTOKEN, TABLE_RECORDSPERPAGE ,SELECTEDLANGUAGEID,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2 } from '../../Constant/Constant'
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import CryptoJS from 'crypto-js';
import '../../App.css'

const RecentList = () => {
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 100;

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [filterTitle, setFilterTitle] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedBookingId, setSelectedBookingId] = useState(null);

    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);

    useEffect(() => {
        if (permissions) {
            try {
                const modules = JSON.parse(permissions);
                const Booking = modules.find(module => module.rolename === 'Booking');
                if (Booking) {
                    setRolePermissions(Booking.permissions);
                }
            } catch (error) {
                console.error('Failed to parse permissions:', error);
            }
        }
    }, [permissions]);


    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${BASE_URL}booking/get?language_id=${languageId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            if (!response.ok) {
                throw new Error(`Server error: ${response.status}`);
            }
    
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                const result = await response.json();
                if (Array.isArray(result) && result.length > 0) {
                    // Only display the last 5 records
                    const lastFiveRecords = result.slice(-5); 
                    setData(lastFiveRecords);
                    setFilteredData(lastFiveRecords);
                    setSelectedRows(Array(lastFiveRecords.length).fill(false)); // Initialize selected rows
    
                } else {
                    console.warn('No data found for the given language ID.');
                }
            } else {
                const text = await response.text();
                throw new Error('Unexpected content type: ' + contentType);
            }
        } catch (error) {
            setError('Error fetching data: ' + error.message);
        } finally {
            setLoading(false);
        }
    };
    
    // Use effect to refresh data every 1 minute (60000 milliseconds)
    useEffect(() => {
        // Fetch data initially
        fetchData();
    
        // Set interval to refresh every 1 minute
        const intervalId = setInterval(() => {
            fetchData();
        }, 60000); // 1 minute = 60000 ms
    
        // Cleanup function to clear interval when component unmounts
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures this runs only once on mount
    

    useEffect(() => {
        if (token && languageId) {
            fetchData();
        }
    }, [token, languageId]);
   

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);

        try {
            // Check permissions before proceeding
            if (rolePermissions.write === 'no') {
                toast.error('You do not have permission to update data.', {
                    autoClose: 1500,
                });
                return;
            }

            // Prepare data to send
            const dataToSend = { ...values };

            // Make the PUT request to update the booking
            const response = await fetch(`${BASE_URL}booking/update/${selectedBookingId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            });

            if (!response.ok) {
                // Handle non-2xx HTTP responses
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error updating booking');
            }

            // Handle successful response
            const responseData = await response.json();
            toast.success("Successfully updated");
            resetForm();
            setModalOpen(false);
            setSelectedBookingId(null);

            // Fetch updated data
            await fetchData(); // Ensure fetchData is defined and correctly fetches updated data
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to update: ' + error.message);
        } finally {
            setLoading(false);
        }
    };


    const handleRowAction = async (action, id) => {
        switch (action) {
            case 'view':
                if (rolePermissions.read === 'no') {
                    toast.dismiss();
                    toast.error('You do not have permission to update data.', {
                        autoClose: 1500,
                    });
                    return;
                }
                navigate(`../ActionBooking/${id}`);
                break;
            case 'update':
                if (rolePermissions.write === 'no') {
                    toast.dismiss();
                    toast.error('You do not have permission to update data.', {
                        autoClose: 1500,
                    });
                    return;
                }
                setSelectedBookingId(id);
                setModalOpen(true);
                break;
            case 'cancel':
                if (rolePermissions.write === 'no') {
                    toast.dismiss();
                    toast.error('You do not have permission to update data.', {
                        autoClose: 1500,
                    });
                    return;
                }
                const confirmCancel = window.confirm(`Are you sure you want to cancel the booking with ID ${id}?`);
                if (confirmCancel) {
                    try {
                        const response = await fetch(`${BASE_URL}booking/update-status/${id}`, {
                            method: 'PUT',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ status: 'cancel' }),
                        });

                        if (!response.ok) {
                            throw new Error(`Failed to cancel booking with ID ${id}`);
                        }

                        setFilteredData(prevFilteredData =>
                            prevFilteredData.map(item =>
                                item.id === id ? { ...item, status: 'cancel' } : item
                            )
                        );

                        // Reload the content by fetching the updated bookings
                        await fetchData();
                    } catch (error) {
                        console.error('Error updating booking status:', error);
                    }
                }
                break;
            default:
                console.warn(`Unknown action ${action} for item with id ${id}`);
        }
    };

    const handlePageChange = (pageNumber) => {
        const totalPages = Math.ceil(data.length / recordsPerPage);
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

    const handlePrimaryClick = () => {
        // Filter data based on filterTitle and filterStatus
        const filtered = data.filter(item => {
            return (
                (filterTitle.trim() === '' || item.name.toLowerCase().includes(filterTitle.trim().toLowerCase())) &&
                (filterStatus.trim() === '' || item.status.toLowerCase() === filterStatus.trim().toLowerCase())
            );
        });
        setFilteredData(filtered);
        setCurrentPage(1);
    };

    const handleResetClick = () => {
        setFilterTitle('');
        setFilterStatus('');
        setFilteredData(data);
        setCurrentPage(1);
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedData = [...filteredData].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

        setFilteredData(sortedData);
    };


    const getSortIndicator = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc' ? (
                <FontAwesomeIcon icon={faArrowUpWideShort} />
            ) : (
                <FontAwesomeIcon icon={faArrowDownShortWide} />
            );
        }
        return <FontAwesomeIcon icon={faArrowsUpDown} />;
    };

    if (loading) {
        return <div className="text-center">Loading data...</div>;
    }

    // if (error) {
    //     return <div className="text-center text-danger mt-5">{error}</div>;
    // }
    const initialValues = { payment_method: '', payment_status: '', status: '' };
    return (
        <div className="container-xl my-4 mt-0 recentTem" >
            <Helmet>
                <title>Booking List </title>
                <meta name="description" content="This is the booking list page of the application" />
            </Helmet>
            {/* <div className='d-flex justify-content-between'>
                <Header title="Booking" />
                <Breadcrumb items={[
                    { label: "Back", link: "/RecentList" },
                    { label: "Booking", link: "#" }
                ]} />
            </div>

            <SearchMain
                statusOptions={[
                    { value: "process", label: "Process" },
                    { value: "complete", label: "Complete" },
                    { value: "cancel", label: "Cancel" }
                ]}
                searcherData={{ namePlaceholder: "Enter Name.." }}
                filterTitle={filterTitle}
                filterStatus={filterStatus}
                handlePrimaryClick={handlePrimaryClick}
                handleResetClick={handleResetClick}
                setFilterTitle={setFilterTitle}
                setFilterStatus={setFilterStatus}
            /> */}

            <TableMain
                title="Recent Booking "
                RecentLists={true}
                addLink="../bookinglist"
                columns={[
                    { key: "booking_id", label: "Booking Id" },
                    { key: "from_location_to_location", label: "Pickup Location - Drop Location" }, // Combine From and To locations with a line break
                    { key: "date_time", label: "Date- Time" }, // Combine Date and Time fields
                    { key: "total_price", label: "Total Price" },
                    { key: "payment_status", label: "Payment Status" },
                    { key: "status", label: "Status" },
                ]}
                data={currentRecords.map(record => ({
                    ...record,
                    from_location_to_location: (
                        <>
                            {record.from_location} -
                            <br />
                            {record.to_location}
                        </>
                    ), // Combine From and To locations with a line break
                    date_time: (
                        <>
                           {record.date} -
                            <br />
                            {record.time}
                        </>
                    ),
                }))}
                setData={setData}
                selectedRows={selectedRows}
                handleRowSelect={() => { }}
                setSelectedRows={setSelectedRows}
                currentPage={currentPage}
                recordsPerPage={recordsPerPage}
                onPageChange={handlePageChange}
                handleRowAction={handleRowAction}
                filteredData={filteredData}
                isBookingListPage={true}
                totalRecords={filteredData.length}
                handleSort={handleSort}
                getSortIndicator={getSortIndicator}
                canEdit={rolePermissions.write === 'yes'}
               
            />



            {modalOpen && (
                <>
                    <div
                        className="modal fade show"
                        style={{ display: 'block' }}
                        tabIndex="-1"
                        aria-hidden={!modalOpen}
                        aria-labelledby="modalTitle"
                        aria-describedby="modalDescription"
                    >
                        <ToastContainer />
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content" style={{ backgroundColor: 'white' }}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modalTitle">Update Status</h5>
                                    <button type="button" className="btn-close" onClick={() => setModalOpen(false)} aria-label="Close"></button>
                                </div>




                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={Yup.object().shape({
                                        payment_method: Yup.string().required('Payment method is required'),
                                        payment_status: Yup.string().required('Payment status is required'),
                                        status: Yup.string().required('Status is required') // Adding validation for status
                                    })}
                                    onSubmit={handleSubmit}
                                >
                                    {formik => (
                                        <Form>
                                            <div className="modal-body" id="modalDescription">
                                                <div className="row g-3">
                                                    {/* Payment Method */}
                                                    <div className="col-6 mb-0">
                                                        <label htmlFor="payment_method" className="form-label">Select Payment Method<span className='text-danger px-1 fs-5'>*</span></label>
                                                        <Field
                                                            as="select"
                                                            id="payment_method"
                                                            name="payment_method"
                                                            className={`form-select ${formik.errors.payment_method && formik.touched.payment_method ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="paypal">Paypal</option>
                                                            <option value="CashPayment">Cash Payment</option>
                                                        </Field>
                                                        {formik.errors.payment_method && formik.touched.payment_method && (
                                                            <div className="invalid-feedback">{formik.errors.payment_method}</div>
                                                        )}
                                                    </div>

                                                    {/* Payment Status */}
                                                    <div className="col-6">
                                                        <label htmlFor="payment_status" className="form-label">Payment Status<span className='text-danger px-1 fs-5'>*</span></label>
                                                        <Field
                                                            as="select"
                                                            id="payment_status"
                                                            name="payment_status"
                                                            className={`form-select ${formik.errors.payment_status && formik.touched.payment_status ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="paid">Paid</option>
                                                            <option value="unpaid">Unpaid</option>
                                                        </Field>
                                                        {formik.errors.payment_status && formik.touched.payment_status && (
                                                            <div className="invalid-feedback">{formik.errors.payment_status}</div>
                                                        )}
                                                    </div>

                                                    {/* Status */}
                                                    <div className="col-6">
                                                        <label htmlFor="status" className="form-label">Status<span className='text-danger px-1 fs-5'>*</span></label>
                                                        <Field
                                                            as="select"
                                                            id="status"
                                                            name="status"
                                                            className={`form-select ${formik.errors.status && formik.touched.status ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="process">Process</option>
                                                            <option value="cancel">Cancel</option>
                                                            <option value="complete">Completed</option>
                                                        </Field>
                                                        {formik.errors.status && formik.touched.status && (
                                                            <div className="invalid-feedback">{formik.errors.status}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-label-secondary" onClick={() => setModalOpen(false)}>Close</button>
                                                <button type="submit" className="btn btn-primary">Update</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>


                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" onClick={() => setModalOpen(false)}></div>
                </>
            )}

        </div>
    );
};

export default RecentList;
