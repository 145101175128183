import React from 'react';

const Header = ({ title }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <h4>
          <span className="text-muted fw-light"></span>{title}
        </h4>
      </div>
    </div>
  );
};

export default Header;
