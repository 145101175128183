import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BASE_URL, CRYPTOJS_ENCRYPTION_KEY } from '../../../Constant/Constant';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CryptoJS from 'crypto-js';

const ActionFAQ = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isEditable, setIsEditable] = useState(false);

  const permissions = useSelector(state => state.roles.permissions.user_modules);
  const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
  const [token, setToken] = useState(null);

  // Function to decrypt tokens
  const decryptToken = (encryptedToken, key) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
      const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedToken ? decryptedToken : null;
    } catch (error) {
      console.error('Error decrypting token:', error);
      return null;
    }
  };

  // Fetch token from localStorage and decrypt it
  const fetchBackendToken = async () => {
    const encryptedBackendToken = localStorage.getItem('BackendToken');
    if (encryptedBackendToken) {
      const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
      setToken(decryptedToken); // Set token to state
    } else {
      console.warn('No BackendToken found in localStorage.');
    }
  };

  useEffect(() => {
    fetchBackendToken();
  }, []);

  useEffect(() => {
    if (permissions) {
      try {
        const modules = JSON.parse(permissions);
        const faq = modules.find(module => module.rolename === 'faq');
        if (faq) {
          setRolePermissions(faq.permissions);
        }
      } catch (error) {
        console.error('Failed to parse permissions:', error);
      }
    }
  }, [permissions]);
  const fetchLegalContents = async () => {

    axios.get(`${BASE_URL}faq/get/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        setTitle(response.data.title);
        setDescription(response.data.description);

      })
      .catch(error => {
        toast.dismiss();
        toast.error('Error fetching FAQ', {
          autoClose: 1500,
        });
      });
  };
  useEffect(() => {
    if (token) {
      fetchLegalContents();
    }
  }, [token]);

  useEffect(() => {
    setIsEditable(action === 'edit');
  }, [action]);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required'),
    description: Yup.string()
      .required('Description is required'),
  });

  const handleUpdate = (values) => {
    if (rolePermissions.write === 'no') {
      toast.dismiss();
      toast.error('You do not have permission to update data.', {
        autoClose: 1500,
      });
      return;
    }

    axios.put(`${BASE_URL}faq/update/${id}`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        toast.dismiss();
        toast.success('FAQ updated successfully', {
          autoClose: 1500,
        });
        setTimeout(() => {
          navigate(`/faqaction/${id}?action=view`);
        });
      })
      .catch(error => {
        toast.dismiss();
        toast.error('Failed to update FAQ', {
          autoClose: 1500,
        });
      });
  };

  const handleEdit = () => {
    navigate(`/faqaction/${id}?action=edit`);
  };

  const handleCancel = () => {
    navigate(`/faqaction/${id}?action=view`);
  };

  const handleDescriptionChange = (value, setFieldValue) => {
    setDescription(value);
    setFieldValue('description', value); // Update Formik description field
  };

  return (
    <div className="container-xl my-4">
      <div className="row">
        <div className="col-md-6">
          <h4>FAQ</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="/faq" className='fs-6'>Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-title">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
            <Formik
              initialValues={{ title, description }}
              validationSchema={validationSchema}
              onSubmit={handleUpdate}
              enableReinitialize={true} // Allows values to reinitialize when fetched from backend
            >
              {({ handleChange, handleBlur, setFieldValue, values }) => (
                <Form>
                  <div className="mb-3">
                    <label className='form-label'>Title<span className='text-danger px-1 fs-5'>*</span></label>
                    <Field
                      type="text"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!isEditable}
                      className="form-control mb-2"
                    />
                    <ErrorMessage name="title" component="div" className="text-danger" />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    {isEditable ? (
                      <ReactQuill
                        theme="snow"
                        value={description}
                        onChange={(value) => handleDescriptionChange(value, setFieldValue)}
                        modules={{
                          toolbar: [
                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                            [{ 'size': [] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            ['link', 'image'],
                            ['clean']
                          ]
                        }}
                        className="react-quill-editor mb-3"
                      />
                    ) : (
                      <div
                        className="form-control mb-2"
                        dangerouslySetInnerHTML={{ __html: description }}
                        style={{ minHeight: '130px', backgroundColor: "#f4f4f4" }}
                      />
                    )}
                    <ErrorMessage name="description" component="div" className="text-danger" />
                  </div>

                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    {action === 'view' && rolePermissions.write === 'yes' && (
                      <button type="button" onClick={handleEdit} className="btn btn-primary mb-2">Edit</button>
                    )}
                    {isEditable && (
                      <button type="submit" className="btn btn-primary mx-3">Update</button>
                    )}
                    {action === 'edit' && (
                      <button type="button" onClick={handleCancel} className="btn btn-label-secondary">Cancel</button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ActionFAQ;
