// src/Redux/Slice/rolesSlice.js
import { createSlice } from '@reduxjs/toolkit';

const rolesSlice = createSlice({
    name: 'roles',
    initialState: {
        permissions: [],
    },
    reducers: {
        fetchUserPermissions(state, action) {
            state.permissions = action.payload;
        },
        clearUserPermissions(state) {
            state.permissions = [];
        },
    },
});

export const { fetchUserPermissions, clearUserPermissions } = rolesSlice.actions;

export default rolesSlice.reducer;
