import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../Redux/Slice/authSlice';
import { fetchUserPermissions } from '../Redux/Slice/roleSlice';
import { Helmet } from 'react-helmet';
import { BASE_URL, BACKENDTOKEN, CRYPTOJS_ENCRYPTION_KEY, CRYPTOJS_ENCRYPTION_KEY_2 } from '../Constant/Constant'; // Import CRYPTOJS_ENCRYPTION_KEY_2
import CryptoJS from 'crypto-js';

// Validation schema
const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email.'),
    password: Yup.string()
        .required('Please enter your password.')
});

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [userId, setUserId] = useState();
    const [languages, setLanguages] = useState([]); // Add state for languages
    const [selectedLanguage, setSelectedLanguage] = useState(''); // Add state for selected language

    // Decrypt token function
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken || null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };

    // Check for token in localStorage on mount
    useEffect(() => {
        const encryptedToken = localStorage.getItem('BackendToken');
        const token = encryptedToken ? decryptToken(encryptedToken, CRYPTOJS_ENCRYPTION_KEY) : null;

        if (!token) {
            console.warn('No valid token found. Redirecting to login.');
            navigate('/login'); // Redirect to login if token is not found
        }
    }, [navigate]);

    // Handle login form submission
    const handleLogin = async (values, { setSubmitting }) => {
        const apiUrl = `${BASE_URL}user/login`;

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Login failed');
            }

            const data = await response.json();

            // Encrypt token before storing in localStorage
            const encryptedToken = CryptoJS.AES.encrypt(data.token, CRYPTOJS_ENCRYPTION_KEY).toString();
            localStorage.setItem('BackendToken', encryptedToken);

            // Fetch languages and set default language
            try {
                const langResponse = await fetch(`${BASE_URL}api/language`);
                if (!langResponse.ok) {
                    throw new Error('Network response was not ok');
                }
                const langResult = await langResponse.json();
                setLanguages(langResult);

                const defaultLang = langResult.find(lang => lang.language_default === 1);
                if (defaultLang) {
                    setSelectedLanguage(defaultLang.language_code);

                    // Encrypt and store default language ID
                    const encryptedLangId = CryptoJS.AES.encrypt(defaultLang.language_id.toString(), CRYPTOJS_ENCRYPTION_KEY_2).toString();
                    localStorage.setItem('selectedLanguageId', encryptedLangId);
                }
            } catch (error) {
                console.error('Error fetching languages:', error);
            }

            // Dispatch login success and store userId
            dispatch(loginSuccess({ userId: data.userId }));
            setUserId(data.userId);

            // Fetch user permissions
            const permissionsResponse = await fetch(`${BASE_URL}user/permission/${data.userId}`, {
                method: 'GET',
            });

            if (!permissionsResponse.ok) {
                throw new Error('Failed to fetch user permissions');
            }

            const permissionsData = await permissionsResponse.json();
            dispatch(fetchUserPermissions(permissionsData));

            navigate('/dashboard');

        } catch (error) {
            console.error("Caught error:", error.message);
            toast.dismiss();
            toast.error(error.message);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="authentication-wrapper authentication-cover authentication-bg" style={{ backgroundColor: 'white' }}>
            <Helmet>
                <title>Login</title>
                <meta name="description" content="Log in to your account to access personalized features and services." />
            </Helmet>
            <div className="authentication-inner row">
                <div className="d-none d-lg-flex col-lg-7 p-0">
                    <div className="auth-cover-bg d-flex justify-content-center align-items-center" style={{ backgroundColor: '#f8f7fa' }}>
                        <img src="./assets/img/illustrations/auth-login-illustration-light.png" alt="auth-login-cover" className="my-5 auth-illustration" />
                        <img src="./assets/img/illustrations/bg-shape-image-light.png" alt="auth-login-cover" className="platform-bg" />
                    </div>
                </div>
                <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
                    <div className="w-px-400 mx-auto">
                        <div className="app-brand mb-3">
                            <Link className="d-flex" to="/">
                                <img
                                    alt="Aargauertaxi"
                                    src="/assets/img/template/logo-old.png"
                                    style={{ height: "45px", width: "230px", marginLeft: "-10px" }}
                                />
                            </Link>
                        </div>
                        <h3 className="mb-1">Welcome to Aargauertaxi</h3>
                        <p className="mb-4">Please sign-in to your account and start the adventure</p>
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleLogin}
                            validateOnChange={true}
                            validateOnBlur={true}
                        >
                            {({ isSubmitting, errors, touched }) => (
                                <Form className="mb-3 needs-validation" noValidate>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <Field
                                            type="email"
                                            name="email"
                                            className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                                            placeholder="Enter your email"
                                        />
                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="mb-3 form-password-toggle">
                                        <div className="d-flex justify-content-between">
                                            <label className="form-label" htmlFor="password">Password</label>
                                            <Link to="../forgotpassword">
                                                <small>Forgot Password?</small>
                                            </Link>
                                        </div>
                                        <div className="input-group input-group-merge">
                                            <Field
                                                as="input"
                                                name="password"
                                                type={isPasswordVisible ? 'text' : 'password'}
                                                className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                                placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                            />
                                            <span className="input-group-text cursor-pointer" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                                                <i className={isPasswordVisible ? "ti ti-eye" : "ti ti-eye-off"}></i>
                                            </span>
                                        </div>
                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                    </div>
                                    <button className="btn btn-primary d-grid w-100" type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Processing...' : 'Login'}
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Login;
