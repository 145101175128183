import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL ,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2 } from '../../../Constant/Constant';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill styles
import { MdOutlineCancel } from 'react-icons/md'; // Add your required imports
import { IoAddCircleOutline } from 'react-icons/io5';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const AboutUs = () => {
    const [loading, setLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [isBookingBoxData, setIsBookingBoxData] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [initialValues, setInitialValues] = useState({
        title: '',
        description: '',
        points: [''],
        image: ''
    });
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); // Set language ID to state
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };

    useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);

    useEffect(() => {
        if (permissions) {
            try {
                const modules = JSON.parse(permissions);
                const legalcontent = modules.find(module => module.rolename === 'aboutus');
                if (legalcontent) {
                    setRolePermissions(legalcontent.permissions);
                    setIsBookingBoxData(legalcontent.permissions.write === 'no');
                }
            } catch (error) {
                console.error('Failed to parse permissions:', error);
            }
        }
    }, [permissions]);
    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}about/get`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data) {
                const data = response.data;
                data.points = Array.isArray(data.points) ? data.points : JSON.parse(data.points || '[]');

                // Set the initial values based on the fetched data
                setInitialValues({
                    title: data.title,
                    description: data.description,
                    points: data.points.length > 0 ? data.points : [''],
                    image: data.image || ''
                });
                setIsDataExists(true);
            } else {
                setIsDataExists(false);
            }
        } catch (error) {
            setIsEditMode(true);
        }
    };
    useEffect(() => {
        if (token && languageId) {
            fetchData();
        }
    }, [token, languageId]);
   
    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required'),
        points: Yup.array().of(Yup.string().required('Point cannot be empty')),
        // Validation for new image upload only
        image: Yup.mixed().test(
            'fileRequired',
            'Image is required',
            function (value) {
                if (value && value instanceof File) {
                    return true; // New file is present
                }
                return !!this.parent.image; // Return true if an existing image already exists
            }
        ).test(
            'fileFormat',
            'Unsupported format',
            function (value) {
                if (value && value instanceof File) {
                    return ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
                }
                return true; // Skip format validation for existing images
            }
        ).test(
            'fileSize',
            'File size should be less than 1MB',
            function (value) {
                if (value && value instanceof File) {
                    return value.size < 1024 * 1024; // Check file size
                }
                return true; // Skip size validation for existing images
            }
        ),

    });

    const handleFileChange = (e, setFieldValue) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
                setFieldValue('image', file);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (values) => {
        if (rolePermissions.write === 'no') {
            toast.error('You do not have permission to update data.', { autoClose: 1500 });
            return;
        }

        setLoading(true);
        // Define validation rules for images
        const imageValidations = [
            {
                file: values.image,
                dimensions: { width: 1920, height: 550 },
                errorMessage: 'Image must be 1920 x 550 pixels.',
            },
        ];

        // Validate each new image (skip validation if the file is not a new File)
        for (const { file, dimensions, errorMessage } of imageValidations) {
            // Check if the file is a new file
            if (file && file instanceof File) {
                console.log('Checking file:', file); // Debugging log

                const isValid = await checkImageDimensions(file, dimensions);
                console.log(`Validation result for ${errorMessage}:`, isValid); // Debugging log

                if (!isValid) {
                    toast.dismiss()
                    toast.error(errorMessage, { autoClose: 1500 });
                    return;
                }
            } else {
                console.log('Skipping validation for existing image:', file); // Skip existing images
            }
        }


        try {
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('description', values.description);
            formData.append('points', JSON.stringify(values.points));
            formData.append('language_id', languageId);

            if (values.image instanceof File) {
                formData.append('image', values.image);
            }

            const url = `${BASE_URL}about/add`;
            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.message) {
                toast.success(response.data.message, { autoClose: 1500 });
            } else {
                toast.error('Unexpected response from the server.', { autoClose: 1500 });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.error('Error saving data.', { autoClose: 1500 });
        } finally {
            setLoading(false);
        }
    };

    // Updated checkImageDimensions function to accept dimensions
    const checkImageDimensions = (file, { width, height }) => {
        return new Promise((resolve) => {
            const img = new Image();
            const objectUrl = URL.createObjectURL(file);

            img.onload = () => {
                const isValid = img.width === width && img.height === height;
                URL.revokeObjectURL(objectUrl);
                resolve(isValid);
            };

            img.onerror = () => {
                URL.revokeObjectURL(objectUrl);
                resolve(false);
            };
            img.src = objectUrl;
        });
    };

    return (
        <div className="container-xl my-4">
            <ToastContainer />
            <div className="row">
                <div className="col-md-6">
                    <h4>AboutUs</h4>
                </div>
                <div className="col-md-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end fs-3">
                            <li className="breadcrumb-item"><Link to="../aboutus" className="fs-6">Back</Link></li>
                            <li className="breadcrumb-item"><Link to="#" className="fs-6">ActionPage</Link></li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="col-12 mt-3">
                <div className="card mt-3">
                    <div className="card-header header-underline pb-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="card-name">{isDataExists ? 'Edit ' : 'Add '}</h5>
                        </div>
                    </div>
                    <div className="card-body mt-3">
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, setFieldValue }) => (
                                <Form className="row g-3">
                                    {/* Form Fields */}
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">Title<span className='text-danger px-1 fs-5'>*</span></label>
                                            <Field
                                                name="title"
                                                type="text"
                                                className="form-control"
                                                disabled={isBookingBoxData}
                                            />
                                            <ErrorMessage name="title" component="div" className="text-danger" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className=''>

                                            <label className="col-sm form-label" htmlFor="image">Image (1920 x550)<span className='text-danger px-1 fs-5'>*</span></label>
                                            <div className=" d-flex flex-row">
                                                <div className="col-md-4">
                                                    {previewImage ? (
                                                        <img
                                                            src={previewImage}
                                                            alt="Image Preview"
                                                            className="img-fluid mb-2 rounded"
                                                        />
                                                    ) : values.image && (
                                                        <img
                                                            src={`${BASE_URL}uploads/${values.image}`}
                                                            alt="Existing Image"
                                                            className="img-fluid mb-2 rounded"
                                                        />
                                                    )}
                                                </div>
                                                <div className='col-md-6 me-3'>
                                                    {rolePermissions.write === 'yes' && (
                                                        <input
                                                            type="file"
                                                            id="image"
                                                            accept="image/jpeg, image/png, image/gif"
                                                            onChange={(e) => handleFileChange(e, setFieldValue)}
                                                            className="form-control mb-2"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <ErrorMessage name="image" component="div" className="text-danger" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="">
                                            <label className="form-label">Description<span className='text-danger px-1 fs-5'>*</span></label>
                                            {rolePermissions.write === 'yes' ? (
                                                <ReactQuill
                                                    theme="snow"
                                                    value={values.description}
                                                    onChange={(value) => setFieldValue('description', value)}
                                                    modules={{
                                                        toolbar: [
                                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                            [{ 'size': [] }],
                                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                            ['link', 'image'],
                                                            ['clean']
                                                        ]
                                                    }}
                                                    style={{ height: '130px', borderRadius: '10px' }}
                                                    className="react-quill-editor mb-3"
                                                    disabled={loading || isBookingBoxData}
                                                />
                                            ) : (
                                                <div
                                                    className="form-control mb-2"
                                                    dangerouslySetInnerHTML={{ __html: values.description }}
                                                    style={{ minHeight: '130px', backgroundColor: "#f4f4f4" }}
                                                />
                                            )}
                                            <ErrorMessage name="description" component="div" className="text-danger" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="">
                                            <label className=" form-label">Points<span className='text-danger px-1 fs-5'>*</span></label>
                                            <FieldArray name="points">
                                                {({ push, remove }) => (
                                                    <>
                                                        {values.points.map((point, index) => (
                                                            <div key={index} className="input-group mb-3">
                                                                <Field
                                                                    name={`points[${index}]`}
                                                                    type="text"
                                                                    className="form-control"
                                                                    disabled={isBookingBoxData}
                                                                />
                                                                {rolePermissions.write === 'yes' && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-label-secondary waves-effect waves-light"
                                                                        onClick={() => remove(index)}
                                                                    >
                                                                        <MdOutlineCancel />
                                                                    </button>
                                                                )}
                                                                <ErrorMessage name={`points[${index}]`} component="div" className="text-danger" />
                                                            </div>
                                                        ))}
                                                        {rolePermissions.write === 'yes' && (
                                                            <button type="button" className="btn btn-primary" onClick={() => push('')}>
                                                                <IoAddCircleOutline style={{ fontSize: '16px' }} />
                                                            </button>
                                                        )}
                                                    </>
                                                )}
                                            </FieldArray>
                                        </div>
                                    </div>

                                    <div className="card-footer text-end">
                                        {rolePermissions.write === 'yes' && (
                                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                                {loading ? 'Saving...' : isDataExists ? 'Update' : 'Add'}
                                            </button>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
