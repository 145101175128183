import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import AsideMenu from './AsideMenu';
import { RoutesAll } from '../routes';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import VerifyToken from '../Components/Token/VerifyToken';
import TokenExpiredPopup from '../Components/Token/TokenExpiredPopup';
import "../App.css";
import CryptoJS from 'crypto-js';
import { CRYPTOJS_ENCRYPTION_KEY } from '../Constant/Constant';

const BaseLayout = () => {
    const navigate = useNavigate();
    const [showTokenExpiredPopup, setShowTokenExpiredPopup] = useState(false);

    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken || null; // Return null if decryptedToken is falsy
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };

    useEffect(() => {
        const encryptedToken = localStorage.getItem('BackendToken');
        const token = encryptedToken ? decryptToken(encryptedToken, CRYPTOJS_ENCRYPTION_KEY) : null;

        if (!token) {
            console.warn('No valid token found. Redirecting to login.');
            navigate('/login'); // Redirect to login if token is not found
        }
        
    }, []); // Empty dependency array to run only on mount

    return (
        <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
            <div className="layout-container">
                <Header />
                <AsideMenu />
                <div className="layout-page">
                    <div className="content-wrapper">
                        <Routes>
                            {RoutesAll().map((route, index) => (
                                route.condition === undefined ? (
                                    <Route key={index} path={route.path} element={<route.component />} />
                                ) : (
                                    route.condition ? (
                                        <Route key={index} path={route.path} element={<route.component />} />
                                    ) : (
                                        <Route key={index} path={route.path} element={<Navigate to="/not-authorized" />} />
                                    )
                                )
                            ))}
                        </Routes>

                        {/* Token expiration popup and verification */}
                        {showTokenExpiredPopup && <TokenExpiredPopup />}
                        <VerifyToken onTokenInvalid={() => setShowTokenExpiredPopup(true)} />
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default BaseLayout;
