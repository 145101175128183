import React from 'react';
import { Link } from 'react-router-dom';

const TableFooter = ({ totalRecords, currentPage, recordsPerPage, onPageChange }) => {
    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    const pageNumbers = [...Array(totalPages).keys()].map(i => i + 1);
    
    const getPageNumbers = () => {
        if (totalPages <= 6) {
            // If total pages are 6 or fewer, display all page numbers
            return pageNumbers;
        }

        const firstThree = [1, 2, 3];
        const lastThree = [totalPages, totalPages - 1, totalPages - 2]; // Reverse order for last three

        if (currentPage <= 3) {
            return [...firstThree, '...', ...lastThree];
        } else if (currentPage >= totalPages - 2) {
            return [...firstThree, '...', ...lastThree];
        } else {
            return [...firstThree, '...', currentPage - 1, currentPage, currentPage + 1, '...', ...lastThree];
        }
    };

    const pageDisplay = getPageNumbers();

    // Calculate the range of records being displayed
    const startRecord = (currentPage - 1) * recordsPerPage + 1;
    const endRecord = Math.min(currentPage * recordsPerPage, totalRecords); // Ensure the last page ends at totalRecords

    return (
        <div className="row mt-3">
            <div className="col-sm-12 col-md-6 justify-content-between">
                <div className="dataTables_info" role="status" aria-live="polite">
                    Records: {startRecord} to {endRecord} of {totalRecords}
                </div>
            </div>
            <div className="col-sm-12 col-md-6">
                <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-end">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <Link className="page-link waves-effect" to="#" onClick={() => onPageChange(currentPage - 1)}>
                                <i className="ti ti-chevrons-left ti-xs"></i>
                            </Link>
                        </li>
                        {pageDisplay.map((number, index) => (
                            <li key={index} className={`page-item ${currentPage === number ? 'active' : ''} ${typeof number === 'string' ? 'disabled' : ''}`}>
                                {typeof number === 'string' ? (
                                    <span className="page-link">{number}</span>
                                ) : (
                                    <Link className="page-link waves-effect" to="#" onClick={() => onPageChange(number)}>
                                        {number}
                                    </Link>
                                )}
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <Link className="page-link waves-effect" to="#" onClick={() => onPageChange(currentPage + 1)}>
                                <i className="ti ti-chevrons-right ti-xs"></i>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default TableFooter;
