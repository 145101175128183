// reducers/index.js
import { combineReducers } from 'redux';
import authReducer from '../Slice/authSlice';
import rolesReducer from '../Slice/roleSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  roles: rolesReducer,
  // Add other reducers here
});

export default rootReducer;
