import React from 'react';

const Button = ({ type, label, icon, className, onClick }) => {
  return (
    <button type={type} className={`btn ${className} waves-effect mt-4`} onClick={onClick}>
      {icon && <i className={`fa ${icon} me-2`}></i>}
      {label}
    </button>
  );
};

export default Button;
