
import Language from './Language'
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, CRYPTOJS_ENCRYPTION_KEY } from '../Constant/Constant'
import { useTokenData } from '../utils/decodeToken';
import Notification from './Notification';
import CryptoJS from 'crypto-js';


const Header = () => {
    const [newNotificationCount, setNewNotificationCount] = useState(0);
 
    const { userId } = useTokenData();
    const [userData, setUserData] = useState({
        image: '',
        first_name: '',
        role: ''
    });
    const [token, setToken] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
    }, []);
    const fetchUserData = async () => {
        try {
          

            const url = `${BASE_URL}user/get/${userId}`;
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setUserData({
                image: response.data.image,
                first_name: response.data.first_name,
                role: response.data.role
            });
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };
    useEffect(() => {
        if (token ) {
            fetchUserData();
        }
    }, [token]);
  
    return (
        <>
            <nav className="layout-navbar navbar navbar-expand-xl align-items-center navbar-dark bg-dark" id="layout-navbar">
                <div className="container-xxl">
                    <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none d-none">
                        <Link className="nav-item nav-link px-0 me-xl-4" to=" #">
                            <i className="ti ti-menu-2 ti-sm"></i>
                        </Link>
                    </div>
                    <div className="navbar-brand app-brand demo d-xl-flex py-0 me-4 d-none d-lg-flex">
                        <Link className="d-flex" href="/">
                            <img
                                alt="Aargauertaxi"
                                src="../assets/img/template/logo.svg"
                                style={{ height: "24px", width: "210px", marginLeft: '-30px' }}
                            />
                        </Link>

                        <Link to="#" className="layout-menu-toggle menu-link text-large ms-auto d-xl-none">
                            <i className="ti ti-x ti-md align-middle"></i>
                        </Link>
                    </div>

                    <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                        <Link className="nav-item nav-link px-0 me-xl-4" to="#">
                            <i className="ti ti-menu-2 ti-md"></i>
                        </Link>
                    </div>

                    <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                        <ul className="navbar-nav flex-row align-items-center ms-auto">
                            {userData.role == 'SuperAdmin' &&
                            <li className="nav-item dropdown-language  navbar-dropdown dropdown me-3 me-xl-2 ">
                                <Language />
                            </li>
                            }
                            <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
                                <Link className="nav-link dropdown-toggle hide-arrow" href="#;" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                    <i className="ti ti-bell ti-md"></i>
                                    {newNotificationCount > 0 && (
                                        <span className="badge bg-danger rounded-pill badge-notifications">{newNotificationCount}</span>
                                    )}
                                </Link>
                                <ul className="dropdown-menu dropdown-menu-end py-0">
                                    <li className="dropdown-menu-header border-bottom">
                                        <div className="dropdown-header d-flex align-items-center py-3">
                                            <h5 className="text-body mb-0 me-auto">Notification</h5>
                                            <Link href="#" className="dropdown-notifications-all text-body" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Mark all as read" data-bs-original-title="Mark all as read">
                                                <i className="ti ti-mail-opened fs-4"></i>
                                            </Link>
                                        </div>
                                    </li>
                                    {/* Pass the setNewNotificationCount function to Notification component */}
                                    <Notification setNewNotificationCount={setNewNotificationCount} />
                                    <li className="dropdown-menu-footer border-top">
                                        <Link to="../bookinglist" className="dropdown-item d-flex justify-content-center text-primary p-2 h-px-40 mb-1 align-items-center">
                                            View all booking
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                          
                           

                    <li className="nav-item navbar-dropdown dropdown-user dropdown mx-2">
                        <Link
                            className="nav-link dropdown-toggle hide-arrow p-0"
                            to="#"
                            data-bs-toggle="dropdown">
                            <div className="avatar avatar-online">
                                <img
                                    src={`${BASE_URL}uploads/${userData.image}`}
                                    alt="avatar"
                                    className="rounded-circle "
                                />
                            </div>
                        </Link>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <Link className="dropdown-item mt-0" to="#">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <div className="avatar avatar-online">
                                                <img
                                                    src={`${BASE_URL}uploads/${userData.image}`}
                                                    alt="avatar"
                                                    className="rounded-circle"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 className="mb-0">{userData.first_name || "User"}</h6>
                                            <small className="text-muted">{userData.role || "Role"}</small>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <div className="dropdown-divider my-1 mx-n2"></div>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="../userProfile">
                                    <i className="ti ti-user me-3 ti-md"></i><span className="align-middle">My Profile</span>
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="../changePassword">
                                    <i className="ti ti-settings me-3 ti-md"></i><span className="align-middle">Change Password</span>
                                </Link>
                            </li>

                            <li>
                                <div className="dropdown-divider my-1 mx-n2"></div>
                            </li>

                            <li>
                                <div className="d-grid px-2 pt-2 pb-1">
                                    <Link className="btn btn-sm btn-danger d-flex" to="../logout" >
                                        <small className="align-middle">Logout</small>
                                        <i className="ti ti-logout ms-2 ti-14px"></i>
                                    </Link>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className="navbar-search-wrapper search-input-wrapper container-xxl d-none">
                <input
                    type="text"
                    className="form-control search-input border-0"
                    placeholder="Search..."
                    aria-label="Search..." />
                <i className="ti ti-x search-toggler cursor-pointer"></i>
            </div>
        </div >
            </nav >

        </>

    )
}

export default Header
