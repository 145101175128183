import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BASE_URL, BACKENDTOKEN } from '../../../Constant/Constant';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Actionwelcome = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();

  const [description, setDescription] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const permissions = useSelector(state => state.roles.permissions.user_modules);
  const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });

  // Formik initialization
  const formik = useFormik({
    initialValues: {
      title: ''
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Title is required')
        .min(3, 'Title must be at least 3 characters'),
    }),
    onSubmit: (values) => {
      handleUpdate(values);
    }
  });

  useEffect(() => {
    if (permissions) {
      try {
        const modules = JSON.parse(permissions);
        const welcome = modules.find(module => module.rolename === 'welcome');
        if (welcome) {
          setRolePermissions(welcome.permissions);
        }
      } catch (error) {
        console.error('Failed to parse permissions:', error);
      }
    }
  }, [permissions]);

  useEffect(() => {
    axios.get(`${BASE_URL}welcome/get/${id}`, {
      headers: {
        'Authorization': `Bearer ${BACKENDTOKEN}`
      }
    })
      .then(response => {
        formik.setFieldValue('title', response.data.title); // Set formik title field
        setDescription(response.data.description);
      })
      .catch(error => {
        console.error('Error fetching welcomemessage:', error);
      });
  }, [id]);

  useEffect(() => {
    // Set editable state based on action
    setIsEditable(action === 'edit');
  }, [action]);

  const handleUpdate = (values) => {
    if (rolePermissions.write === 'no') {
      toast.dismiss();
      toast.error('You do not have permission to update data.', {
        autoClose: 1500,
      });
      return;
    }

    axios.put(`${BASE_URL}welcome/update/${id}`, { title: values.title, description }, {
      headers: {
        'Authorization': `Bearer ${BACKENDTOKEN}`
      }
    })
      .then(response => {
        toast.dismiss();
        toast.success('Welcome message updated successfully', {
          autoClose: 1500,
        });
        setTimeout(() => {
          navigate(`/actionwelcome/${id}?action=view`);
        });
      })
      .catch(error => {
        toast.dismiss();
        toast.error('Failed to update welcome message', {
          autoClose: 1500,
        });
      });
  };

  const handleEdit = () => {
    navigate(`/actionwelcome/${id}?action=edit`);
  };

  const handleCancel = () => {
    navigate(`/actionwelcome/${id}?action=view`);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  return (
    <div className="container-xl my-4">
      <ToastContainer />
      <div className="row">
        <div className="col-md-6">
          <h4>Welcome Message</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="/welcomemessage" className='fs-6'>Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-title">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label className='form-label'>Title<span className='text-danger px-1 fs-5'>*</span></label>
                <input
                  type="text"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!isEditable}
                  className="form-control mb-2"
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="text-danger">{formik.errors.title}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                {isEditable ? (
                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={handleDescriptionChange}
                    modules={{
                      toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ 'size': [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link', 'image'],
                        ['clean']
                      ]
                    }}
                    style={{ height: '200px', borderRadius: '10px' }}
                    className="react-quill-editor mb-3"
                  />
                ) : (
                  <div
                    className="form-control mb-2"
                    dangerouslySetInnerHTML={{ __html: description }}
                    style={{ minHeight: '100px', backgroundColor: "#f4f4f4" }}
                  />
                )}
              </div>
              <div className='card-footer mt-5'>
                <div className="d-flex justify-content-end align-items-center">
                  {action === 'view' && rolePermissions.write === 'yes' && (
                    <button type="button" onClick={handleEdit} className="btn btn-primary mb-2">Edit</button>
                  )}

                  {isEditable && (
                    <button type="submit" className="btn btn-primary mx-3">Update</button>
                  )}

                  {action === 'edit' && (
                    <button type="button" onClick={handleCancel} className=' btn btn-label-secondary'>Cancel</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Actionwelcome;
