import React from 'react';

const InputField = ({ label, id, placeholder, required ,setFilterTitle,filterTitle}) => {
  return (
    <div className="col-md-3">
      <label className="form-label" htmlFor={id}>{label}</label>
      <input type="text"
       className="form-control" 
       id={id} 
       value={filterTitle}
       onChange={(e) => setFilterTitle(e.target.value)}
       placeholder={placeholder} 
       required={required} />
    </div>
  );
};

export default InputField;
