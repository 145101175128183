import React, { useState, useEffect, useRef } from 'react';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { MdOutlineCancel } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'; // Import toast
import { IoAddCircleOutline } from "react-icons/io5";
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import {BASE_URL,BACKENDTOKEN,SELECTEDLANGUAGEID} from '../../../Constant/Constant'

const AddAbout = () => {
    const fileInputRef = useRef(null); // Create a ref for the file input
const setLanguageId = `${SELECTEDLANGUAGEID}`
    const initialValues = {
        title: '',
        image: null,
        description: '',
        points: [{ id: 1, text: '' }],
        language_id:setLanguageId  ,
    };
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required'),
        image: Yup.mixed().nullable().test('fileType', 'Unsupported file type. Only JPEG, PNG, and GIF are allowed.', value => {
            // Check if the file is an image
            return !value || (value && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type));
        }),
        description: Yup.string().required('Description is required'),
        points: Yup.array()
            .of(
                Yup.object({
                    text: Yup.string().required('Point is required')
                })
            )
            .min(1, 'At least one point is required')
            .max(6, 'No more than 6 points are allowed'),
    });


    const handleSubmit = async (values, { resetForm, setFieldValue }) => {
       

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('title', values.title);

            // Check if the image is properly set and append it
            if (values.image) {
                formDataToSend.append('image', values.image);
            } else {
                console.error('No image file found in Formik values');
            }

            formDataToSend.append('description', values.description);
            formDataToSend.append('points', JSON.stringify(values.points.map(point => point.text)));
            formDataToSend.append('language_id', values.language_id);

            const response = await axios.post(`${BASE_URL}about/add/`, formDataToSend, {
                headers: {
                    'Authorization': `Bearer ${BACKENDTOKEN}`,
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                },
            });

            // Assuming backend sends a response with a 'message' field
            const { message } = response.data;
            resetForm();
            setFieldValue('image', null); // Clear the image field manually
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Clear the file input field using the ref
            }
            toast.dismiss();
            toast.success(message || 'About Us entry added successfully!', {
                autoClose: 1500,             
});
        } catch (error) {
            if (error.response) {
                console.error('Server responded with an error status:', error.response.status);
                console.error('Error response data:', error.response.data);
                const errorMessage = error.response.data.message || 'Failed to add About Us entry. Please try again.';
                toast.dismiss();
                toast.error(errorMessage, {
                    autoClose: 1500,             
    });
            } else if (error.request) {
                console.error('No response received from server:', error.request);
                toast.dismiss();
                toast.error('Failed to receive a response from server. Please try again.', {
                    autoClose: 1500,             
    });
            } else {
                console.error('Error setting up the request:', error.message);
                toast.dismiss();
                toast.error('An error occurred while setting up the request. Please try again.', {
                    autoClose: 1500,             
    });
            }
            console.error('Axios error config:', error.config);
        }
    };

    useEffect(() => {
        initialValues.language_id = `${SELECTEDLANGUAGEID}` || 1;
    }, []);

    return (
        <div className="container-xl my-4">
            <ToastContainer/>
            <div className="row">
                <div className="col-md-6">
                    <h4>AboutUs</h4>
                </div>
                <div className="col-md-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end fs-3">
                            <li className="breadcrumb-item">
                                <Link to="../aboutus" className='fs-6'>Back</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="#" className='fs-6'>Add</Link>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row row-cards">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header header-underline pb-1">
                            <h4 className="card-title">Add</h4>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ setFieldValue, values }) => (
                                    <Form className="row g-3">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Title<span className='text-danger px-1 fs-5'>*</span></label>
                                                <Field className='form-control' type="text" name="title" />
                                                <ErrorMessage name="title" component="div" className="text-danger" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Points</label>
                                                <FieldArray name="points">
                                                    {({ push, remove }) => (
                                                        <>
                                                            {values.points.map((point, index) => (
                                                                <div key={index} className="input-group mb-3">
                                                                    <Field
                                                                        className='form-control'
                                                                        type="text"
                                                                        name={`points.${index}.text`}
                                                                    />
                                                                    {index !== 0 && ( // Show delete button for all fields except the first one
                                                                        <button
                                                                            className="btn btn-label-secondary waves-effect waves-light"
                                                                            type="button"
                                                                            onClick={() => remove(index)}
                                                                            disabled={values.points.length <= 1} // Prevent removal if only one point is left
                                                                        >
                                                                            <MdOutlineCancel />
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            ))}
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() => {
                                                                    if (values.points.length < 6) { // Limit to 6 points
                                                                        push({ id: values.points.length + 1, text: '' });
                                                                    } else {
                                                                        toast.dismiss();
                                                                        toast.warn('Maximum of 6 points allowed', {
                                                                            autoClose: 1500,             
                                                            }); // Toast warning
                                                                    }
                                                                }}
                                                            >
                                                                <IoAddCircleOutline style={{ fontSize: "16px" }} />
                                                            </button>
                                                        </>
                                                    )}
                                                </FieldArray>

                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Image</label>
                                                <input
                                                    className='form-control'
                                                    type="file"
                                                    accept="image/jpeg, image/png, image/gif"
                                                    name="image"
                                                    ref={fileInputRef} // Attach the ref to the file input
                                                    onChange={(e) => {
                                                        const file = e.currentTarget.files[0];
                                                        setFieldValue('image', file);
                                                    }}
                                                />
                                                <ErrorMessage name="image" component="div" className="text-danger" />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label">Description</label>
                                                <ReactQuill
                                              
                                                    value={values.description}
                                                    onChange={(content) => setFieldValue('description', content)}
                                                    className="ql-container"
                                                 
                                                />
                                               
                                            </div>
                                        </div>

                                        <div className="col-12 d-flex justify-content-end align-items-center mt-5">
                                            <button type="submit" className="btn btn-primary">Add</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAbout;
