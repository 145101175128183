
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {BASE_URL,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2 } from '../../../../Constant/Constant';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';


const PasswordPage = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        PasswordTitle: '',
        PasswordButtonTitle: '',
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [id, setId] = useState(null); // Initialize with null
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [isBookingBoxData, setIsBookingBoxData] = useState(false);
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);
  
    useEffect(() => {
      if (permissions) {
        try {
          const modules = JSON.parse(permissions);
          const legalcontent = modules.find(module => module.rolename === 'staticcontent');
          if (legalcontent) {
            setRolePermissions(legalcontent.permissions);
            setIsBookingBoxData(legalcontent.permissions.write === 'no');
           
          }
        } catch (error) {
          console.error('Failed to parse permissions:', error);
        }
      }
    }, [permissions]);

 
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url,{
                    headers:{
                        'Authorization': `Bearer ${token}`
                    }
                });
            
                setId(response.data.id);
                if (response.data && response.data.passwordpage) {
                    const parsedData = JSON.parse(response.data.passwordpage);
                    setFormValues(parsedData);
                    setIsDataExists(true);
                    setIsEditMode(false); // Data exists, so not in edit mode


                } else {

                    setFormValues({
                        PasswordTitle: '',
                        PasswordButtonTitle: '',
                    });
                    setIsDataExists(false);
                    setIsEditMode(true); // No data, so allow adding
                }
            } catch (error) {
                setIsEditMode(true); // Error fetching data, possibly allow editing
            }
        };

    useEffect(() => {
        if (token && languageId) {
            fetchData();
        }
    }, [token, languageId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                passwordpage: formValues,
                language_id: languageId
            };

            const url = `${BASE_URL}staticcontent/passwordpage`;

            const response = await axios.post(url, payload,{
                headers:{
                    'Authorization': `Bearer ${token}`
                }
            });
            

            // Check if response data is in the expected format
            if (response.data && response.data.message === 'passwordpage Process successfully!') {
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);

            } else {
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                {/* First Row */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Password Title</label>
                        <input
                            name="PasswordTitle"
                            type="text"
                            className="form-control"
                            value={formValues.PasswordTitle}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                {/* <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Password Slug</label>
                        <input
                            name="PasswordSlug"
                            type="text"
                            className="form-control"
                            value={formValues.PasswordSlug}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div> */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">PasswordButton Title</label>
                        <input
                            name="PasswordButtonTitle"
                            type="text"
                            className="form-control"
                            value={formValues.PasswordButtonTitle}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>

                {/* Second Row */}
                {/* <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">ForgotPassword Tilte</label>
                        <input
                            name="ForgotPasswordTilte"
                            type="text"
                            className="form-control"
                            value={formValues.ForgotPasswordTilte}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">DonotHaveAccount Title</label>
                        <input
                            name="DonotHaveAccountTitle"
                            type="text"
                            className="form-control"
                            value={formValues.DonotHaveAccountTitle}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">DonotHave AccountLink</label>
                        <input
                            name="DonotHaveAccountLink"
                            type="text"
                            className="form-control"
                            value={formValues.DonotHaveAccountLink}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div> */}

                <div className="card-footer text-end">
                {rolePermissions.write === 'yes' &&
                    <button
                        type="submit"
                        className="btn btn-primary"
                      disabled={loading ||isBookingBoxData}
                    >
                        {loading ? 'Saving...' : (isDataExists ? 'Update' : 'Add')}
                    </button>
}
                </div>

            </form>
        </>
    );
};

export default PasswordPage;

