import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { CRYPTOJS_ENCRYPTION_KEY,BASE_URL } from '../../Constant/Constant';

const VerifyToken = ({ onTokenInvalid }) => {
  const [token, setToken] = useState(null);  // Store the token in state
  const [userId, setUserId] = useState(null); // Store the userId in state

  // Function to decrypt tokens
  const decryptToken = (encryptedToken, key) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
      const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedToken ? decryptedToken : null;
    } catch (error) {
      console.error('Error decrypting token:', error);
      return null;
    }
  };

  const clearAuthToken = () => {
    localStorage.removeItem('BackendToken');
    localStorage.removeItem('userId');
  };

  const checkTokenExpiry = async () => {
    if (!token) {
      console.log('No token found, triggering token invalidation.');
      onTokenInvalid();
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}user/verify-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token,
          id: userId, // Pass the id if needed
        }),
      });

      if (!response.ok) {
        console.error(`HTTP error! Status: ${response.status}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      

      if (!data.valid) {
        if (data.message === 'JWT expired') {
          console.log('Token expired, clearing token and triggering invalidation.');
        } else {
          console.log('Token invalid, clearing token and triggering invalidation.');
        }
        clearAuthToken(); // Clear the token from localStorage
        onTokenInvalid(); // Handle token invalidation (e.g., redirect to login)
      }
    } catch (error) {
      console.error('Token verification error:', error);
      clearAuthToken();
      onTokenInvalid();
    }
  };

  useEffect(() => {
    const encryptedLanguage = localStorage.getItem('BackendToken');
    if (encryptedLanguage) {
      const decryptedToken = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY);
      setToken(decryptedToken);  // Set the decrypted token
    } else {
      console.warn('No selectedLanguageId found in localStorage.');
    }

    const storedUserId = localStorage.getItem('userId');
    setUserId(storedUserId);  // Set the userId
  }, []);

  useEffect(() => {
    if (token && userId) {
      checkTokenExpiry(); // Run token verification after the token and userId are set
    }
  }, [token, userId]);

  return null;
};

export default VerifyToken;
