import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { BASE_URL, BACKENDTOKEN,SELECTEDLANGUAGEID } from '../../../../Constant/Constant';
import { toast } from 'react-toastify';

const TourismIndex = () => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    TourismTitle: '',
    Description: ''
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDataExists, setIsDataExists] = useState(false);
  const [languageId, setLanguageId] = useState(`${SELECTEDLANGUAGEID}`);
  const [id, setId] = useState(null); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${BACKENDTOKEN}`
          }
        });

        // Set the ID from the fetched data
        if (response.data && response.data.id) {
          setId(response.data.id);
        }

        if (response.data && response.data.tourismindex) {
          const parsedData = JSON.parse(response.data.tourismindex);
          setFormValues(parsedData);
          setIsDataExists(true);
          setIsEditMode(false); // Data exists, so not in edit mode
        } else {
          setFormValues({
            TourismTitle: '',
            Description: ''
          });
          setIsDataExists(false);
          setIsEditMode(true); // No data, so allow adding
        }
      } catch (error) {
        setIsEditMode(true); 
      }
    };

    fetchData();
  }, [languageId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleDescriptionChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      Description: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        tourismindex: JSON.stringify(formValues),
        language_id: languageId
      };

      const url = `${BASE_URL}staticcontent/tourismindex`;

      const response = await axios.post(url, payload, {
        headers: {
          'Authorization': `Bearer ${BACKENDTOKEN}`
        }
      });

      if (response.data.message === 'tourismindex Process successfully!') {
        setIsDataExists(true);
        toast.success('Data processed successfully!', {
          autoClose: 1500
        });
      } else {
        toast.error('Unexpected server response.', {
          autoClose: 1500
        });
      }

      setIsEditMode(false); // Disable editing after saving
    } catch (error) {
      toast.error('Error saving data.', {
        autoClose: 1500
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">Tourism Title</label>
            <input
              name="TourismTitle"
              type="text"
              className="form-control"
              value={formValues.TourismTitle}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">Tourism Description</label>
            <ReactQuill
              theme="snow"
              value={formValues.Description}
              onChange={handleDescriptionChange}
              modules={{
                toolbar: [
                  [{ header: '1' }, { header: '2' }, { font: [] }],
                  [{ size: [] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  ['link', 'image'],
                  ['clean']
                ]
              }}
              style={{ height: '130px', borderRadius: '10px' }}
              className="react-quill-editor mb-3"
            />
          </div>
        </div>

        <div className="card-footer text-end mt-5">
          <button
            type="submit"
            className="btn btn-primary"
          >
            {loading ? 'Saving...' : isDataExists ? 'Update' : 'Add'}
          </button>
        </div>
      </form>
    </>
  );
};

export default TourismIndex;
