import React from 'react';

const ActiveButton = ({ onActivate, onDeactivate }) => {
  return (
    <div className="row mt-1">
      <div className=" col-12 ">
        <button 
          type="button" 
          className="btn btn-primary waves-effect mt-4" 
          onClick={onActivate}
        >
          Set As Active
        </button>
        <button 
          type="button" 
          className="btn btn-danger waves-effect waves-light mt-4  mx-2" 
          style={{ marginLeft: "5px" }} 
          onClick={onDeactivate}
        >
          Set As Inactive
        </button>
      </div>
    </div>
  );
};

export default ActiveButton;
