
import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ items }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-end">
        {items.map((item, index) => (
          <li className="breadcrumb-item" key={index}>
            <Link className="footer-link fs-6" to={item.link}>
              {item.label}
            </Link>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
