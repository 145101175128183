import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { BASE_URL, BACKENDTOKEN, CRYPTOJS_ENCRYPTION_KEY_2 } from '../Constant/Constant';

const Language = () => {
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(''); // Initialize as empty

    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const response = await fetch(`${BASE_URL}api/language`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setLanguages(result); // Set fetched languages

                // Find and set the default language
                const defaultLang = result.find(lang => lang.language_default === 1);
                if (defaultLang) {
                    setSelectedLanguage(defaultLang.language_code); // Set default language code

                    // Encrypt and store the default language ID
                    const encryptedLangId = CryptoJS.AES.encrypt(defaultLang.language_id.toString(), CRYPTOJS_ENCRYPTION_KEY_2).toString();
                    localStorage.setItem('selectedLanguageId', encryptedLangId);
                    
                }
            } catch (error) {
                console.error('Error fetching languages:', error);
            }
        };

        fetchLanguages();
    }, []);

    const handleLanguageChange = (e) => {
        const newLanguage = e.target.value;
        setSelectedLanguage(newLanguage);
        const selectedLang = languages.find(lang => lang.language_code === newLanguage);
        
        if (selectedLang) {
            const languageId = selectedLang.language_id;

            // Encrypt and store the selected language ID in localStorage
            const encryptedLangId = CryptoJS.AES.encrypt(languageId.toString(), CRYPTOJS_ENCRYPTION_KEY_2).toString();
            localStorage.setItem('selectedLanguageId', encryptedLangId);

            updateDefaultLanguage(languageId);
        }
    };

    const updateDefaultLanguage = async (languageId) => {
        try {
            await resetDefaultLanguages();
            const response = await fetch(`${BASE_URL}api/language/${languageId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ language_default: 1 }), // Set this language as default
            });
            if (!response.ok) {
                throw new Error(`Failed to update language ${languageId}`);
            }
            window.location.reload(); // Reload the whole page
        } catch (error) {
            console.error('Error updating default language:', error);
        }
    };

    const resetDefaultLanguages = async () => {
        try {
            const response = await fetch(`${BASE_URL}api/language/reset-default`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            });
            if (!response.ok) {
                throw new Error('Failed to reset default languages');
            }
        } catch (error) {
            console.error('Error resetting default languages:', error);
        }
    };

    return (
        <div>
            <li className="nav-item dropdown-language navbar-dropdown dropdown me-3 me-xl-2">
                <select
                    id="language-select"
                    className="form-select nav-link btn btn-text-secondary bg-dark rounded dropdown-toggle hide-arrow"
                    onChange={handleLanguageChange}
                    value={selectedLanguage}
                >
                    {languages.map((lang) => (
                        <option key={lang.language_id} value={lang.language_code}>
                            {lang.language_name}
                        </option>
                    ))}
                </select>
            </li>
        </div>
    );
};

export default Language;
