import React, { useState ,useEffect} from 'react';
import { Formik, Form, Field } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, BACKENDTOKEN ,SELECTEDLANGUAGEID,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2 
} from '../../../Constant/Constant'
import CryptoJS from 'crypto-js';
// Validation Schema
const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
});

// ModalFAQ Component
const ModalFAQ = ({ refreshData }) => { // Add refreshData prop
    const [modalOpen, setModalOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const initialValues = { title: '', description: '' };
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
        try {
            // Retrieve language_id from localStorage
            const language_id = `${languageId}` // Adjust key as per your implementation
    
            // Prepare the data to be sent
            const dataToSend = {
                ...values,
                description: description || '', // Ensure description is not null
                language_id: language_id // Include language_id in the request
            };
    
            const response = await fetch(`${BASE_URL}faq/add`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            });
    
            const data = await response.json(); // Parse the response as JSON
    
            // Check if the request was successful
            if (response.ok) {
                toast.dismiss();
                toast.success(data.message || "Successfully added FAQ", {
                    autoClose: 1500,             
                });
                resetForm();
                setDescription(''); // Reset Description after submission
                setModalOpen(false); // Close modal after submission
    
                if (refreshData) {
                    refreshData(); // Call the refreshData callback
                }
            } else {
                // Show error message from backend or fallback to a generic error
                toast.dismiss();
                toast.error(data.message || 'Error adding FAQ', {
                    autoClose: 1500,             
                });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.dismiss();
            toast.error('Failed to submit: ' + error.message, {
                autoClose: 1500,             
            });
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
            <Link type="button" className="btn btn-primary waves-effect waves-light" onClick={() => setModalOpen(true)}>
           
                <i className="fa fa-plus me-2"></i>Add
            </Link>

            {modalOpen && (
                <>
             
                    <div className={`modal fade show`} style={{ display: 'block' }} tabIndex="-1" aria-hidden={!modalOpen}>
                   
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content" >
                                <div className="modal-header">
                                    <h5 className="modal-title">Add</h5>
                                    <button type="button" className="btn-close" onClick={() => setModalOpen(false)} aria-label="Close"></button>
                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {formik => (
                                        <Form>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <label htmlFor="title" className="form-label">Title<span className='text-danger px-1 fs-5'>*</span></label>
                                                        <Field
                                                            id="title"
                                                            name="title"
                                                            type="text"
                                                            className={`form-control ${formik.errors.title && formik.touched.title ? 'is-invalid' : ''}`}
                                                            
                                                        />
                                                        {formik.errors.title && formik.touched.title && (
                                                            <div className="invalid-feedback">{formik.errors.title}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col">
                                                        <label htmlFor="description" className="form-label">Description</label>
                                                        <ReactQuill
                                                            theme="snow"
                                                            value={description}
                                                            onChange={setDescription}
                                                            modules={{
                                                                toolbar: [
                                                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                                    [{ 'size': [] }],
                                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                    ['link', 'image'],
                                                                    ['clean'],
                                                                ]
                                                            }}
                                                         
                                                            className="react-quill-editor mb-3"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                               
                                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                                    {loading ? 'Adding...' : 'Add'}
                                                </button>
                                                <button type="button" className="btn btn-label-secondary" onClick={() => setModalOpen(false)}>
                                                    Close
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
                </>
            )}
        </>
    );
};

export default ModalFAQ;
