
import 'react-toastify/dist/ReactToastify.css';
import BaseLayout from "./Layout/BaseLayout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Views/Login";
import ForgotPassword from "./Views/ForgotPassword";
import ResetPassword from "./Views/ResetPassword";
import "./App.css";

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:token" element={<ResetPassword />} />
          <Route path="/*" element={<BaseLayout />}>
          </Route>
        </Routes>
      </BrowserRouter>
      
    </>
  );
}

export default App;
