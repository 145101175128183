import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import "../../App.css";
import { BASE_URL, CRYPTOJS_ENCRYPTION_KEY } from '../../Constant/Constant'
import CryptoJS from 'crypto-js';

const statusClasses = {
  complete: 'bg-primary text-light',
  cancel: 'bg-danger text-light',
  process: 'bg-warning text-dark'
};
const Actionbooking = () => {
  const { id } = useParams();

  const [booking, setbooking] = useState({});
  const [currencyCode, setCurrencyCode] = useState('');
  const [extraOptions, setExtraOptions] = useState({
    selectItems: [],
    quantityItems: []
  });
  const [token, setToken] = useState(null);

  // Function to decrypt tokens
  const decryptToken = (encryptedToken, key) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
      const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedToken ? decryptedToken : null;
    } catch (error) {
      console.error('Error decrypting token:', error);
      return null;
    }
  };

  // Fetch token from localStorage and decrypt it
  const fetchBackendToken = async () => {
    const encryptedBackendToken = localStorage.getItem('BackendToken');
    if (encryptedBackendToken) {
      const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
      setToken(decryptedToken); // Set token to state
    } else {
      console.warn('No BackendToken found in localStorage.');
    }
  };

  useEffect(() => {
    fetchBackendToken(); // Fetch and set token
  }, []);


  useEffect(() => {
    const fetchTime = async () => {
      try {
        const res = await fetch(`${BASE_URL}api/central_setting_time?_t=` + new Date().getTime());

        if (!res.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await res.json();
        setCurrencyCode(data[0]?.code || '');
      } catch (error) {
        console.error('Error fetching time data:', error);
      }
    };

    fetchTime();
  }, []);
  const fetchLegalContents = async () => {

    axios.get(`${BASE_URL}booking/get/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => {
        const data = response.data;

        // Update booking state
        setbooking(data);

        // Parse extra options
        let parsedOptions = { selectItems: [], quantityItems: [] };
        try {
          // Decode the double-encoded JSON string
          const decodedExtraOption = JSON.parse(data.extra_option);
          parsedOptions = JSON.parse(decodedExtraOption);
        } catch (parseError) {
          console.error("Error parsing extra options:", parseError);
        }
        setExtraOptions(parsedOptions);
      })
      .catch(error => {
        console.error('Error fetching booking details:', error);
      });
  }
  useEffect(() => {
    if (token) {
      fetchLegalContents();
    }
  }, [token]);

  const getStatusClass = (status) => {
    return statusClasses[status] || 'bg-secondary text-light'; // Default class if status is unknown
  };
  const bookingDate = new Date(booking.booking_date);
const formattedDate = bookingDate.toLocaleDateString('en-GB'); // 'en-GB' for day-month-year format
  return (
    <div className="container-xl my-4">
      <div>
        <div className="row">
          <div className="col-md-6">
            <h4>Booking Details</h4>
          </div>
          <div className="col-md-6">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-end fs-3">
                <li className="breadcrumb-item"><Link to="/bookinglist" className='fs-6'>Back</Link></li>
                <li className="breadcrumb-item"><Link to="#" className='fs-6'>Action Page</Link></li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container-sub ">
          <div className="card ">
            <div className="card-body">
              <div className="my-2 mb-20">
                <h5>Booking Details</h5>
              </div>
              <div className="mt-5 row">
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body rounded">
                      <h6 className="mb-2">Order Summary</h6>
                      <div className="d-flex justify-content-between py-1">
                        <div>
                          Order Number
                        </div>
                        <div>
                          <p className="fw-bolder">#{booking.id}</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between py-1">
                        <div>
                          Booking Date
                        </div>
                        <div>
                          <p className="fw-bold">{formattedDate}</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between py-1">
                        <div>
                          Select Vehicle
                        </div>
                        <div>
                          <p className="fw-bold">{booking.car_name}</p>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body rounded">
                      <h6 className="mb-2">Booking Info</h6>
                      <div className="d-flex justify-content-between py-1">
                        <div>
                         Pickup Location
                        </div>
                        <div>
                          <p className="fw-bolder">{booking.from_location}</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between py-1">
                        <div>
                          Drop Location
                        </div>
                        <div>
                          <p className="fw-bold">{booking.to_location}</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between py-1">
                        <div>
                          Total Distance
                        </div>
                        <div>
                          <p className="fw-bold">{booking.total_distance}</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between py-1">
                        <div>
                          Total Duration
                        </div>
                        <div>
                          <p className="fw-bold">{booking.total_time}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body rounded">
                      <h6 className="mb-2">Passenger Info</h6>
                      <div className="d-flex justify-content-between py-1">
                        <div>
                          First Name
                        </div>
                        <div>
                          <p className="fw-bolder">{booking.name}</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between py-1">
                        <div>
                          Last Name
                        </div>
                        <div>
                          <p className="fw-bold">{booking.lastname}</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between py-1">
                        <div>
                          Email
                        </div>
                        <div>
                          <p className="fw-bold">{booking.email_address}</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between py-1">
                        <div>
                          Phone
                        </div>
                        <div>
                          <p className="fw-bold">+{booking.phone}</p>
                        </div>
                      </div>
                      
                      <div className="d-flex justify-content-between py-1">
                        <div>
                          City
                        </div>
                        <div>
                          <p className="fw-bold">{booking.city}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body rounded">
                      <h6 className="mb-2">Price Breakdown</h6>
                      <div className="d-flex flex-column gap-2 justify-content-between pt-1 pb-3">
                        <div>
                          <p className="fw-bolder">{booking.car_name}</p>
                        </div>
                        <div>
                          <p>{booking.date}-{booking.time}</p>
                        </div>
                      </div>
                      <div className="border-bottom border-top">
                        <div className="d-flex flex-column gap-2 justify-content-between py-3">
                          {/* <ul className="list-prices">
                            {extraOptions.selectItems.length > 0 && (
                              <>
                                <li><strong>Select Items:</strong></li>
                                {extraOptions.selectItems.map(item => (
                                  <li key={item.id}>
                                    <span className="text-top">{item.title}</span>
                                    <span className="text-bottom">{currencyCode} {item.price}</span>
                                  </li>
                                ))}
                              </>
                            )}
                            {extraOptions.quantityItems.length > 0 && (
                              <>
                                <li><strong>Quantity Items:</strong></li>
                                {extraOptions.quantityItems.map(item => (
                                  <li key={item.id}>
                                    <span className="text-top">{item.title}</span>
                                    <span className="text-bottom">
                                      {item.quantity} <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z" /></svg> {currencyCode}{item.totalPrice}
                                    </span>
                                  </li>
                                ))}
                              </>
                            )}
                          </ul> */}
                          <ul className="list-prices">
                            {(extraOptions?.selectItems?.length > 0 || extraOptions?.quantityItems?.length > 0) ? (
                              <>
                                {extraOptions?.selectItems?.length > 0 && (
                                  <>
                                    <li><strong>Select Items:</strong></li>
                                    {extraOptions.selectItems.map(item => (
                                      <li key={item.id}>
                                        <span className="text-top">{item.title}</span>
                                        <span className="text-bottom">{currencyCode} {item.price}</span>
                                      </li>
                                    ))}
                                  </>
                                )}

                                {extraOptions?.quantityItems?.length > 0 && (
                                  <>
                                    <li><strong>Quantity Items:</strong></li>
                                    {extraOptions.quantityItems.map(item => (
                                      <li key={item.id}>
                                        <span className="text-top">{item.title}</span>
                                        <span className="text-bottom">
                                          {item.quantity}
                                          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368">
                                            <path d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z" />
                                          </svg>
                                          {currencyCode}{item.totalPrice}
                                        </span>
                                      </li>
                                    ))}
                                  </>
                                )}
                              </>
                            ) : (
                              <li>No extra options selected</li>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-2 py-3 ">
                        <div className="d-flex gap-2 justify-content-between">
                          <div>
                            <p>Selected vehicle</p>
                          </div>
                          <div>
                            <p className="fw-bolder">{currencyCode}{booking.car_price}</p>
                          </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-between">
                          <div>
                            <p>Extra options</p>
                          </div>
                          <div>
                            <p className="fw-bolder">{currencyCode}{booking.extra_option_total}</p>
                          </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-between">
                          <div>
                            <h5>Grand Total</h5>
                          </div>
                          <div>
                            <h5 className="fw-bolder">
                              {currencyCode}{booking.total_price}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body rounded">
                      <h6 className="mb-2">Order Status</h6>
                      <div className="d-flex justify-content-between py-1">
                        <small
                          key={booking.id}
                          className={`d-inline-flex mb-3 px-2 py-1 fw-semibold rounded-2 ${getStatusClass(booking.status)}`}
                        >
                          {booking.status}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Actionbooking;
