import React from 'react';
import InputField from './InputField';
import SelectField from './SelectField';
import Button from './Button';

const FilterForm = ({metatags, searcherData, dataSourceOptions, statusOptions,filterTitle, setFilterTitle ,filterStatus,setFilterStatus, handlePrimaryClick, handleResetClick }) => {
  const { namePlaceholder} = searcherData;

  return (
    <div className="row mt-3">
      <InputField
        label="Filter By Name"
        id="filter-by-name"
        placeholder={namePlaceholder}
        filterTitle={filterTitle}
        setFilterTitle={setFilterTitle}
        required
      />
      {/* <SelectField
        label="Data Source"
        id="data-source"
        options={dataSourceOptions} // Pass dataSourceOptions here
        required
      /> */}
      {!metatags===true &&
      <SelectField
        label="Filter By Status"
        id="filter-by-status"
        options={statusOptions} // Pass statusOptions here
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        required
      />
}
      <div className="col-md-3">
        <Button type="button" label="Primary" icon="fa-search" className="btn-outline-primary" onClick={handlePrimaryClick} />
        <Button type="reset" label="Reset" className="btn-outline-dark mx-2" style={{ marginLeft: "5px" }}   onClick={handleResetClick}/>
      </div>
    </div>
  );
};

export default FilterForm;
