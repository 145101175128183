import CryptoJS from 'crypto-js';
// Constants
export const BASE_URL = "https://backend.aargauertaxi.ch/";
export const CRYPTOJS_ENCRYPTION_KEY = "13f3953e8050396082b3d05e9e798f8cbeaad8117d9604438f4965319ec02cc6"; 
export const CRYPTOJS_ENCRYPTION_KEY_2 = "6c6f61268b49e815aba06433606039d9d296ff3fb52b376c4641436ec164d954"; 
export const TABLE_RECORDSPERPAGE = 20;

// Function to decrypt tokens
const decryptToken = (encryptedToken, key) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
        const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedToken ? decryptedToken : null;
    } catch (error) {
        console.error('Error decrypting token:', error);
        return null;
    }
};

// Retrieve and decrypt BackendToken
let BACKENDTOKEN = null;
const encryptedBackendToken = localStorage.getItem('BackendToken');
if (encryptedBackendToken) {
    BACKENDTOKEN = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
} else {
    console.warn('No BackendToken found in localStorage.');
}

// Retrieve and decrypt selectedLanguageId
let SELECTEDLANGUAGEID = null;
const encryptedLanguage = localStorage.getItem('selectedLanguageId');
if (encryptedLanguage) {
    SELECTEDLANGUAGEID = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
} else {
    console.warn('No selectedLanguageId found in localStorage.');
}

// Export the decrypted tokens
export { BACKENDTOKEN, SELECTEDLANGUAGEID };
