import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2  } from '../../../../Constant/Constant';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
const ServiceIndex = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        ServiceTitle1: '',
        Description1: '',
        ServiceTitle2: '',
        Description2: '',
        ServiceTitle3: '',
        Description3: ''
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [id, setId] = useState(null);
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [isBookingBoxData, setIsBookingBoxData] = useState(false);

    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);
  
    useEffect(() => {
      if (permissions) {
        try {
          const modules = JSON.parse(permissions);
          const legalcontent = modules.find(module => module.rolename === 'staticcontent');
          if (legalcontent) {
            setRolePermissions(legalcontent.permissions);
            setIsBookingBoxData(legalcontent.permissions.write === 'no');
           
          }
        } catch (error) {
          console.error('Failed to parse permissions:', error);
        }
      }
    }, [permissions]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setId(response.data.id);
                if (response.data && response.data.serviceindex) {
                    const parsedData = JSON.parse(response.data.serviceindex);
                    setFormValues(parsedData);
                    setIsDataExists(true);
                    setIsEditMode(false);
                } else {
                    setFormValues({
                        ServiceTitle1: '',
                        Description1: '',
                        ServiceTitle2: '',
                        Description2: '',
                        ServiceTitle3: '',
                        Description3: ''
                    });
                    setIsDataExists(false);
                    setIsEditMode(true);
                }
            } catch (error) {
                setIsEditMode(true);
            }
        };

        fetchData();
    }, [languageId, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                serviceindex: formValues,
                language_id: languageId,
            };

            const url = `${BASE_URL}staticcontent/serviceindex`;
            const response = await axios.post(url, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data && response.data.message === 'serviceindex Process successfully!') {
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);
            } else {
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Service Title1</label>
                        <input
                            name="ServiceTitle1"
                            type="text"
                            className="form-control"
                            value={formValues.ServiceTitle1}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Service Description1</label>
                        <textarea
                            name="Description1"
                            className="form-control"
                            value={formValues.Description1}
                            onChange={handleChange}
                            rows="2"
                          disabled={loading ||isBookingBoxData}
                        ></textarea>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Service Title2</label>
                        <input
                            name="ServiceTitle2"
                            type="text"
                            className="form-control"
                            value={formValues.ServiceTitle2}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Service Description2</label>
                        <textarea
                            name="Description2"
                            className="form-control"
                            value={formValues.Description2}
                            onChange={handleChange}
                            rows="2"
                          disabled={loading ||isBookingBoxData}
                        ></textarea>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Service Title3</label>
                        <input
                            name="ServiceTitle3"
                            type="text"
                            className="form-control"
                            value={formValues.ServiceTitle3}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Service Description3</label>
                        <textarea
                            name="Description3"
                            className="form-control"
                            value={formValues.Description3}
                            onChange={handleChange}
                            rows="2"
                          disabled={loading ||isBookingBoxData}
                        ></textarea>
                    </div>
                </div>

                <div className="card-footer text-end mt-5">
                {rolePermissions.write === 'yes' && 
                    <button
                        type="submit"
                        className="btn btn-primary"
                      disabled={loading ||isBookingBoxData}
                    >
                        {loading ? 'Saving...' : isDataExists ? 'Update' : 'Save'}
                    </button>
}
                </div>
            </form>
        </>
    );
};

export default ServiceIndex;
