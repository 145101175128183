// lib/storage.js
import { persistStore, persistReducer } from 'redux-persist';

// Create a storage module
const createStorage = () => {
  if (typeof window !== 'undefined') {
    return require('redux-persist/lib/storage').default; // Use localStorage in browser
  }
  return {
    getItem: () => Promise.resolve(null),
    setItem: () => Promise.resolve(),
    removeItem: () => Promise.resolve(),
  }; // Mock storage for SSR
};

const storage = createStorage();

export default storage;
