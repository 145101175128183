// redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import rootReducer from './reducers';
import storage from './lib/storage'; // Import the custom storage module

// Function to get the userId from the Redux state
const getUserId = (state) => state.auth.userId;

// Persist configuration with dynamic secretKey
const createPersistConfig = (userId) => ({
  key: 'root',
  storage,
  transforms: [
    encryptTransform({
      secretKey: userId ? `user_${userId}` : 'c68d36af1ecfe22b176ee71e46222210ee49c1f4b0b457ed7712c4b1cf7420f1', // Use userId if available
      onError: (error) => {
        console.error('Encryption error:', error);
      },
    }),
    {
      in: (inboundState, key) => {
        if (inboundState instanceof Date) {
          return { _type: 'Date', value: inboundState.toISOString() };
        }
        return inboundState;
      },
      out: (outboundState, key) => {
        if (outboundState && outboundState._type === 'Date') {
          return new Date(outboundState.value);
        }
        return outboundState;
      }
    }
  ],
});

const initializeStore = (preloadedState) => {
  const userId = getUserId(preloadedState || { auth: {} });
  const persistedReducer = persistReducer(createPersistConfig(userId), rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    // devTools: true, // Enable Redux DevTools
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        },
      }),
    preloadedState,
  });

  const persistor = persistStore(store);
  return { store, persistor };
};

const { store, persistor } = initializeStore();

export { store, persistor };
