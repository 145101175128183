import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill styles
import { MdOutlineCancel } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import { IoAddCircleOutline } from "react-icons/io5";
import { BASE_URL ,BACKENDTOKEN} from '../../../Constant/Constant';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../../../App.css'
const ActionAbout = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [points, setPoints] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [file, setFile] = useState(null);
  const [originalImage, setOriginalImage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const permissions = useSelector(state => state.roles.permissions.user_modules);
  const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
  // Define validation schema
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),

  });
  useEffect(() => {
    if (permissions) {
      try {
        const modules = JSON.parse(permissions);
        const aboutus = modules.find(module => module.rolename === 'aboutus');
        if (aboutus) {
          setRolePermissions(aboutus.permissions);
        }
      } catch (error) {
        console.error('Failed to parse permissions:', error);
      }
    }
  }, [permissions]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}about/get/${id}`, {
          headers: {
            'Authorization': `Bearer ${BACKENDTOKEN}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        data.points = Array.isArray(data.points) ? data.points : JSON.parse(data.points || '[]');
        setTitle(data.title);
        setDescription(data.description);
        setPoints(data.points);
        setOriginalImage(data.image);
        setPreviewImage(`${BASE_URL}uploads/${data.image}`);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    setIsEditable(action === 'edit');
  }, [action]);

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('points', JSON.stringify(points));

      if (file) {
        formData.append('new_image', file);
      } else {
        formData.append('image', originalImage);
      }

      await axios.put(`${BASE_URL}about/update/${id}`, formData, {
        headers: {
          'Authorization': `Bearer ${BACKENDTOKEN}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.dismiss();
      toast.success('About updated successfully', {
        autoClose: 1500,
      });
      setTimeout(() => {
        navigate(`/actionabout/${id}?action=view`);
      });
    } catch (error) {
      toast.dismiss();
      toast.error('Failed to update about', {
        autoClose: 1500,
      });
    }
  };

  const handleEdit = () => {
    if (action === 'view' && rolePermissions.read === 'yes') {
      navigate(`/actionabout/${id}?action=edit`);
    }
  };

  const handleCancel = () => {
    navigate(`/actionabout/${id}?action=view`);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreviewImage(URL.createObjectURL(selectedFile));
    }
  };

  const handleChange = (e, index) => {
    const newPoints = [...points];
    newPoints[index] = e.target.value;
    setPoints(newPoints);
  };

  const handleRemovePoint = (index) => {
    const newPoints = [...points];
    newPoints.splice(index, 1);
    setPoints(newPoints);
  };

  const handleAddPoint = () => {
    setPoints([...points, '']);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container-xl my-4">
      <ToastContainer />
      <div className="row">
        <div className="col-md-6">
          <h4>AboutUs</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="../aboutus" className="fs-6">Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className="fs-6">ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-name">{isEditable ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
            <Formik
              initialValues={{ title, description }}
              validationSchema={validationSchema}
              onSubmit={handleUpdate}
              enableReinitialize={true}  // Enable reinitialization of values
            >
              {({ handleChange, handleBlur, setFieldValue, values }) => (
                <Form className="row g-3">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="col-sm col-form-label">Title<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field
                        type="text"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!isEditable}
                        className="form-control mb-2"
                      />
                      <ErrorMessage name="title" component="div" className="text-danger" />
                    </div>
                    <div className="mb-3">
                      <label className="col-sm col-form-label" htmlFor="image">Image</label>
                      <div className="mb-3">
                        {previewImage && (
                          <img
                            src={previewImage}
                            alt="Image Preview"
                            className="img-fluid mb-2 rounded"
                          />
                        )}
                      </div>
                      {isEditable && (
                        <input
                          type="file"
                          id="image"
                          accept="image/jpeg, image/png, image/gif"
                          onChange={handleFileChange}
                          className="form-control mb-2"
                        />
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="col-sm col-form-label">Points</label>
                      <div className=''>
                        {points.map((point, index) => (
                          <div key={index} className='input-group mb-3'>
                            <input
                              type="text"
                              value={point}
                              onChange={(e) => handleChange(e, index)}
                              required
                              disabled={!isEditable}
                              className="form-control"
                            />
                            {isEditable && (
                              <button type="button" className='btn btn-label-secondary waves-effect waves-light' onClick={() => handleRemovePoint(index)}>
                                <MdOutlineCancel />
                              </button>
                            )}
                          </div>
                        ))}
                        {isEditable && (
                          <button type="button" className="btn btn-primary" onClick={handleAddPoint}>
                            <IoAddCircleOutline style={{ fontSize: "16px" }} />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                  <label className="col-sm col-form-label">Description</label>
                  {isEditable ? (
                    <ReactQuill
                      theme="snow"
                      value={description}
                      onChange={handleDescriptionChange}
                      modules={{
                        toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ 'size': [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                          ['link', 'image'],
                          ['clean']
                        ]
                      }}
                      style={{ height: '300px', borderRadius: '10px' }}
                      className="react-quill-editor mb-3"
                    />
                  ) : (
                    <div
                      className="form-control mb-2"
                      dangerouslySetInnerHTML={{ __html: description }}
                      style={{ minHeight: '130px', backgroundColor: "#f4f4f4" }}
                    />
                  )}
                </div>
                    </div>

                  {isEditable ? (
                    <div className="col-12 mt-5 d-flex justify-content-end align-items-center">
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                      <button type="button" onClick={handleCancel} className="btn btn-label-secondary ms-2">
                        Cancel
                      </button>
                    </div>
                  ) : (
                    rolePermissions.write === 'yes' && (
                      <div className="col-12 d-flex justify-content-end align-items-center">
                        <button type="button" onClick={handleEdit} className="btn btn-primary">
                          Edit
                        </button>
                      </div>
                    )
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionAbout;
