// src/features/auth/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: null,
    userId: null,
    isAuthenticated: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.token = action.payload.token;
            state.userId = action.payload.userId;
            state.isAuthenticated = true;
        },
        logout: (state) => {
            state.token = null;
            state.userId = null;
            state.isAuthenticated = false;
        },
        // Add other reducers if necessary
    },
});

export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
