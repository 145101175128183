import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, BACKENDTOKEN ,CRYPTOJS_ENCRYPTION_KEY} from '../../../Constant/Constant'
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import CryptoJS from 'crypto-js';

const TimeSetting = () => {
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingSetting, setEditingSetting] = useState(null);
    const [formData, setFormData] = useState({
        night_start: '',
        night_end: '',
    });
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [token, setToken] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
   
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
    }, []);
    useEffect(() => {
        if (permissions) {
            try {
                const modules = JSON.parse(permissions);
                const centralSetting = modules.find(module => module.rolename === 'centralSetting');
                if (centralSetting) {
                    setRolePermissions(centralSetting.permissions);
                }
            } catch (error) {
                console.error('Failed to parse permissions:', error);
            }
        }
    }, [permissions]);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.get(`${BASE_URL}api/central_setting_time`);
                setSettings(response.data);
            } catch (error) {
                toast.error('Failed to fetch settings: ' + error.message, {
                    autoClose: 1500,
                });
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    const handleEditClick = (setting) => {
        setEditingSetting(setting);
        setFormData({
            night_start: setting.night_start,
            night_end: setting.night_end,
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {

            if (rolePermissions.write === 'no') {
                toast.dismiss();
                toast.error('You do not have permission to update data.', {
                    autoClose: 1500,
                });
                return;
            }
            await axios.put(`${BASE_URL}api/central_setting_time/${editingSetting.id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setSettings(settings.map(setting => (setting.id === editingSetting.id ? { ...editingSetting, ...formData } : setting)));
            toast.success('Settings updated successfully!', {
                autoClose: 1500,
            });
            setEditingSetting(null);
            setFormData({
                night_start: '',
                night_end: '',
            });
        } catch (error) {
            toast.error('Failed to update settings: ' + error.message, {
                autoClose: 1500,
            });
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="container-xl my-4">
            <Helmet>
    <title>central Setting - TimeSetting </title>
    <meta name="description" content="This is the time setting  page of the application" />
  </Helmet>
            <ToastContainer />
            <div className="row">
                <div className="col-md-6">
                    <h4><span className="text-muted fw-light"> </span> TimeSetting</h4>
                </div>
                <div className="col-md-6">
                    <div className="breadcumbtext">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-end">
                                <li className="breadcrumb-item">
                                    <Link className="footer-link fs-6" to="../">Back</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link className="footer-link fs-6" to="#">TimeSetting</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="row row-cards">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header header-underline">
                            <div className='d-flex justify-content-between align-items-center'>
                                <h5 className="card-title me-3">Night Time</h5>
                            </div>
                        </div>
                        <div className="card-body">
                            {editingSetting ? (
                                <form onSubmit={handleFormSubmit} className="row g-3">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className='form-label'>
                                                Night Start:
                                            </label>
                                            <input
                                                type="time"
                                                name="night_start"
                                                value={formData.night_start}
                                                onChange={handleInputChange}
                                                className='form-control'
                                                style={{ width: '500px' }}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className='form-label'>
                                                Night End:
                                            </label>
                                            <input
                                                type="time"
                                                name="night_end"
                                                value={formData.night_end}
                                                onChange={handleInputChange}
                                                style={{ width: '500px' }}
                                                required
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-end align-items-center">
                                        <button type="submit" className="btn btn-primary">Update</button>
                                        <button type="button" className="btn btn-label-secondary ms-2" onClick={() => setEditingSetting(null)}>Cancel</button>
                                    </div>
                                </form>
                            ) : (
                                settings.map(setting => (
                                    <div className="row g-3" key={setting.id}>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className='form-label'>
                                                    Night Start:
                                                </label>
                                                <input
                                                    type="time"
                                                    name="night_start"
                                                    value={setting.night_start}
                                                    disabled
                                                    className='form-control'
                                                    style={{ width: '500px' }}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className='form-label'>
                                                    Night End:
                                                </label>
                                                <input
                                                    type="time"
                                                    name="night_end"
                                                    value={setting.night_end}
                                                    disabled
                                                    style={{ width: '500px' }}
                                                    required
                                                    className='form-control'
                                                />
                                            </div>
                                        </div>
                                        {rolePermissions.write === 'yes' && (
                                            <div className="col-12 d-flex justify-content-end align-items-center">
                                                <button className="btn btn-primary" onClick={() => handleEditClick(setting)}>Edit</button>
                                            </div>
                                        )}
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TimeSetting;
