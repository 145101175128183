import React from 'react';
import { Link } from 'react-router-dom';

const NoPage = () => {
  return (
    <main className="main bg-light-subtle mt-5">
      <section className="section mt-120 mb-100">
        <div className="container-sub">
          <div className="text-center">
            <img
              className="wow fadeInUp"
              src="../../assets/img/pages/404/404.svg"
              alt="Aargauertaxi"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            />
            <h2
              className="heading-44-medium color-text mt-5 mb-20 wow fadeInUp"
              style={{ visibility: "visible", animationName: "none" }}
            >
              Oops! It looks like you're lost.
            </h2>
            <p
              className="color-text text-16 mb-25 wow fadeInUp"
              style={{ visibility: "visible", animationName: "none" }}
            >
              The page you're looking for isn't available. Try to search again
              or use the go to.
            </p>
            <Link
              className="btn btn-primary mb-5"
              to="/"  // Adjust the path to your homepage route
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              Go Back to Homepage<svg className="icon-16 ml-5 mx-2" fill="none" style={{width:"20px"}} stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"></path>
              </svg>
             
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
};

export default NoPage;
