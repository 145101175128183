import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL, BACKENDTOKEN,SELECTEDLANGUAGEID,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2  } from '../../../../Constant/Constant';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Ensure toast styles are imported
import '../../../../App.css'; // Assuming you have a CSS file for custom styles
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';


const Partners = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        PartnerTitle: ''
    });
    const [imageFiles, setImageFiles] = useState({
        Image1: null,
        Image2: null,
        Image3: null,
        Image4: null
    });
    const [imageUrls, setImageUrls] = useState({
        Image1: null,
        Image2: null,
        Image3: null,
        Image4: null
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [id, setId] = useState(null);
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [isBookingBoxData, setIsBookingBoxData] = useState(false);
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);

  
    useEffect(() => {
      if (permissions) {
        try {
          const modules = JSON.parse(permissions);
          const legalcontent = modules.find(module => module.rolename === 'staticcontent');
          if (legalcontent) {
            setRolePermissions(legalcontent.permissions);
            setIsBookingBoxData(legalcontent.permissions.write === 'no');
           
          }
        } catch (error) {
          console.error('Failed to parse permissions:', error);
        }
      }
    }, [permissions]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setId(response.data.id);
                if (response.data && response.data.partners) {
                    const parsedData = JSON.parse(response.data.partners);
                    setFormValues(parsedData);
                    setIsDataExists(true);
                    setIsEditMode(false); // Data exists, so not in edit mode

                    // Set existing image URLs if they exist
                    setImageUrls({
                        Image1: parsedData.Image1 || null,
                        Image2: parsedData.Image2 || null,
                        Image3: parsedData.Image3 || null,
                        Image4: parsedData.Image4 || null
                    });
                } else {
                    setFormValues({
                        PartnerTitle: ''
                    });
                    setIsDataExists(false);
                    setIsEditMode(true); // No data, so allow adding
                }
            } catch (error) {
                setIsEditMode(true); // Error fetching data, possibly allow editing
            }
        };

        fetchData();
    }, [languageId, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setImageFiles((prevFiles) => ({
            ...prevFiles,
            [name]: files[0],
        }));

        // Generate URL for the selected image (this is for local preview before upload)
        const fileUrl = URL.createObjectURL(files[0]);
        setImageUrls((prevUrls) => ({
            ...prevUrls,
            [name]: fileUrl,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('partners', JSON.stringify(formValues));
            formData.append('language_id', languageId);

            // Append files if available
            if (imageFiles.Image1) formData.append('Image1', imageFiles.Image1);
            if (imageFiles.Image2) formData.append('Image2', imageFiles.Image2);
            if (imageFiles.Image3) formData.append('Image3', imageFiles.Image3);
            if (imageFiles.Image4) formData.append('Image4', imageFiles.Image4);

            const url = `${BASE_URL}staticcontent/partners`;
            const response = await axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data && response.data.message === 'partners processed successfully!') {
                toast.success('Data processed successfully!', { autoClose: 1500 });
                setIsDataExists(true);
            } else {
                toast.warn('Unexpected response from the server.', { autoClose: 1500 });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.error('Error saving data.', { autoClose: 1500 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-md-3">
                    <label className="form-label">Partner Title</label>
                    <input
                        name="PartnerTitle"
                        type="text"
                        className="form-control"
                        value={formValues.PartnerTitle}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
                <div className="col-md-3">
                    <label className="form-label">Image1</label>
                    <input
                        name="Image1"
                        type="file"
                        className="form-control"
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png, image/gif"
                      disabled={loading ||isBookingBoxData}
                    />
                    {imageFiles.Image1 ? (
                        <img src={imageUrls.Image1} alt="Image 1 preview" className="image-preview" />
                    ) : (
                        imageUrls.Image1 && <img src={`${BASE_URL}uploads/${imageUrls.Image1}`} alt="Image 1" className="image-preview" />
                    )}
                </div>
                <div className="col-md-3">
                    <label className="form-label">Image2</label>
                    <input
                        name="Image2"
                        type="file"
                        className="form-control"
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png, image/gif"
                      disabled={loading ||isBookingBoxData}
                    />
                    {imageFiles.Image2 ? (
                        <img src={imageUrls.Image2} alt="Image 2 preview" className="image-preview" />
                    ) : (
                        imageUrls.Image2 && <img src={`${BASE_URL}uploads/${imageUrls.Image2}`} alt="Image 2" className="image-preview" />
                    )}
                </div>
                <div className="col-md-3">
                    <label className="form-label">Image3</label>
                    <input
                        name="Image3"
                        type="file"
                        className="form-control"
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png, image/gif"
                      disabled={loading ||isBookingBoxData}
                    />
                    {imageFiles.Image3 ? (
                        <img src={imageUrls.Image3} alt="Image 3 preview" className="image-preview" />
                    ) : (
                        imageUrls.Image3 && <img src={`${BASE_URL}uploads/${imageUrls.Image3}`} alt="Image 3" className="image-preview" />
                    )}
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label">Image4</label>
                    <input
                        name="Image4"
                        type="file"
                        className="form-control"
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png, image/gif"
                      disabled={loading ||isBookingBoxData}
                    />
                    {imageFiles.Image4 ? (
                        <img src={imageUrls.Image4} alt="Image 4 preview" className="image-preview" />
                    ) : (
                        imageUrls.Image4 && <img src={`${BASE_URL}uploads/${imageUrls.Image4}`} alt="Image 4" className="image-preview" />
                    )}
                </div>

                <div className="card-footer text-end mt-5">
                {rolePermissions.write === 'yes' && 
                    <button
                        type="submit"
                        className="btn btn-primary"
                      disabled={loading ||isBookingBoxData}
                    >
                        {loading ? 'Saving...' : isDataExists ? 'Update' : 'Save'}
                    </button>
}
                </div>
            </form>
        </>
    );
};

export default Partners;
