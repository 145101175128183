import { useNavigate } from 'react-router-dom';

const TokenExpiredPopup = () => {
  const navigate = useNavigate();

  return (
    <div className="token-invalid-overlay">
      <div className="token-invalid-popup">
        <p>Your session has expired. Please log in again.</p>
        <button
          className="btn bg-dark text-white rounded-pill"
          onClick={() => navigate('/logout')}
        >
          Log In
        </button>
      </div>
    </div>
  );
};

export default TokenExpiredPopup;

