import { useState, useEffect } from 'react'; // Import React hooks
import {jwtDecode} from 'jwt-decode'; // Fixed import statement (remove curly braces)
import { BACKENDTOKEN, CRYPTOJS_ENCRYPTION_KEY } from '../Constant/Constant';
import CryptoJS from 'crypto-js';

// Convert to a custom hook
export const useTokenData = () => {
    const [token, setToken] = useState(null); // useState now defined

    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };

    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };

    useEffect(() => {
        fetchBackendToken(); // Fetch and set token
    }, []);

    if (token) {
        try {
            // Decode the token without verifying its signature
            const decodedToken = jwtDecode(token);
            // Extract userId from the decoded token
            const userId = decodedToken.id || null;
            return { userId };
        } catch (error) {
            console.error('Error decoding token', error);
            return { userId: null };
        }
    }

    return { userId: null };
};
