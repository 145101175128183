import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Modules from './Modules';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, CRYPTOJS_ENCRYPTION_KEY } from '../../../Constant/Constant';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'; // Make sure to install this package
import CryptoJS from 'crypto-js';

const AddUser = () => {
  const [permissionsData, setPermissionsData] = useState({});
  const [resetFormFlag, setResetFormFlag] = useState(false);
  const [userTypes, setUserTypes] = useState([]); // State for user types
  const [token, setToken] = useState(null);

  // Function to decrypt tokens
  const decryptToken = (encryptedToken, key) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
      const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedToken ? decryptedToken : null;
    } catch (error) {
      console.error('Error decrypting token:', error);
      return null;
    }
  };

  // Fetch token from localStorage and decrypt it
  const fetchBackendToken = async () => {
    const encryptedBackendToken = localStorage.getItem('BackendToken');
    if (encryptedBackendToken) {
      const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
      setToken(decryptedToken); // Set token to state
    } else {
      console.warn('No BackendToken found in localStorage.');
    }
  };


  useEffect(() => {
    fetchBackendToken(); // Fetch and set token
  }, []);



  useEffect(() => {
    // Fetch user types from the API
    const fetchUserTypes = async () => {
      try {
        const response = await axios.get(`${BASE_URL}user/permission`);
        const data = response.data[0]; // Access the first item of the array
        const roles = JSON.parse(data.user_role); // Parse the JSON string into an array

        // Map the roles into an array of objects with id and userRole
        const rolesWithIds = roles.map((role, index) => ({
          id: index + 1, // Or use a unique ID if available
          userRole: role
        }));

        setUserTypes(rolesWithIds); // Set the mapped array to state
      } catch (error) {
        console.error('Error fetching user types:', error); // Log the error for debugging
      }
    };

    fetchUserTypes();
  }, []); // Empty dependency array means this effect runs once on mount

  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    mobilenumber: '', // Ensure this matches your form structure
    role: '',
    password: ''
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string(), // You can set this as required if needed
    email: Yup.string().email('Invalid email address').required('Email is required'),
    mobilenumber: Yup.string().required('Mobile Number is required'),
    password: Yup.string().required('Password is required'),
    role: Yup.string().required('Role is required'),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const userData = {
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email,
      mobile_number: values.mobilenumber,
      role: values.role,
      user_modules: JSON.stringify(permissionsData),
      password: values.password
    };


    try {
      const response = await axios.post(`${BASE_URL}user/add`, userData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      toast.success(`User added successfully: ${response.data.message || 'User has been added successfully.'}`, {
        autoClose: 1500,
      });
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred. Please try again later.';
      toast.error(`Error adding user: ${errorMessage}`, {
        autoClose: 1500,
      });
    }

    // Reset form fields and related state
    setPermissionsData({});
    setResetFormFlag((prevFlag) => !prevFlag); // Trigger reset
    resetForm();
  };

  const handlePermissionsChange = (updatedPermissions) => {
    setPermissionsData(updatedPermissions);
  };

  return (
    <div className="container-xl my-4">
      <Helmet>
        <title>User Create Page </title>
        <meta name="description" content="This is the user create page of the application" />
      </Helmet>
      <ToastContainer />
      <div className="row row-cards">
        <div className="row">
          <div className="col-md-6">
            <h4>Users</h4>
          </div>
          <div className="col-md-6">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-end fs-3">
                <li className="breadcrumb-item"><Link to="/userlist" className='fs-6'>Back</Link></li>
                <li className="breadcrumb-item"><Link to="#" className='fs-6'>Add</Link></li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className='d-flex justify-content-between align-items-center'>
                <h5 className="card-title me-3">Add</h5>
              </div>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <div className="card-body">
                    <div className="row g-3">
                      <div className="col-md-12">
                        <div className="row g-2 mb-3">
                          <div className="col-sm-3">
                            <label htmlFor="firstname" className="form-label">First Name<span className='text-danger px-1 fs-5'>*</span></label>
                            <Field
                              id="firstname"
                              name="firstname"
                              type="text"
                              className={`form-control ${errors.firstname && touched.firstname ? 'is-invalid' : ''}`}
                            />
                            <ErrorMessage name="firstname" component="div" className="invalid-feedback" />
                          </div>
                          <div className="col-sm-3">
                            <label htmlFor="lastname" className="form-label">Last Name</label>
                            <Field
                              id="lastname"
                              name="lastname"
                              type="text"
                              className={`form-control ${errors.lastname && touched.lastname ? 'is-invalid' : ''}`}
                            />
                            <ErrorMessage name="lastname" component="div" className="invalid-feedback" />
                          </div>
                          <div className="col-sm-3">
                            <label htmlFor="email" className="form-label">Email<span className='text-danger px-1 fs-5'>*</span></label>
                            <Field
                              id="email"
                              name="email"
                              type="email"
                              className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                            />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                          </div>
                          <div className="col-sm-3">
                            <label className="form-label" htmlFor="mobilenumber">Mobile<span className='text-danger px-1 fs-5'>*</span></label>
                            <PhoneInput
                              country={'ch'} // Set default country code
                              value={initialValues.mobilenumber} // Ensure this reflects Formik's state
                              onChange={(value) => setFieldValue('mobilenumber', value)} // Update Formik state
                              className={` ${errors.mobilenumber && touched.mobilenumber ? 'is-invalid' : ''}`}
                              inputStyle={{
                                width: "100%",
                                height: "40px",
                                paddingLeft: "44px",
                                borderRadius: "5px",
                                fontSize: "16px",
                              }}
                            />
                            <ErrorMessage name="mobilenumber" component="div" className="invalid-feedback" />
                          </div>
                        </div>
                        <div className="row g-2 mb-3">
                          <div className="col-sm-3">
                            <label htmlFor="role" className="form-label">Role<span className='text-danger px-1 fs-5'>*</span></label>
                            <Field
                              as="select"
                              id="role"
                              name="role"
                              className={`form-select ${errors.role && touched.role ? 'is-invalid' : ''}`}
                            >
                              <option value="">Select a role</option>
                              {userTypes.map((userType) => (
                                <option key={userType.id} value={userType.userRole}>{userType.userRole}</option>
                              ))}
                            </Field>
                            <ErrorMessage name="role" component="div" className="invalid-feedback" />
                          </div>
                          <div className="col-sm-3">
                            <label htmlFor="password" className="form-label">Password<span className='text-danger px-1 fs-5'>*</span></label>
                            <Field
                              id="password"
                              name="password"
                              type="password"
                              className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`}
                            />
                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 col-sm-6 mb-4 mt-3">
                        <Modules onPermissionsChange={handlePermissionsChange} reset={resetFormFlag} />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="row ">
                      <div className="col-md-12 text-end">
                        <button type="submit" className="btn btn-primary waves-effect waves-light">Create</button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
