import React from 'react'

const Footer = () => {
  return (
    <>
      <footer className="footer pt-3 bg-light">
        <div className="copyright text-muted text-center">
          <p>© 2024 Aargauertaxi. All Rights Reserved.</p>
        </div>
       </footer>
    </>
  )
}

export default Footer