import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../../App.css'

const TableTitle = ({ title, addLink, RecentLists }) => {
    return (
        <div className="d-flex justify-content-between align-items-center">
            <h5>{title}</h5>
            {!RecentLists === true &&
                <>
                    {typeof addLink === 'string' ? (
                        <Link to={addLink} className="btn btn-primary">
                            <i className="fa fa-plus me-2"></i>  Add
                        </Link>
                    ) : (
                        addLink // This assumes addLink is a React element
                    )}
                </>
            }
            {RecentLists === true &&
                <>
                    {typeof addLink === 'string' ? (
                        <Link to={addLink} className=" p-2 pt-0 pb-0 text-primary b-0 rounded-pill mb-3 recents" >
                            View More
                        </Link>
                    ) : (
                        addLink // This assumes addLink is a React element
                    )}

                </>
            }

        </div>
    );
};

TableTitle.propTypes = {
    title: PropTypes.string.isRequired,
    addLink: PropTypes.oneOfType([
        PropTypes.string, // Now accepts a string
        PropTypes.node,   // Can also be a React element
    ]),
};

export default TableTitle;
