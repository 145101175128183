import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { BASE_URL, BACKENDTOKEN,SELECTEDLANGUAGEID ,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2 } from '../../../../Constant/Constant';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

const HomePage = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        HomePageTitle: '',
        HomePageButton: '',
        Description: '',
        OurFleetTitle: '',
        TripTitle: '',
        SafetyTitle: '',
        PricesTitle: '',
        SolutionsTitle: '',
        SafetyDescription: '',
        PricesDescription: '',
        SolutionsDescription: '',
        ShowcaseTitle: '',
        VehiclesTitle: '',
        VehiclesCount: '',
        AwardsTitle: '',
        AwardsCount: '',
        CustomerTitle: '',
        CustomerCount: '',
        MoreOurFleet: '',
        ServicesTitle: '',
        MoreServices: '',
        FaqTitle: ''

    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [id, setId] = useState(null);
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [isBookingBoxData, setIsBookingBoxData] = useState(false);
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);


    useEffect(() => {
        if (permissions) {
            try {
                const modules = JSON.parse(permissions);
                const legalcontent = modules.find(module => module.rolename === 'staticcontent');
                if (legalcontent) {
                    setRolePermissions(legalcontent.permissions);
                    setIsBookingBoxData(legalcontent.permissions.write === 'no');

                }
            } catch (error) {
                console.error('Failed to parse permissions:', error);
            }
        }
    }, [permissions]);


   
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setId(response.data.id);
                if (response.data && response.data.homepage) {
                    const parsedData = JSON.parse(response.data.homepage);
                    setFormValues(parsedData);
                    setIsDataExists(true);
                    setIsEditMode(false); // Data exists, so not in edit mode
                } else {
                    setFormValues({
                        HomePageTitle: '',
                        HomePageButton: '',
                        Description: '',
                        OurFleetTitle: '',
                        TripTitle: '',
                        SafetyTitle: '',
                        PricesTitle: '',
                        SolutionsTitle: '',
                        SafetyDescription: '',
                        PricesDescription: '',
                        SolutionsDescription: '',
                        ShowcaseTitle: '',
                        VehiclesTitle: '',
                        VehiclesCount: '',
                        AwardsTitle: '',
                        AwardsCount: '',
                        CustomerTitle: '',
                        CustomerCount: '',
                        MoreOurFleet: '',
                        ServicesTitle: '',
                        MoreServices: '',
                        FaqTitle: ''
                    });
                    setIsDataExists(false);
                    setIsEditMode(true); // No data, so allow adding
                }
            } catch (error) {
                setIsEditMode(true); // Error fetching data, possibly allow editing
            }
        };

   

    useEffect(() => {
        if (token && languageId) {
            fetchData();
        }
    }, [token, languageId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleDescriptionChange = (value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            Description: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                homepage: formValues,  // This should be structured correctly
                language_id: languageId,
            };

            const url = `${BASE_URL}staticcontent/homepage`;
            const response = await axios.post(url, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data && response.data.message === 'HomePage Process successfully!') {
                toast.dismiss()
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);
            } else {
                toast.dismiss()
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.dismiss()
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
     
            <form className="row g-3" onSubmit={handleSubmit}>
              
                <h5>Home Info</h5>
                <div className="col-md-3">
                    <label className="form-label">HomePage Title</label>
                    <input
                        name="HomePageTitle"
                        type="text"
                        className="form-control"
                        value={formValues.HomePageTitle}
                        onChange={handleChange}
                     disabled={loading ||isBookingBoxData}
                    />
                </div>
                <div className="col-md-3">
                    <label className="form-label">HomePage Button</label>
                    <input
                        name="HomePageButton"
                        type="text"
                        className="form-control"
                        value={formValues.HomePageButton}
                        onChange={handleChange}
                     disabled={loading ||isBookingBoxData}
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">HomePage Description</label>
                    <textarea
                        name="Description"
                        className="form-control"
                        rows="2" // This will create a 2-row textarea
                        value={formValues.Description}
                        onChange={handleChange}
                     disabled={loading ||isBookingBoxData}
                    />
                </div>

                <div className="col-md-3 mb-3">
                    <label className="form-label">OurFleet Title</label>
                    <input
                        name="OurFleetTitle"
                        type="text"
                        className="form-control"
                        value={formValues.OurFleetTitle}
                        onChange={handleChange}
                     disabled={loading ||isBookingBoxData}
                    />
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label">More OurFleet</label>
                    <input
                        name="MoreOurFleet"
                        type="text"
                        className="form-control"
                        value={formValues.MoreOurFleet}
                        onChange={handleChange}
                     disabled={loading ||isBookingBoxData}
                    />
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label">Services Title</label>
                    <input
                        name="ServicesTitle"
                        type="text"
                        className="form-control"
                        value={formValues.ServicesTitle}
                        onChange={handleChange}
                     disabled={loading ||isBookingBoxData}
                    />
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label">More Services</label>
                    <input
                        name="MoreServices"
                        type="text"
                        className="form-control"
                        value={formValues.MoreServices}
                        onChange={handleChange}
                        disabled={loading ||isBookingBoxData}
                    />
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label">Faq Title</label>
                    <input
                        name="FaqTitle"
                        type="text"
                        className="form-control"
                        value={formValues.FaqTitle}
                        onChange={handleChange}
                        disabled={loading ||isBookingBoxData}
                    />
                </div>
                <h5>Trip Info</h5>
                <div className="col-md-3">
                    <div className="mb-1">
                        <label className="form-label">Trip Title</label>
                        <input
                            name="TripTitle"
                            type="text"
                            className="form-control"
                            value={formValues.TripTitle}
                            onChange={handleChange}
                            disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-1">
                        <label className="form-label">Safety Title</label>
                        <input
                            name="SafetyTitle"
                            type="text"
                            className="form-control"
                            value={formValues.SafetyTitle}
                            onChange={handleChange}
                            disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-1">
                        <label className="form-label">Prices Title</label>
                        <input
                            name="PricesTitle"
                            type="text"
                            className="form-control"
                            value={formValues.PricesTitle}
                            onChange={handleChange}
                            disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="mb-1">
                        <label className="form-label">Solutions Title</label>
                        <input
                            name="SolutionsTitle"
                            type="text"
                            className="form-control"
                            value={formValues.SolutionsTitle}
                            onChange={handleChange}
                            disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <label className="form-label">Safety Description</label>
                    <textarea
                        name="SafetyDescription"
                        className="form-control"
                        rows="2" // This will create a 2-row textarea
                        value={formValues.SafetyDescription}
                        onChange={handleChange}
                        disabled={loading ||isBookingBoxData}
                    />
                </div> <div className="col-md-4">
                    <label className="form-label">Prices Description</label>
                    <textarea
                        name="PricesDescription"
                        className="form-control"
                        rows="2" // This will create a 2-row textarea
                        value={formValues.PricesDescription}
                        onChange={handleChange}
                        disabled={loading ||isBookingBoxData}
                    />
                </div> <div className="col-md-4 mb-3">
                    <label className="form-label">Solutions Description</label>
                    <textarea
                        name="SolutionsDescription"
                        className="form-control"
                        rows="2" // This will create a 2-row textarea
                        value={formValues.SolutionsDescription}
                        onChange={handleChange}
                        disabled={loading ||isBookingBoxData}
                    />
                </div>
                <h5>Showcase  Info</h5>
                <div className="col-md-3">
                    <div className="mb-1">
                        <label className="form-label">Showcase Title</label>
                        <input
                            name="ShowcaseTitle"
                            type="text"
                            className="form-control"
                            value={formValues.ShowcaseTitle}
                            onChange={handleChange}
                            disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-1">
                        <label className="form-label">Vehicles Title</label>
                        <input
                            name="VehiclesTitle"
                            type="text"
                            className="form-control"
                            value={formValues.VehiclesTitle}
                            onChange={handleChange}
                            disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-1">
                        <label className="form-label">Vehicles Count</label>
                        <input
                            name="VehiclesCount"
                            type="text"
                            className="form-control"
                            value={formValues.VehiclesCount}
                            onChange={handleChange}
                            disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-1">
                        <label className="form-label">Awards Title</label>
                        <input
                            name="AwardsTitle"
                            type="text"
                            className="form-control"
                            value={formValues.AwardsTitle}
                            onChange={handleChange}
                            disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-1">
                        <label className="form-label">Awards Count</label>
                        <input
                            name="AwardsCount"
                            type="text"
                            className="form-control"
                            value={formValues.AwardsCount}
                            onChange={handleChange}
                            disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-1">
                        <label className="form-label">Customer Title</label>
                        <input
                            name="CustomerTitle"
                            type="text"
                            className="form-control"
                            value={formValues.CustomerTitle}
                            onChange={handleChange}
                            disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-1">
                        <label className="form-label">Customer Count</label>
                        <input
                            name="CustomerCount"
                            type="text"
                            className="form-control"
                            value={formValues.CustomerCount}
                            onChange={handleChange}
                            disabled={loading ||isBookingBoxData}
                           
                        />
                    </div>
                </div>
                <div className="card-footer text-end mt-5">

                    {rolePermissions.write === 'yes' &&

                        <button
                            type="submit"
                            className="btn btn-primary"
                         disabled={loading ||isBookingBoxData}
                        >
                            {loading ? 'Saving...' : isDataExists ? 'Update' : 'Save'}
                        </button>
                    }
                </div>

            </form>
        </>
    );
};

export default HomePage;


