
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL ,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2 } from '../../../../Constant/Constant';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

const HowToWorks = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        MainTitle: '',
        Title1: '',
        Description1: '',
        Title2: '',
        Description2: '',
        Title3: '',
        Description3: '',
    });
    const [imageFiles, setImageFiles] = useState({
        Image1: null,
        Image2: null,
        Image3: null,
    });
    const [imagePreviews, setImagePreviews] = useState({
        Image1: '',
        Image2: '',
        Image3: '',
    });
    const [existingImages, setExistingImages] = useState({
        Image1: '',
        Image2: '',
        Image3: '',
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [id, setId] = useState(null);
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [isBookingBoxData, setIsBookingBoxData] = useState(false);

    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);

  
    useEffect(() => {
      if (permissions) {
        try {
          const modules = JSON.parse(permissions);
          const legalcontent = modules.find(module => module.rolename === 'staticcontent');
          if (legalcontent) {
            setRolePermissions(legalcontent.permissions);
            setIsBookingBoxData(legalcontent.permissions.write === 'no');
           
          }
        } catch (error) {
          console.error('Failed to parse permissions:', error);
        }
      }
    }, [permissions]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setId(response.data.id);
                if (response.data && response.data.howtowork) {
                    const parsedData = JSON.parse(response.data.howtowork);
                    setFormValues(parsedData);
                    setExistingImages({
                        Image1: parsedData.Image1 || '',
                        Image2: parsedData.Image2 || '',
                        Image3: parsedData.Image3 || '',
                    });
                    setIsDataExists(true);
                    setIsEditMode(false);
                } else {
                    setFormValues({
                        MainTitle: '',
                        Title1: '',
                        Description1: '',
                        Title2: '',
                        Description2: '',
                        Title3: '',
                        Description3: '',
                    });
                    setIsDataExists(false);
                    setIsEditMode(true);
                }
            } catch (error) {
                setIsEditMode(true);
            }
        };

        fetchData();
    }, [languageId, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        if (file) {
            // Create a preview URL for the selected image
            const previewUrl = URL.createObjectURL(file);

            setImageFiles((prevFiles) => ({
                ...prevFiles,
                [name]: file,
            }));

            setImagePreviews((prevPreviews) => ({
                ...prevPreviews,
                [name]: previewUrl,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('howtowork', JSON.stringify(formValues));
            formData.append('language_id', languageId);

            if (imageFiles.Image1) {
                formData.append('Image1', imageFiles.Image1);
            }
            if (imageFiles.Image2) {
                formData.append('Image2', imageFiles.Image2);
            }
            if (imageFiles.Image3) {
                formData.append('Image3', imageFiles.Image3);
            }

            const url = `${BASE_URL}staticcontent/howtowork`;
            const response = await axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data && response.data.message === 'howtowork processed successfully!') {
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);
            } else {
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                {/* Form Inputs */}
                <div className="col-md-4">
                    <label className="form-label">Main Title</label>
                    <input
                        name="MainTitle"
                        type="text"
                        className="form-control"
                        value={formValues.MainTitle}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
                {/* More Input Fields */}
                <div className="col-md-4">
                    <label className="form-label">Title1</label>
                     <input
                         name="Title1"
                        type="text"
                         className="form-control"
                         value={formValues.Title1}
                         onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label"> Title2</label>
                    <input
                        name="Title2"
                        type="text"
                        className="form-control"
                        value={formValues.Title2}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label">Title3</label>
                    <input
                        name="Title3"
                        type="text"
                        className="form-control"
                        value={formValues.Title3}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label"> Description1</label>
                    <textarea
                        name="Description1"
                        className="form-control"
                        rows="2" //This will create a 2-row textarea
                        value={formValues.Description1}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label"> Description2</label>
                    <textarea
                        name="Description2"
                        className="form-control"
                        rows="2" //This will create a 2-row textarea
                        value={formValues.Description2}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label"> Description3</label>
                    <textarea
                        name="Description3"
                        className="form-control"
                        rows="2" // This will create a 2-row textarea
                        value={formValues.Description3}
                        onChange={handleChange}
                      disabled={loading ||isBookingBoxData}
                    />
                </div>
                {/* Image Upload and Preview */}
                <div className="col-md-4">
                    <label className="form-label">Image 1</label>
                    <input
                        name="Image1"
                        type="file"
                        accept="image/jpeg, image/png, image/gif"
                        className="form-control"
                        onChange={handleFileChange}
                      disabled={loading ||isBookingBoxData}
                    />
                    {imagePreviews.Image1 ? (
                        <img src={imagePreviews.Image1} alt="Image Preview 1" className="image-preview" />
                    ) : existingImages.Image1 && (
                        <img src={`${BASE_URL}uploads/${existingImages.Image1}`} alt="Existing Image 1" className="image-preview" />
                    )}
                </div>

                <div className="col-md-4">
                    <label className="form-label">Image 2</label>
                    <input
                        name="Image2"
                        type="file"
                        accept="image/jpeg, image/png, image/gif"
                        className="form-control"
                        onChange={handleFileChange}
                      disabled={loading ||isBookingBoxData}
                    />
                    {imagePreviews.Image2 ? (
                        <img src={imagePreviews.Image2} alt="Image Preview 2" className="image-preview" />
                    ) : existingImages.Image2 && (
                        <img src={`${BASE_URL}uploads/${existingImages.Image2}`} alt="Existing Image 2" className="image-preview" />
                    )}
                </div>

                <div className="col-md-4">
                    <label className="form-label">Image 3</label>
                    <input
                        name="Image3"
                        type="file"
                        accept="image/jpeg, image/png, image/gif"
                        className="form-control"
                        onChange={handleFileChange}
                      disabled={loading ||isBookingBoxData}
                    />
                    {imagePreviews.Image3 ? (
                        <img src={imagePreviews.Image3} alt="Image Preview 3" className="image-preview" />
                    ) : existingImages.Image3 && (
                        <img src={`${BASE_URL}uploads/${existingImages.Image3}`} alt="Existing Image 3" className="image-preview" />
                    )}
                </div>

                <div className="card-footer text-end mt-5">
                {rolePermissions.write === 'yes' && 
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? 'Saving...' : isDataExists ? 'Update' : 'Save'}
                    </button>
}
                </div>
            </form>
        </>
    );
};

export default HowToWorks;
