import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BASE_URL,CRYPTOJS_ENCRYPTION_KEY } from '../../../Constant/Constant';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

const ActionCarCategory = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();

  const [previewImage, setPreviewImage] = useState(null);
  const [originalImage, setOriginalImage] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [token, setToken] = useState(null);
  
  // Function to decrypt tokens
  const decryptToken = (encryptedToken, key) => {
      try {
          const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
          const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
          return decryptedToken ? decryptedToken : null;
      } catch (error) {
          console.error('Error decrypting token:', error);
          return null;
      }
  };
  
  // Fetch token from localStorage and decrypt it
  const fetchBackendToken = async () => {
      const encryptedBackendToken = localStorage.getItem('BackendToken');
      if (encryptedBackendToken) {
          const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
          setToken(decryptedToken); // Set token to state
      } else {
          console.warn('No BackendToken found in localStorage.');
      }
  };
  
  
      useEffect(() => {
      fetchBackendToken(); // Fetch and set token
  }, []);

  const permissions = useSelector(state => state.roles.permissions.user_modules);
  const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });

  useEffect(() => {
    if (permissions) {
      try {
        const modules = JSON.parse(permissions);
        const carcatgory = modules.find(module => module.rolename === 'carcatgory');
        if (carcatgory) {
          setRolePermissions(carcatgory.permissions);
        }
      } catch (error) {
        console.error('Failed to parse permissions:', error);
      }
    }
  }, [permissions]);

  const fetchLegalContents= async () => {
    axios.get(`${BASE_URL}carcategory/get/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        const data = response.data;
        formik.setFieldValue('name', data.name);
        setOriginalImage(data.image);
        setPreviewImage(`${BASE_URL}uploads/${data.image}`);
      })
      .catch(error => {
        console.error('Error fetching car category:', error);
      });
  };
  useEffect(() => {
    if (token ) {
        fetchLegalContents();
    }
}, [token]);

  useEffect(() => {
    setIsEditable(action === 'edit');
  }, [action]);

  const formik = useFormik({
    initialValues: {
      name: '',
      image: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name is required'),
      image: Yup.mixed()
        .test('fileSize', 'The file is too large', (value) => {
          return value ? value.size <= 2 * 1024 * 1024 : true; // Max size 2MB
        })
        .test('fileType', 'Only image files are allowed', (value) => {
          return value ? ['image/jpeg', 'image/png', 'image/gif'].includes(value.type) : true;
        })
        .nullable()
    }),
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append('name', values.name);

        if (values.image) {
          formData.append('new_image', values.image);
        } else {
          formData.append('image', originalImage);
        }

        await axios.put(`${BASE_URL}carcategory/update/${id}`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        toast.dismiss();
        toast.success('Car category updated successfully', {
          autoClose: 1500,
        });
        setTimeout(() => {
          navigate(`/ActionCarCategory/${id}?action=view`);
        });
      } catch (error) {
        console.error('Error updating car category:', error);
        toast.dismiss();
        toast.error('Failed to update car category', {
          autoClose: 1500,
        });
      }
    }
  });

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      formik.setFieldValue('image', selectedFile);
      setPreviewImage(URL.createObjectURL(selectedFile));
    }
  };

  return (
    <div className="container-xl my-4">
      <ToastContainer/>
      <div className="row">
        <div className="col-md-6">
          <h4>Car Category</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="../carcategory" className='fs-6'>Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-name">{isEditable ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>

          <div className="card-body mt-3">
            <form onSubmit={formik.handleSubmit} className='row g-2'>
              <div className='col-5 me-5'>
                <div className="mb-3">
                  <label className="col-sm col-form-label">Name<span className='text-danger px-1 fs-5'>*</span></label>
                  <input
                    type="text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="name"
                    disabled={!isEditable}
                    className="form-control mb-2"
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger">{formik.errors.name}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-6 '>
                <div className="mb-3">
                  <label className="col-sm col-form-label" htmlFor="image">Image</label>
               
                  {isEditable && (
                    <>
                      <input
                        type="file"
                        id="image"
                        onChange={handleFileChange}
                        className="form-control mb-2"
                        accept="image/*"
                      />
                      {formik.touched.image && formik.errors.image ? (
                        <div className="text-danger">{formik.errors.image}</div>
                      ) : null}
                    </>
                  )}
                     <div className="mb-3">
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="Car Image Preview"
                        className="img-fluid mb-2"
                        style={{ maxHeight: '306px', width: '20%' }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {isEditable ? (
                <div className="col-12 d-flex justify-content-end align-items-center">
                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                  <button type="button" onClick={() => navigate(`/ActionCarCategory/${id}?action=view`)} className="btn btn-label-secondary ms-2">
                    Cancel
                  </button>
                </div>
              ) : (rolePermissions.write === 'no' && (
                <div className="col-12 d-flex justify-content-end align-items-center">
                  <button type="button" onClick={() => navigate(`/ActionCarCategory/${id}?action=edit`)} className="btn btn-primary">
                    Edit
                  </button>
                </div>
              ))}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionCarCategory;
