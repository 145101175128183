import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2  } from '../../../../Constant/Constant';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

const RegisterPage = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        Title: '',
        SignUpButtonTitle: '',
        AlreadyRegisteredTitle: '',
        AlreadyRegisteredLinkTitle: '',
        Description:''
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [isBookingBoxData, setIsBookingBoxData] = useState(false);
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);
  
    useEffect(() => {
      if (permissions) {
        try {
          const modules = JSON.parse(permissions);
          const legalcontent = modules.find(module => module.rolename === 'staticcontent');
          if (legalcontent) {
            setRolePermissions(legalcontent.permissions);
            setIsBookingBoxData(legalcontent.permissions.write === 'no');
           
          }
        } catch (error) {
          console.error('Failed to parse permissions:', error);
        }
      }
    }, [permissions]);

    
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.data && response.data.registerpage) {
                    const parsedData = JSON.parse(response.data.registerpage);
                    setFormValues(parsedData);
                    setIsDataExists(true);
                    setIsEditMode(false); // Disable edit mode if data exists
                } else {
                    setFormValues({
                        Title: '',
                        SignUpButtonTitle: '',
                        AlreadyRegisteredTitle: '',
                        AlreadyRegisteredLinkTitle: '',
                        Description:''
                    });
                    setIsDataExists(false);
                    setIsEditMode(true); // Enable edit mode if no data
                }
            } catch (error) {
                setIsEditMode(true); // Allow editing if there's an error
            }
        };

    useEffect(() => {
        if (token && languageId) {
            fetchData();
        }
    }, [token, languageId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                registerpage: JSON.stringify(formValues), // Ensure formValues is stringified
                language_id: languageId,
            };

            const url = `${BASE_URL}staticcontent/registerpage`;

            const response = await axios.post(url, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data && response.data.message === 'registerpage Process successfully!') {
                toast.success('Data processed successfully!', { autoClose: 1500 });
                setIsDataExists(true);
                setIsEditMode(false); // Disable edit mode after successful save
            } else {
                toast.warn('Unexpected response from the server.', { autoClose: 1500 });
            }
        } catch (error) {
            toast.error('Error saving data.', { autoClose: 1500 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <form className="row g-3" onSubmit={handleSubmit}>
            {/* First Row */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input
                        name="Title"
                        type="text"
                        className="form-control"
                        value={formValues.Title}
                        onChange={handleChange}
                        disabled={loading ||isBookingBoxData}
                       
                    />
                </div>
            </div>
            <div className="col-md-4">
                        <label className="form-label">Description</label>
                        <textarea
                            name="Description"
                            className="form-control"
                            rows="2" // This will create a 2-row textarea
                            value={formValues.Description}
                            onChange={handleChange}
                            disabled={loading ||isBookingBoxData}
                        />
                </div>
         

            {/* Second Row
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Moblie Title</label>
                    <input
                        name="MoblieTitle"
                        type="text"
                        className="form-control"
                        value={formValues.MoblieTitle}
                        onChange={handleChange}
                       
                    />
                </div>
            </div>

            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Email Title</label>
                    <input
                        name="EmailTitle"
                        type="text"
                        className="form-control"
                        value={formValues.EmailTitle}
                        onChange={handleChange}
                       
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Password Title</label>
                    <input
                        name="PasswordTitle"
                        type="text"
                        className="form-control"
                        value={formValues.PasswordTitle}
                        onChange={handleChange}
                       
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">confirm password Title</label>
                    <input
                        name="confirmPasswordTitle"
                        type="text"
                        className="form-control"
                        value={formValues.confirmPasswordTitle}
                        onChange={handleChange}
                       
                    />
                </div>
            </div> */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">SignUpButton Title</label>
                    <input
                        name="SignUpButtonTitle"
                        type="text"
                        className="form-control"
                        value={formValues.SignUpButtonTitle}
                        onChange={handleChange}
                        disabled={loading ||isBookingBoxData}
                       
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">AlreadyRegistered Title</label>
                    <input
                        name="AlreadyRegisteredTitle"
                        type="text"
                        className="form-control"
                        value={formValues.AlreadyRegisteredTitle}
                        onChange={handleChange}
                        disabled={loading ||isBookingBoxData}
                       
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">AlreadyRegisteredLink Title</label>
                    <input
                        name="AlreadyRegisteredLinkTitle"
                        type="text"
                        className="form-control"
                        value={formValues.AlreadyRegisteredLinkTitle}
                        onChange={handleChange}
                        disabled={loading ||isBookingBoxData}
                       
                    />
                </div>
            </div>

            <div className="card-footer text-end">
            {rolePermissions.write === 'yes' && 
                <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Saving...' : isDataExists ? 'Update' : 'Add'}
                </button>
}
            </div>
        </form>
    );
};

export default RegisterPage;
