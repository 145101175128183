import React from 'react';

const SelectField = ({ label, id, options, required,filterStatus,setFilterStatus }) => {
  return (
    <div className="col-md-2">
      <label className="form-label" htmlFor={id}>{label}</label>
      <select className="form-select"
        id={id} 
        value={filterStatus}
        onChange={(e) => setFilterStatus(e.target.value)} 
        required={required}>

        <option value="">Select</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;
