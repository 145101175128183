import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, CRYPTOJS_ENCRYPTION_KEY } from '../../Constant/Constant';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CryptoJS from 'crypto-js';

const ActionExtra = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    title: '',
    price: '',
    duration: '',
    quantity: '',
    description: '',
    category: '',
  });

  const [isEditable, setIsEditable] = useState(false);
  const [message, setMessage] = useState(null);
  const [token, setToken] = useState(null);

  // Function to decrypt tokens
  const decryptToken = (encryptedToken, key) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
      const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedToken ? decryptedToken : null;
    } catch (error) {
      console.error('Error decrypting token:', error);
      return null;
    }
  };

  // Fetch token from localStorage and decrypt it
  const fetchBackendToken = async () => {
    const encryptedBackendToken = localStorage.getItem('BackendToken');
    if (encryptedBackendToken) {
      const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
      setToken(decryptedToken); // Set token to state
    } else {
      console.warn('No BackendToken found in localStorage.');
    }
  };


  useEffect(() => {
    fetchBackendToken(); // Fetch and set token
  }, []);

  const permissions = useSelector((state) => state.roles.permissions.user_modules);
  const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });

  useEffect(() => {
    if (permissions) {
      try {
        const modules = JSON.parse(permissions);
        const extraoption = modules.find((module) => module.rolename === 'extraoption');
        if (extraoption) {
          setRolePermissions(extraoption.permissions);
        }
      } catch (error) {
        console.error('Failed to parse permissions:', error);
      }
    }
  }, [permissions]);

  const fetchLegalContents = async () => {
    axios.get(`${BASE_URL}extraoption/get/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        const data = response.data;
        setInitialValues({
          title: data.title || '',
          price: data.price || '',
          duration: data.duration || '',
          quantity: data.quantity || '',
          description: data.description || '',
          category: data.category || ''
        });
      })
      .catch(error => {
        toast.error('Error fetching ExtraOptions', { autoClose: 1500 });
      });
  }
  useEffect(() => {
    if (token) {
      fetchLegalContents();
    }
  }, [token]);
  useEffect(() => {
    setIsEditable(action === 'edit');
  }, [action]);

  const handleUpdate = (values) => {
    if (rolePermissions.write === 'no') {
      toast.dismiss();
      toast.error('You do not have permission to update data.', { autoClose: 1500 });
      return;
    }

    axios.put(`${BASE_URL}extraoption/update/${id}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        toast.success('ExtraOptions updated successfully', { autoClose: 1500 });
        setTimeout(() => {
          setMessage(null);
          navigate(`/actionextra/${id}?action=view`);
        });
      })
      .catch(error => {
        toast.error('Failed to update ExtraOptions', { autoClose: 1500 });
        setTimeout(() => {
          setMessage(null);
        }, 2000);
      });
  };

  const handleEdit = () => {
    navigate(`/actionextra/${id}?action=edit`);
  };

  const handleCancel = () => {
    navigate(`/actionextra/${id}?action=view`);
  };

  const categories = [
    { value: '', label: 'Select Category' },
    { value: 'select', label: 'Select Item' },
    { value: 'quantity', label: 'Quantity Item' },
  ];

  // Yup validation schema
  const validationSchema = Yup.object({
    category: Yup.string().required('Category is required'),
    title: Yup.string().required('Title is required'),
    price: Yup.number().required('Price is required').positive('Price must be a positive number'),
  });

  return (
    <div className="container-xl my-4">
      <ToastContainer />
      <div className="row">
        <div className="col-md-6">
          <h4>ExtraOptions</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="../extraoptionslist" className='fs-6'>Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-legal_title">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={handleUpdate}
            >
              {({ handleChange, handleBlur, values }) => (
                <Form className="row g-3">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="col-sm col-form-label">Category<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field
                        as="select"
                        name="category"
                        className="form-select mb-2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.category}
                        disabled={!isEditable}
                        style={{ color: !isEditable ? "#808080" : "#808080", }}
                      >
                        {categories.map(cat => (
                          <option key={cat.value} value={cat.value}>{cat.label}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="category" component="div" className="text-danger" />
                    </div>
                    <div className="mb-3">
                      <label className="col-sm col-form-label">Price<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field
                        type="number"
                        name="price"
                        className="form-control mb-2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.price}
                        disabled={!isEditable}
                      />
                      <ErrorMessage name="price" component="div" className="text-danger" />
                    </div>
                    <div className="mb-3">
                      <label className="col-sm col-form-label">Description</label>
                      <Field
                        type="text"
                        name="description"
                        className="form-control mb-2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        disabled={!isEditable}
                      />
                    </div>

                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="col-sm col-form-label">Title<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field
                        type="text"
                        name="title"
                        className="form-control mb-2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        disabled={!isEditable}
                      />
                      <ErrorMessage name="title" component="div" className="text-danger" />
                    </div>
                    <div className="mb-3">
                      <label className="col-sm col-form-label">Quantity</label>
                      <Field
                        type="number"
                        name="quantity"
                        className="form-control mb-2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.quantity}
                        disabled={!isEditable}
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label className="col-sm col-form-label">Duration</label>
                      <Field
                        type="text"
                        name="duration"
                        className="form-control mb-2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.duration}
                        disabled={!isEditable}
                      />
                    </div> */}
                  </div>
                  {isEditable ? (
                    <div className="col-12 d-flex justify-content-end align-items-center">
                      <button type="button" onClick={handleCancel} className="btn btn-label-secondary me-3">
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                    </div>
                  ) : (rolePermissions.write === 'yes' && (
                    <div className="col-12 d-flex justify-content-end align-items-center">
                      <button type="button" onClick={handleEdit} className="btn btn-primary">
                        Edit
                      </button>
                    </div>
                  ))}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionExtra;
