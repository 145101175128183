import React, { useState,useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2 } from '../../Constant/Constant';
import CryptoJS from 'crypto-js';

// Validation Schema
const DisplayingErrorMessagesSchema = Yup.object().shape({
    meta_title: Yup.string().required('Meta title is required'),
    meta_description: Yup.string().required('Meta description is required'),
    meta_keywords: Yup.string().required('meta keywords is required'),
    category: Yup.string().required('Category is required')
});

const ModalMetaTags = ({ refreshData }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);



    // Initial form values
    const initialValues = {
        meta_title: "",
        meta_description: "",
        meta_keywords: "",
        category: ""
    };

    // Sample categories, replace with your actual categories
    const categories = [
        { value: '', label: 'Select Category' },
        { value: 'home', label: 'Home' },
        { value: 'about', label: 'About' },
        { value: 'ourfleet', label: 'Our Fleet' },
        { value: 'services', label: 'Services' },
        { value: 'contact', label: 'Contact' },
        { value: 'booking-vehicle', label: 'Booking Vehicle' },
        { value: 'booking-extra', label: 'Booking Extra' },
        { value: 'booking-passenger', label: 'Booking Passenger' },
        { value: 'booking-payment', label: 'Booking Payment' },
        { value: 'booking-received', label: 'Booking Received' },
        { value: 'legel-content', label: 'Legal Content' },
    ];

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
        try {
            const formData = {
                ...values,
                language_id: languageId
            };
    
            // Send the form data to the server
            await axios.post(`${BASE_URL}metatags/add`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            toast.success('Form submitted successfully!', { autoClose: 1500 });
            resetForm();
            setModalOpen(false); // Close modal after submission
            refreshData(); // Refresh table data
        } catch (error) {
            // Check if error response is available
            const errorMessage = error.response?.data?.message || 'Error submitting form';
            toast.error(errorMessage, { autoClose: 1500 });
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
            <Link type="button" className="btn btn-primary waves-effect waves-light" onClick={() => setModalOpen(true)}>
                <i className="fa fa-plus me-2"></i>Add 
            </Link>

            {modalOpen && (
                <>
                    <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" aria-hidden={!modalOpen}>
                        <ToastContainer />
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content" style={{ backgroundColor: 'white' }}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modalCenterTitle">Add</h5>
                                    <button type="button" className="btn-close" onClick={() => setModalOpen(false)} aria-label="Close"></button>
                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={DisplayingErrorMessagesSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className="modal-body">
                                                <div className='row g-3'>
                                                    <div className="col-6 mb-3">
                                                        <label htmlFor="category" className="form-label">Category<span className='text-danger px-1 fs-5'>*</span>
                                                        </label>
                                                        <Field
                                                            id="category"
                                                            name="category"
                                                            as="select"
                                                            className={`form-select ${errors.category && touched.category ? 'is-invalid' : ''}`}
                                                        >
                                                            {categories.map(cat => (
                                                                <option key={cat.value} value={cat.value}>{cat.label}</option>
                                                            ))}
                                                        </Field>
                                                        {errors.category && touched.category && (
                                                            <div className="invalid-feedback">{errors.category}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-6 mb-3">
                                                        <label htmlFor="meta_title" className="form-label">Meta Title<span className='text-danger px-1 fs-5'>*</span>
                                                        </label>
                                                        <Field
                                                            id="meta_title"
                                                            name="meta_title"
                                                            type="text"
                                                            className={`form-control ${errors.meta_title && touched.meta_title ? 'is-invalid' : ''}`}
                                                        />
                                                        {errors.meta_title && touched.meta_title && (
                                                            <div className="invalid-feedback">{errors.meta_title}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className='row g-3'>
                                                    <div className="col-12 ">
                                                        <label htmlFor="meta_description" className="form-label">Meta Description<span className='text-danger px-1 fs-5'>*</span>
                                                        </label>
                                                        <Field
                                                            id="meta_description"
                                                            name="meta_description"
                                                            as="textarea"
                                                            rows="4"
                                                            className={`form-control ${errors.meta_description && touched.meta_description ? 'is-invalid' : ''}`}
                                                        />
                                                        {errors.meta_description && touched.meta_description && (
                                                            <div className="invalid-feedback">{errors.meta_description}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-12 ">
                                                        <label htmlFor="meta_keywords" className="form-label">Meta Keywords<span className='text-danger px-1 fs-5'>*</span>
                                                        </label>
                                                        <Field
                                                            id="meta_keywords"
                                                            name="meta_keywords"
                                                            as="textarea"
                                                            rows="3"
                                                            className={`form-control ${errors.meta_keywords && touched.meta_keywords ? 'is-invalid' : ''}`}
                                                        />
                                                        {errors.meta_keywords && touched.meta_keywords && (
                                                            <div className="invalid-feedback">{errors.meta_keywords}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-label-secondary" onClick={() => setModalOpen(false)}>Close</button>
                                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                                    {loading ? 'Adding...' : 'Add'}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
                </>
            )}
        </>
    );
};

export default ModalMetaTags;
